import { Outlet } from 'react-router-dom';
import { Auth } from './Auth';

export const AuthRoute = () => {
  return (
    <Auth>
      <Outlet />
    </Auth>
  );
};
