import { admin } from '@/admin/module';
import { Page, PageParams } from '@/module';

export type TargetStatus = 'connected' | 'disconnected';
export type TargetData = {
  id?: string;
  name?: string;
  protocol?: string;
  through?: string;
  deviceId?: string;
  tenantId?: string;
  status?: TargetStatus | string;
  createdTime?: string;
  lastActive?: string;
} & {
  /**
   * left show info, default: false
   * @desc custom info no backend
   * */
  noShow?: boolean;
};

export const getTargetList = <T = Page<TargetData>>(data: PageParams) => {
  return admin.get<T>('/tenant-target/list', { params: data });
};

export const postTarget = (data: TargetData) => {
  return admin.post('/tenant-target/create', data);
};
export const postTargetUpdate = (data: TargetData) => {
  return admin.post('/tenant-target/update', data);
};

export const deleteTarget = (targetId: string) => {
  return admin.get(`/tenant-target/delete/${targetId}`, { params: { targetId } });
};

export type RealTargetData = {
  deviceId?: string;
};

export const getTargetRealList = <T = Page<TargetData>>(data: PageParams<RealTargetData>) => {
  const { deviceId, ...rest } = data;
  return admin.get<T>(`/tenant-target/ditto/targets/${deviceId}`, { params: data });
};
