import { useStore } from '@/store';
import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type AuthProps = {
  children?: React.ReactNode;
};
export const Auth = (props: AuthProps) => {
  const store = useStore();
  const navigate = useNavigate();
  const [mounted, setMounted] = React.useState(false);
  useLayoutEffect(() => {
    init();
  }, []);
  const init = async () => {
    const isLogin = await store.getUser?.();
    if (!isLogin) {
      navigate('/user/login');
      return;
    }
    setMounted(true);
  };
  return <>{mounted && props?.children}</>;
};
