import { Button, Table } from '@/theme';
import { ColumnsType } from 'antd/es/table';
import { useViewerStore } from './store';
import { EasyViewStore } from './model';
import { useContextMenu } from '@/theme';
import { MenuItem } from '@mui/material';
import { message } from 'antd';
import { PinToBottom } from '@nisar/icons';

type Props = {
  openPinTab?: (id: string) => void;
  closePopover?: () => void;
  [key: string]: any;
};
export const CategoryType = {
  plot: 'Plot',
  image: 'Image',
  ['3d']: '3D',
  gauge: 'Dashboard',
  rawMessage: 'Raw Message',
};

export const Manager = (props: Props) => {
  const store = useViewerStore();
  const easyViewStore = EasyViewStore.useStore();
  const list = easyViewStore.useKey?.('list');
  const contextMenu = useContextMenu();
  const setPin = async (record) => {
    const { id, pin } = record;
    const { setValue, addPin, saveModalData: pSave } = easyViewStore;
    if (!pin) {
      setValue?.(id, { pin: true }); // update data
      addPin?.({ id }); // update pin data
      const r = await pSave?.({ id, pin: true }); // save to server
      if (r) {
        message.success('Pin success');
      }
    }
    props.openPinTab?.(id);
    props.closePopover?.();
  };
  const columns: ColumnsType<any> = [
    {
      title: () => {
        return <div style={{ paddingLeft: 20 }}>Title</div>;
      },
      dataIndex: 'title',
      width: 230,
      render: (value) => {
        return <div style={{ paddingLeft: 20 }}>{value}</div>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'category',
      width: 100,
      render: (val) => {
        return <>{CategoryType[val]}</>;
      },
    },
    {
      title: 'Note',
      dataIndex: 'description',
      width: 240,
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      width: 100,
    },
    {
      title: ' ',
      dataIndex: 'operation',
      render: (_value, record, _index) => {
        const onClick = (e) => {
          if (record.id) {
            // easyViewStore.addModal(record.id)
            setPin(record);
          }
          e.stopPropagation();
          e.preventDefault();
        };
        return (
          <div className='flex items-center'>
            <PinToBottom className='cursor-pointer w-6' onClick={onClick} />
          </div>
        );
      },
    },
  ];
  const renderActions = (value, contextMenu) => {
    const del = () => {
      if (value.id) {
        easyViewStore.deleteEasy(value.id);
        contextMenu?.close();
        props.closePopover?.();
      }
    };
    const edit = () => {
      if (value.id) {
        easyViewStore.setFormData(value);
        easyViewStore.toggleModal(true);
        contextMenu?.close();
        props.closePopover?.();
      }
    };
    return [
      <MenuItem onClick={del} key='delete'>
        Delete
      </MenuItem>,
      <MenuItem onClick={edit} key='edit'>
        Edit
      </MenuItem>,
    ];
  };
  return (
    <div style={{ background: '#F4F7FD' }}>
      <div
        style={{
          padding: '5px 0px',
          width: 720,
        }}
      >
        {contextMenu.render()}
        <Table
          columns={columns}
          rowKey={'id'} //
          loading={store.loading}
          scroll={{ y: 188 }}
          dataSource={list}
          pagination={false}
          showHeaderDivider={false}
          style={{ minHeight: 170 }}
          showTableBorder={false}
          onRow={(record) => {
            return {
              onContextMenu: (event) => {
                // 右键点击行
                // console.log('onContextMenu', record, event);
                contextMenu.onContextMenu(event);
                contextMenu.setRenderItems(() => {
                  if (!renderActions) return [];
                  return renderActions(record, contextMenu);
                });
              },
              onClick: (event) => {
                easyViewStore?.addModal?.(record.id);
                props.closePopover?.();
              },
            };
          }}
          styled={{
            '& .ant-table ': {
              background: 'unset' + ' !important',
            },
            '& .ant-table-thead>tr>th ': {
              background: 'unset' + ' !important',
            },
            '& .ant-table-placeholder': {
              background: 'unset' + ' !important',
            },
            '& .ant-spin-dot-item': {
              backgroundColor: '#D8E0E8' + ' !important',
            },
          }}
        />
      </div>
      <div
        style={{
          padding: '13px 0px 13px 36px',
          borderTop: '1px solid #C2CFD4',
        }}
      >
        <Button
          onClick={() => {
            easyViewStore?.toggleModal?.(true);
            props.closePopover?.();
          }}
        >
          + Create
        </Button>
      </div>
    </div>
  );
};
