type IconProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

type CircleIconProps = {
  // color: '#A8071A' | '#1890FF' | '#52C41A' | '#FAAD14' | '#F5222D';
  color?: '#A8071A' | '#289002';
};
export const CircleIcon = (props: IconProps & CircleIconProps) => {
  const { color = '#A8071A' } = props;
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='8.74292' cy='8' r='6' fill='white' stroke={color} strokeWidth='4' />
    </svg>
  );
};
/**
 * GPSIcon
 * @param props
 * @returns
 */
export const GPSIcon = (props: IconProps & { level: 1 | 2 | 3 | 4 | 5 }) => {
  const d = 'M1 6V8-M3 5V8-M5 4V8-M7 2V8-M9 0V8'.split('-');
  const { level } = props;
  const levelD = d.slice(0, level).join(' ');
  /* <path d='M1 6V8M3 3V8M5 2V8M7 0V8M9 0V6' stroke='#172F41' /> */
  return (
    <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d={levelD} stroke='#172F41' />
    </svg>
    // <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    //   <path d='M1 8V10' stroke='#172F41' />
    //   <path d='M3 7V10' stroke='#172F41' />
    //   <path d='M5 6V10' stroke='#172F41' />
    //   <path d='M7 4V10' stroke='#172F41' />
    //   <path d='M9 10V2' stroke='#172F41' />
    // </svg>
  );
};

export const UpdownIcon = (props: IconProps) => {
  const { isUp = true, style = {}, ...rest } = props;
  if (!isUp) {
    style.transform = 'rotate(180deg)';
  }
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest} style={style}>
      <path
        d='M12.1518 10.8573H11.3147C11.2578 10.8573 11.2042 10.8294 11.1708 10.7836L8 6.4131L4.82924 10.7836C4.79576 10.8294 4.74219 10.8573 4.68527 10.8573H3.84822C3.77567 10.8573 3.73326 10.7747 3.77567 10.7156L7.71094 5.29034C7.8538 5.09391 8.14621 5.09391 8.28795 5.29034L12.2232 10.7156C12.2667 10.7747 12.2243 10.8573 12.1518 10.8573Z'
        fill='#172F41'
      />
    </svg>
  );
};
