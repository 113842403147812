import { useEffect, useState } from 'react';
import { Space, App } from 'antd';
import { TableAction, TableWrapper, usePagination } from '@/theme';
import { Table } from '@/theme';
import { getTargetList as getList, TargetData, deleteTarget } from './api';
import { ColumnsType } from 'antd/es/table';
import { TenantData } from '../tenant/api';
import { DeviceStatus } from '@/pages/device';
import { EditModal, useTargetEditStore } from './module';
import { shallow } from 'zustand/shallow';
import cs from 'classnames';

type Props = {
  tenant?: TenantData;
  isSingle?: boolean;
};
export const Target = (props: Props) => {
  const { tenant, isSingle } = props;
  const { id: tenantId } = tenant || {};
  const store = useTargetEditStore(
    (state) => ({
      setOpen: state.setOpen,
      setDeviceId: state.setDeviceId,
      setTenantId: state.setTenantId,
      refreshCount: state.refreshCount,
      clearRefresh: state.clearRefresh,
      setTargetInfo: state.setTargetInfo,
    }),
    shallow,
  );
  const { setOpen, refreshCount, clearRefresh } = store;
  const {
    run,
    params: [param],
    pagination,
    dataSource,
    loading,
    refresh,
  } = usePagination(getList);
  const { message } = App.useApp();
  useEffect(() => {
    if (!tenantId && !isSingle) return;
    run({ tenantId });
  }, [tenantId, isSingle]);
  useEffect(() => {
    if (refreshCount > 0) {
      refresh();
      clearRefresh();
    }
  }, [refreshCount]);

  const columns: ColumnsType<TargetData> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Protocol',
      dataIndex: 'protocol',
    },
    {
      title: 'Through',
      dataIndex: 'through',
    },
    {
      title: 'Parent Deviece',
      dataIndex: 'deviceId',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        return <DeviceStatus text={text} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <>
            <Space>
              <TableAction
                type='delete'
                onClick={async () => {
                  const res = await deleteTarget(record.id!);
                  if (res.success) {
                    message.success('Delete successfully');
                    refresh();
                  }
                }}
              >
                Delete
              </TableAction>
              <TableAction
                type='edit'
                onClick={() => {
                  store.setDeviceId(record.deviceId!);
                  store.setTargetInfo(record);
                  store.setTenantId(record.tenantId!);
                  store.setOpen(true);
                }}
              >
                Edit
              </TableAction>
            </Space>
          </>
        );
      },
    },
  ];
  return (
    <div className={cs(!isSingle && 'h-full overflow-hidden')}>
      <TableWrapper
        search={
          <TableWrapper.QueryForm
            onFinsh={(v) => {
              run({ ...param, ...v });
            }}
          />
        }
        tools={
          <>
            {!isSingle && (
              <TableWrapper.AddButton
                onClick={() => {
                  setOpen(true);
                }}
              />
            )}
          </>
        }
      >
        <Table dataSource={dataSource} rowKey={'id'} columns={columns} loading={loading} pagination={pagination} />
      </TableWrapper>
      {isSingle && <EditModal tenant={tenant} />}
    </div>
  );
};
