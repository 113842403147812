import { Routes, Route } from 'react-router-dom';
import { Tenant } from './Tenant';
import { TenantList } from './List';

export const TenantRouter = () => {
  return (
    <Routes>
      <Route index element={<TenantList />}></Route>
      <Route path=':id' element={<Tenant />}></Route>
    </Routes>
  );
};

export * from './hooks';
export * from './module';

export type { TenantData } from './api';
