type To = string | Location;
type State<T> = {
  [key: string]: any;
} & T;
export const push = <T = any>(to: To, state?: State<T>) => {
  const _history = window.history;
  if (typeof to === 'string') {
    // must key is default, so react navigate can work
    _history.pushState({ key: 'default', usr: state }, '', to);
  } else {
    // const path = to.pathname;
    _history.pushState({ key: 'default', usr: state }, '', to.pathname);
  }
  // must dispatch popstate event, so react navigate can work
  window.dispatchEvent(new Event('popstate'));
};
export const history = {
  push,
};

// import { createBrowserHistory } from 'history';
// export const history = createBrowserHistory();
