import { useEffect } from 'react';
import { TemperatureChart } from '../chart/TemperatureChart';
import { useDataViewerStore } from './store';
import { useParams } from 'react-router-dom';
import { useDataViewer } from '../mitt';
import { random } from 'lodash-es';

type ChartWrapprProps = {
  title?: string;
  children: React.ReactNode;
};
const ChartWrappr = (props: ChartWrapprProps) => {
  return (
    <div
      className='flex flex-col'
      style={{
        width: 'calc(50% - 0.5rem)',
      }}
    >
      <div className='flex justify-between px-4 mb-2'>
        <div className='font-bold select-none'>{props.title}</div>
        {/* <Close className='w-6 h-6' /> */}
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export const DataViewer = () => {
  const store = useDataViewerStore();
  const param = useParams<{ id: string }>();
  const id = param.id;
  const { tempList } = store;
  useDataViewer(store.addData);
  useEffect(() => {
    if (id) {
      store.init(id);
    }
    return () => {
      store.dispose();
    };
  }, [id]);

  // remove demo
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     store.addData({
  //       targetId: 'demo',
  //       temperature: random(10, 50),
  //     });
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  return (
    <div className='no-scrollbar w-full h-full flex flex-wrap'>
      {tempList.map((item) => {
        const { upperThreshold, lowerThreshold } = item;
        return (
          <ChartWrappr key={item.id! + item.title} title={item.targetName}>
            <TemperatureChart
              xLabel={item.xLabel}
              yLabel={item.yLabel}
              title={item.title}
              upperThreshold={upperThreshold}
              lowerThreshold={lowerThreshold}
              data={item.data}
              labels={item.labels}
              dev={item.dev}
            />
          </ChartWrappr>
        );
      })}
    </div>
  );
};
