import L from 'leaflet';

import Red from './images/red.png';
import green from './images/green.png';

export const RedIcon = new L.Icon({
  iconUrl: Red,
  iconAnchor: [9, 0],
});

export const GreenIcon = new L.Icon({
  iconUrl: green,
  iconAnchor: [9, 0],
});
