import { Page, PageParams, request } from '@/module';
import { DeviceData } from '../device/type';
import { CommandData } from './module/protocol/model';
import { TargetData } from '@/admin/type';
import { RealTargetData } from '@/admin/pages/target';

export const getDeviceByDetail = <T = DeviceData>(deviceId: string) => {
  return request.get<T>('/tenant-device/getByDeviceId', { params: { deviceId } });
};

export type ViewerChartData = {
  deviceId: string;
  targetId: string;
  targetName: string;
  upperThreshold: number;
  lowerThreshold: number;
  xLabel?: string;
  yLabel?: string;
  title?: string;
};
export const getCharts = <T = ViewerChartData[]>(deviceId: string) => {
  return request.get<T>(`/dataViewer/charts/${deviceId}`);
};

export const getTargetCommand = <T = any>(id: string) => {
  return request.get<T>(`/tenant-target/commandList/${id}`);
};

type CallBody = CommandData;
export const postTargetCall = <T = any>(data: CallBody) => {
  return request.post<T>('/tenant-target/call', data, { timeout: 1 * 10 * 1000 });
};

export const postTargetApply = <T = any>(data: any) => {
  return request.post<T>('/tenant-target/apply', data);
};

export const postTarget = (data: TargetData) => {
  return request.post('/tenant-target/create', data);
};

export const postTargetUpdate = (data: TargetData) => {
  return request.post('/tenant-target/update', data);
};

export const getList = <T = Page<DeviceData>>(data: PageParams<{ tenantId?: string }>) => {
  return request.get<T>('/tenant-device/list', { params: data });
};

export const getTargetRealList = <T = Page<TargetData>>(data: PageParams<RealTargetData>) => {
  const { deviceId, ...rest } = data;
  return request.get<T>(`/tenant-target/ditto/targets/${deviceId}`, { params: data });
};
