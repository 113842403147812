import { create } from 'zustand';

export type DownloadFile = {
  fileName: string;
  desc: string;
  downloadUrl: string;
};
type DownloadStore = {
  download: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  file?: DownloadFile;
  setFile?: (file?: DownloadFile) => void;
  openFile?: (file?: DownloadFile) => void;
};
const demoData: DownloadFile = {
  fileName: 'demo.zip',
  desc: 'demo',
  downloadUrl: 'https://www.baidu.com',
};
export const useDownloadStore = create<DownloadStore>((set, get, store) => ({
  open: false,
  setOpen: (open: boolean) => {
    set({ open });
  },
  file: undefined,
  // file: demoData,
  setFile: (file) => {
    set({ file, open: true });
  },
  openFile: (file) => {
    set({ file, open: true });
  },
  download: () => {
    const { file } = get();
    if (file) {
      window.open(file.downloadUrl);
    }
  },
}));
