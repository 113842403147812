import { ToolFilled, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import Styles from './index.module.scss';
import { Select } from 'antd';
import { Button } from '@/theme';
import classnames from 'classnames';
import { DeviceType } from '.';
import { useState } from 'react';
import { MapIcon, ListIcon, CardIcon } from '../../component';
import { useDeviceStore } from '../../store';
type Props = {
  setType: (type: DeviceType) => void;
  type?: DeviceType;
};
export const DeviceStatus = ['ONLINE', 'OFFLINE'];
export const filterOptions = [
  { label: 'All', value: '' },
  { label: 'Favorite', value: 'star' },
];
export const statusOptions = [{ label: 'All', value: '' }].concat(DeviceStatus.map((item) => ({ label: item, value: item })));
export const DeviceHeader = (props: Props) => {
  const { type, setType } = props;
  const store = useDeviceStore((state) => {
    return {
      filterData: state.filterData,
      setFilterData: state.setFilterData,
      loading: state.loading,
      setRefresh: state.setRefresh,
    };
  });
  const { filter, status } = store.filterData || {};

  const changeForm = (key: string, value: string) => {
    const _formData = { ...store.filterData, [key]: value };
    store.setFilterData?.(_formData);
  };
  return (
    <div className={Styles.header}>
      <div className='flex'>
        <div className='flex'>
          <div className={Styles.selectLabel}>Filter:</div>
          <Select
            value={filter}
            onChange={(v) => {
              changeForm('filter', v);
            }}
            style={{ width: 118 }}
            options={filterOptions}
          ></Select>
        </div>
        <div className='flex ml-4'>
          <div className={Styles.selectLabel}>Status:</div>
          <Select
            value={status}
            onChange={(v) => {
              changeForm('status', v);
            }}
            style={{ width: 118 }}
            options={statusOptions}
          ></Select>
        </div>
        <div className={classnames('flex ml-4', Styles.selectLabel)}>
          {/* <LoadingOutlined /> */}
          <Button
            icon={<ReloadOutlined />}
            loading={store.loading}
            onClick={() => {
              store.setRefresh?.();
            }}
          />
        </div>
      </div>
      <div>
        <Button
          type='text'
          icon={<MapIcon isActive={type === 'map'} />}
          onClick={() => {
            setType('map');
          }}
        />
        <Button
          type='text'
          icon={<CardIcon isActive={type === 'card'} />}
          onClick={() => {
            setType('card');
          }}
        />
        <Button
          type='text'
          icon={<ListIcon isActive={type === 'list'} />}
          onClick={() => {
            setType('list');
          }}
        />
      </div>
    </div>
  );
};
