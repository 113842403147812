import { useAdminStore } from '@/admin/store';
import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type AuthProps = {
  children?: React.ReactNode;
};
export const Auth = (props: AuthProps) => {
  const adminStore = useAdminStore();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    init();
  }, []);
  const init = async () => {
    const isLogin = await adminStore.getUser?.();
    if (!isLogin) {
      navigate('/admin/login');
    }
  };
  return <>{props?.children}</>;
};
