import { Modal, ModalProps } from 'antd';

type Props = {
  [key: string]: any;
} & ModalProps;

export const SimpleModal = (props: Props) => {
  return (
    <Modal footer={false} {...props}>
      {props?.children}
    </Modal>
  );
};
