import { useLayoutEffect, useState } from 'react';

export const RememberToken = 'no-remember';
export const getRemember = () => {
  return !!!localStorage.getItem(RememberToken);
};
export const setRemember = (value: string) => {
  if (!value) localStorage.removeItem(RememberToken);
  localStorage.setItem(RememberToken, 'no-remember');
};

/** AutoComplate is invalid */
export const useCheckRemember = () => {
  const [checked, setChecked] = useState<boolean>(true);
  useLayoutEffect(() => {
    setChecked(getRemember());
  }, []);
  const onChange = (e: any) => {
    const { checked } = e.target;
    setChecked(checked);
    setRemember(checked ? 'remember' : '');
  };
  return {
    checked,
    onChange,
  };
};
