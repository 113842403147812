import { useParams, useLocation } from 'react-router-dom';
import { TenantData } from './api';
import { useEffect, useState } from 'react';

export const useTenantInfo = () => {
  const params = useParams();
  const id = params.id;
  const location = useLocation();
  const tenant = location.state as TenantData;
  return {
    id,
    tenant,
  };
};
type Options = {
  label: string;
  value: string | number;
};
export const useTenantOptions = (tenant?: TenantData) => {
  const { id: tenantId } = tenant || {};
  const [tenantItems, setTenantItems] = useState<Options[]>([]);
  useEffect(() => {
    if (tenantId) {
      setTenantItems([{ label: tenant?.name || '', value: tenantId }]);
    }
  }, [tenantId]);
  return {
    tenantOptions: tenantItems,
  };
};
