import { Space, Tooltip, App } from 'antd';
import { useDeviceStore } from '../../store';
import { DeviceData } from '../../type';
import { NoStarIcon, StarIcon, UpdownIcon } from '../../component';
import { useEffect, useState } from 'react';
import { Filter, Device } from '@nisar/icons';
import { Icon, Dropdown, Input, LoadingData, Button, ModalTitle, Form } from '@/theme';
import { SearchOutlined } from '@ant-design/icons';
import { shallow } from 'zustand/shallow';
import { filterOptions } from '../content/DeviceHeader';
import { BasicModal } from '@/theme';
import { postDevice } from '../../api';

type TargetListProps = {
  targets: DeviceData['targets'];
};
const TargetList = (props: TargetListProps) => {
  const { targets = [] } = props;

  return (
    <div>
      {targets?.map((item, index) => {
        return (
          <div key={index} className='mt-3 cursor-pointer'>
            {item.name}
          </div>
        );
      })}
    </div>
  );
};
type DeviceInfoProps = {
  data: DeviceData;
  onActive?: (data: DeviceData) => void;
  onEdit?: (data: DeviceData) => void;
};
export const DeviceInfo = (props: DeviceInfoProps) => {
  const { data } = props;
  const [isExpand, setIsExpand] = useState(false);
  const store = useDeviceStore((state) => {
    return {
      updateStar: state.updateStar,
    };
  }, shallow);

  return (
    <div className='mt-2'>
      <div className='flex items-center justify-between'>
        <div
          className='flex items-center justify-between select-none cursor-pointer'
          onClick={() => {
            props.onActive?.(data);
            setIsExpand(!isExpand);
          }}
        >
          <Tooltip title='edit'>
            <Icon
              className='mr-2'
              icon={<Device />}
              onClick={(e) => {
                e.stopPropagation();
                props.onEdit?.(data);
              }}
            />
          </Tooltip>

          {data?.name}
        </div>

        <div className='flex'>
          <Tooltip title='star device'>
            <div
              className='cursor-pointer'
              style={{ marginRight: 14.5 }}
              onClick={(e) => {
                e.stopPropagation();
                store.updateStar?.(data);
              }}
            >
              {data.star ? <StarIcon /> : <NoStarIcon />}
            </div>
          </Tooltip>
          <Icon
            icon={
              <UpdownIcon
                isUp={!!isExpand}
                onClick={(e: Event) => {
                  setIsExpand(!isExpand);
                  e.preventDefault();
                }}
              />
            }
          />
        </div>
      </div>
      {isExpand && (
        <div className='ml-5 mt-3 mb-3'>
          <TargetList targets={data.targets} />
        </div>
      )}
    </div>
  );
};

export const Left = () => {
  const store = useDeviceStore((state) => {
    return {
      list: state.list,
      setActiveDevice: state.setActiveDevice,
      filterData: state.filterData,
      setFilterData: state.setFilterData,
      loading: state.loading,
    };
  }, shallow);
  // const list = store.list || [];
  const [list, setList] = useState<any>([]);
  const { filter } = store.filterData || {};
  const { open, onClose, setOpen } = BasicModal.useModal();
  const [form] = Form.useForm();
  useEffect(() => {
    const list = store.list || [];
    const _list = list.filter((item) => {
      if (filter === 'star') {
        return item.star === true;
      }
      return true;
    });
    setList(_list);
  }, [store.list, store.filterData?.filter]);
  const changeForm = (key: string, value: string) => {
    const _formData = { ...store.filterData, [key]: value };
    store.setFilterData?.(_formData);
  };
  const items = filterOptions.map((item) => {
    return {
      label: item.label,
      key: item.value,
    };
  });
  const onEdit = (data: DeviceData) => {
    form.setFieldsValue(data);
    setOpen(true);
  };
  const { message } = App.useApp();
  const onFinish = async (value: any) => {
    const res = await postDevice(value);
    if (res.success) {
      onClose();
      message.success('edit success');
    }
  };
  const [value, setValue] = useState('');
  const onSearch = (e: any) => {
    setValue(e.target.value);
  };
  const [showList, setShowList] = useState<any>([]);

  useEffect(() => {
    if (!value) {
      setShowList(list || []);
    }
    const _list = list.filter((item: any) => {
      return item.name?.includes?.(value);
    });
    setShowList(_list);
  }, [value, list]);
  return (
    <div style={{ height: '100%', padding: '1rem', fontSize: 12, fontWeight: 500 }}>
      <div>
        <Input placeholder='Search devices' prefix={<SearchOutlined className='site-form-item-icon' />} value={value} onChange={onSearch} />
      </div>
      <div className='mt-2 flex justify-between items-center'>
        <div style={{ color: '#6D848F' }}>My Devices</div>
        <div>
          <Dropdown
            menu={{
              items,
              value: filter,
              onChange: (key) => {
                changeForm('filter', key);
              },
            }}
          >
            <Icon icon={<Filter />} />
          </Dropdown>
        </div>
      </div>
      <div
        className='mt-2 no-scrollbar'
        style={{
          height: 'calc(100% - 30px)',
          color: '#172F41',
        }}
      >
        <LoadingData loading={store.loading}>
          {showList.map((item) => {
            return <DeviceInfo key={item.id} data={item} onActive={store.setActiveDevice} onEdit={onEdit}></DeviceInfo>;
          })}
        </LoadingData>
      </div>
      <BasicModal open={open} onClose={onClose} style={{ padding: '36px 72px', width: 500 }}>
        <div className='mb-6'>
          <ModalTitle style={{ padding: 10, color: '#172F41' }}>Edit Device</ModalTitle>
          <div
            className='text-center'
            style={{
              fontSize: 12,
              color: '#405768',
            }}
          >
            ID: {form.getFieldValue('id') || '-'}
          </div>
        </div>
        <Form labelCol={{ span: 8 }} form={form} onFinish={onFinish}>
          <Form.Item label='Device ID' name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item label='Device Name' name='name'>
            <Input />
          </Form.Item>
          <Form.Item label='Version' name='version' required>
            <Input disabled />
          </Form.Item>
          <Form.Item label=' ' colon={false}>
            <Space>
              <Button type='primary' htmlType='submit'>
                Confirm
              </Button>
              <Button
                type='text'
                htmlType='reset'
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </BasicModal>
      <div style={{ display: 'none' }}>
        <Form form={form} />
      </div>
    </div>
  );
};
