import { create } from 'zustand';
import { TenantData } from './api';
export enum TenantTab {
  Device = 'Device',
  User = 'User',
  Target = 'Target',
}
type Store = {
  open: boolean;
  setOpen: (open: boolean) => void;
  tenant?: TenantData;
  setTenant: (tenant: TenantData) => void;
  tab?: TenantTab;
  setTab: (tab: TenantTab) => void;
};
export const useStore = create<Store>((set) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
  tenant: undefined,
  setTenant: (tenant: TenantData) => set({ tenant }),
  tab: TenantTab.Device,
  setTab: (tab: TenantTab) => set({ tab }),
}));
