import { Tabs as AntTabs, TabsProps } from 'antd';
import styled from '@emotion/styled';
import cs from 'classnames';
type MyTabsProps = TabsProps & {
  /** @desc display .ant-tabs-nav::before */
  showBottomBorder?: boolean;
  /** @desc display .ant-tabs-nav margin */
  showBottomMargin?: boolean;
  /** @desc when need Scroll */
  showScroll?: boolean;
  /**
   * @desc display .ant-tabs-nav-wrap height
   *
   * when only use tab, and no children use it,
   * default height is 30px, but you can set it also
   */
  tabNavWrapHeight?: number | boolean;
  /**
   * @desc sx
   */
  sx?: any;
};
export const MyTabs = (props: MyTabsProps) => {
  const { className, showBottomBorder, showBottomMargin, tabNavWrapHeight, showScroll, sx, ...rest } = props;
  return <AntTabs className={cs(className, 'ni-tabs')} {...rest}></AntTabs>;
};

export const Tabs = styled(MyTabs)((props) => {
  const { showBottomBorder = true, showBottomMargin = true, tabNavWrapHeight, showScroll, sx: pSx } = props;
  let navWrapHeight = 30;
  if (typeof tabNavWrapHeight === 'number') {
    navWrapHeight = tabNavWrapHeight;
  }
  const light2 = '#1d2e3f';
  let sx = {
    ...pSx,
  };
  if (showScroll) {
    sx = {
      '&, & .ant-tabs-content, & .ant-tabs-tabpane': {
        height: '100%',
      },
      ...sx,
    };
  }

  return {
    '&': {
      fontFamily: 'Montserrat',
    },
    '& .ant-tabs-nav': {
      paddingLeft: '10px',
    },
    '& .ant-tabs-tab-btn': {
      color: '#405768' + ' !important',
      fontWeight: 400,
      textShadow: 'unset' + ' !important',
    },
    '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: light2 + ' !important',
      fontWeight: 500,
    },
    '& .ant-tabs-tab+.ant-tabs-tab': {
      margin: '0px 0px 0px 12px',
    },
    '& .ant-tabs-ink-bar': {
      background: light2,
    },
    '& .ant-tabs-nav::before': {
      borderBottom: showBottomBorder ? '1px solid #C2CFD4' : 'none',
    },
    '& > .ant-tabs-nav': {
      margin: showBottomMargin ? '0px 0px 16px 0px' : '0px',
    },
    '& >.ant-tabs-nav .ant-tabs-nav-wrap': {
      height: tabNavWrapHeight !== null ? navWrapHeight : 'unset',
    },
    ...sx,
  };
});
