import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useModal } from './hooks';
type BasicModalProps = {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};
export const basicSX = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F9FBFF',
  // bgcolor: 'background.paper',
  boxShadow: '0px 2px 10px rgba(23, 47, 65, 0.2)',
  borderRadius: '4px',
  width: '400px',
};

export const BasicModal = (props: BasicModalProps) => {
  const { open, onClose, style } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={basicSX} style={{ ...style }}>
        {props.children}
      </Box>
    </Modal>
  );
};

BasicModal.useModal = useModal;
