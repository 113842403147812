export const getQuery = () => {
  const query: any = {};
  const searchParams = new URLSearchParams(location.search.substring(1));
  for (const pair of searchParams.entries()) {
    query[pair[0]] = pair[1];
  }
  return query;
};

export const querystring = (props?: any) => {
  if (!props) return '';
  props = JSON.parse(JSON.stringify(props));
  return new URLSearchParams(props).toString();
};
