import { message } from 'antd';
// import { useParams } from 'react-router-dom';
// import browerHistory from '@/utils/history';
import Card from '@mui/material/Card';
import { Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';

import { checkPassword } from '@/utils/check';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Styles from './ResetPassword.module.scss';

export const infoFromToken = function (token: string) {
  try {
    const usercode = token.split('.')[1];
    const info = JSON.parse(atob(usercode));
    return info;
  } catch (error) {
    console.log('error', error);
  }
  return null;
};
export const ResetPassword = () => {
  const t = (str) => str;

  const p = useParams();
  const token = p.token as string;

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const userInfo = infoFromToken(token) || {};

  const toLogin = () => {
    // browerHistory.push('/login');
  };
  const sendReset = () => {
    if (userInfo == null) {
      return;
    }
    // resetPassword({ newPassword: password, token }).then((res) => {
    //   if (res.success) {
    //     message.success(res.mes);
    //     browerHistory.push('/login');
    //   } else {
    //     message.error(res.mes);
    //   }
    // });
  };

  const isValid = password === password2 && checkPassword(password) && userInfo != null;

  const renderForm = () => {
    return (
      <Card className={`${Styles['reset-bg-box']} px-12 py-10`}>
        <div className={`${Styles['reset-text-c1']} mb-8 flex flex-col items-center text-lg font-bold`}>
          <div>Reset Password</div>
          <div className='font-light'>Account: {userInfo.email}</div>
        </div>
        <div className='flex w-full'>
          <div className='flex w-full flex-col gap-5'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <div className={`${Styles['reset-text-c1']} w-28 text-right font-editor font-light`}>{t('New Password')}:</div>
              <TextField
                placeholder={t('Password(6 digits at least)')}
                className='w-60'
                onChange={(e) => setPassword(e.target.value)}
                type='password'
                InputProps={{
                  classes: {
                    input: `py-2 px-3.5 font-editor ${Styles['reset-text-c1']} font-light`,
                  },
                }}
              />
            </Stack>
            <Stack className=' ' direction='row' spacing={1} alignItems='center'>
              <div className={`${Styles['reset-text-c1']} w-28 text-right font-editor font-light`}>{t('Repeat Password')}:</div>
              <TextField
                variant='outlined'
                placeholder={t('Type again')}
                className='w-60'
                type='password'
                onChange={(e) => setPassword2(e.target.value)}
                InputProps={{
                  classes: {
                    input: `py-2 px-3.5 font-editor ${Styles['reset-text-c1']} font-light`,
                  },
                }}
              />
            </Stack>
            <div className={`${Styles['reset-margin-left']} mt-2 flex gap-4`}>
              <Button className='font-normal' disabled={!isValid} onClick={sendReset} variant='contained'>
                Reset
              </Button>
            </div>
          </div>
        </div>
      </Card>
    );
  };
  const Success = () => {
    return (
      <div className={`${Styles['reset-text-c1']} mt-24 text-lg`}>
        <div>{'The password has been reset.'}</div>
        <div>
          Click{' '}
          <a className={`${Styles['reset-text-c2']}`} onClick={toLogin}>
            Here
          </a>{' '}
          to return to the login page
        </div>
      </div>
    );
  };
  const Failed = () => {
    return (
      <div className={`${Styles['reset-text-c1']} mt-24 text-lg`}>
        <div>{'The password reset failed.'}</div>
        <div>Please refresh the page and try again.</div>
      </div>
    );
  };
  const renderContent = () => {
    // return <Success />
    // return <Failed />
    if (userInfo == null) {
      return <div className={`${Styles['reset-text-c1']} mt-24 text-lg`}>Token is invalid</div>;
    } else {
      return renderForm();
    }
  };
  return (
    <Card className={`${Styles['reset-bg-box']} px-12 py-10`}>
      <div className={`${Styles['reset-text-c1']} mb-8 flex flex-col items-center text-lg font-bold`}>
        <div>Reset Password</div>
        <div className='font-light'>Account: {userInfo.email}</div>
      </div>
      <div className='flex w-full'>
        <div className='flex w-full flex-col gap-5'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <div className={`${Styles['reset-text-c1']} w-28 text-right font-editor font-light`}>{t('New Password')}:</div>
            <TextField
              placeholder={t('Password(6 digits at least)')}
              className='w-60'
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              InputProps={{
                classes: {
                  input: `py-2 px-3.5 font-editor ${Styles['reset-text-c1']} font-light`,
                },
              }}
            />
          </Stack>
          <Stack className=' ' direction='row' spacing={1} alignItems='center'>
            <div className={`${Styles['reset-text-c1']} w-28 text-right font-editor font-light`}>{t('Repeat Password')}:</div>
            <TextField
              variant='outlined'
              placeholder={t('Type again')}
              className='w-60'
              type='password'
              onChange={(e) => setPassword2(e.target.value)}
              InputProps={{
                classes: {
                  input: `py-2 px-3.5 font-editor ${Styles['reset-text-c1']} font-light`,
                },
              }}
            />
          </Stack>
          <div className={`${Styles['reset-margin-left']} mt-2 flex gap-4`}>
            <Button className='font-normal' disabled={!isValid} onClick={sendReset} variant='contained'>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};
