import { Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// import { Router } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { DemoRoute } from '../pages/demo';
import { SignRouter } from '../pages/sign';
import { DeviceRouter } from '@/pages/device';
import React from 'react';
import { useTheme } from '@/theme';
import { ConfigProvider, App } from 'antd';
import '@/assets/style/antd.reset.css';
// import { history } from '@/utils';
// import { CustomRouter as Router } from '@/layout/CustomRouter';

const AdminRouter = React.lazy(() => import('@/admin'));

export const MainRoutes = () => {
  const theme = useTheme();

  console.log('theme', theme);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#66D7EB',
          fontFamily: 'Montserrat, sans-serif',
          borderRadius: 4,
          colorTextLightSolid: '#172F41',
          colorBgSpotlight: '#F9FBFF',
          zIndexBase: 1401,
        },
      }}
    >
      <App
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Router>
          <Routes>
            <Route index element={<Navigate to='/device' replace={true} />}></Route>
            <Route path='/device/*' element={<DeviceRouter />} />
            <Route path='/demo/*' element={<DemoRoute />} />
            <Route path='/user/*' element={<SignRouter />} />
            <Route path='/admin/*' element={<AdminRouter />} />
            <Route path='*' element={<h1>404</h1>} />
          </Routes>
        </Router>
        <div id='id-container-draggable' className='global-draggable'></div>
      </App>
    </ConfigProvider>
  );
};
