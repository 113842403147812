import { useEffect, useState } from 'react';
import { ProtocolData, Target } from '../../type';

export type CommandParams = {
  type: string;
  name: string;
  value: any;
  valueType: string;
  selects?: any;
};
export type CommandData = {
  deviceId?: string;
  targetId?: string;
  /** example: SOMEIP */
  protocol?: string;
  command: string;
  params: CommandParams[];
};

// TODO: generate protocol command
type Props = {
  protocols?: ProtocolData[];
  target?: Target;
  activeProtocol?: string;
  commandKey?: 'commands' | 'properties';
};
export const useCommondList = (props: Props) => {
  const { protocols, target, activeProtocol, commandKey = 'commands' } = props;
  const [commandList, setCommandList] = useState<CommandData[]>([]);
  useEffect(() => {
    if (!target) return;
    if (!activeProtocol) return;
    if (!protocols) return;
    const list: CommandData[] = [];
    const clear = () => {
      setCommandList([]);
    };
    const commandProtocol = protocols.find((protocol) => protocol.protocolName === activeProtocol);
    if (commandProtocol) {
      const { protocolName } = commandProtocol;
      const commands = commandProtocol[commandKey];
      if (!commands) {
        clear();
        return;
      }
      commands.forEach((item) => {
        const { command, params } = item;
        const commandData: CommandData = {
          deviceId: target.deviceId,
          targetId: target.id,
          protocol: protocolName,
          command,
          params,
        };
        list.push(commandData);
      });
    }

    setCommandList(list);
  }, [protocols, activeProtocol, target]);
  return {
    commandList,
  };
};
