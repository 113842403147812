import { Outlet } from 'react-router-dom';
import { CopyWrite, Logo, NisarLogo } from '@/components/company';

import Styles from './Main.module.scss';
import { LoginTitle } from '@/theme';

export const LogoTitle = () => {
  return (
    <div>
      <div className='flex grow items-center'>
        <div className='font-orbitron text-4xl font-bold'>ROS Design Studio</div>
      </div>
    </div>
  );
};

type MainProps = {
  children?: React.ReactNode;
  isAdmin?: boolean;
};
export const Main = (props: MainProps) => {
  const { isAdmin = true } = props;
  const title = isAdmin ? 'NISAR Admin' : 'NISAR Connection';
  return (
    <div style={{ width: '100%', height: '100%', background: '#f3f6fc' }}>
      <Logo />
      <div className={Styles.main}>
        <div className='flex items-center justify-center'>
          <NisarLogo width={64} height={64} />
          <LoginTitle>{title}</LoginTitle>
        </div>
        {/* <AdminLogo /> */}
        <Outlet />
      </div>
      <CopyWrite />
    </div>
  );
};
