import { StateCreator } from 'zustand';
import { DetailStore as GlobalStore } from '.';
import { Target, ProtocolData } from '../type';
import { produce } from 'immer';
import { getTargetCommand } from '../api';
export const enum RightKey {
  Info = 'Info',
  Protocol = 'Protocol',
  Properties = 'Properties',
}

type State = {
  rightKey?: RightKey;
  activeTarget?: Target;
  loaddingCommand?: boolean;
  protocols?: ProtocolData[];
};
const state: State = {
  rightKey: RightKey.Info,
  loaddingCommand: false,
};
type Action = {
  setRightKey?: (key: RightKey) => void;
  setActiveTarget?: (target?: Target) => void;
  getCommand?: (targetId?: string) => void;
  setLoadingCommand?: (loading: boolean) => void;
};
export type RightStore = State & Action & { [key: string]: any };
export const createRightStore: StateCreator<GlobalStore, [], [], RightStore> = (set, get) => ({
  ...state,
  setRightKey: (key: RightKey) => {
    set({ rightKey: key });
  },
  setActiveTarget: (target?: Target) => {
    set(
      produce((draft) => {
        draft.activeTarget = target;
        if (target && draft.rightKey !== RightKey.Protocol) draft.rightKey = RightKey.Protocol;
        else if (!target) {
          draft.rightKey = RightKey.Info;
        }
      }),
    );
  },
  setLoadingCommand: (loading) => {
    set({ loaddingCommand: loading });
  },
  getCommand: async (id) => {
    if (!id) return;
    set({ loaddingCommand: true });
    const res = await getTargetCommand(id);
    if (res.success) {
      const data = res.data ?? [];
      const protocols = data;
      set({ protocols });
    }
    set({ loaddingCommand: false });
  },
});
