import { ToolPopover } from '@/components/popover-modal';
import { Viewer } from '@nisar/icons';
import { ViewerIcon } from '../../components/icons';
// const ViewerIcon = Viewer;
import { EditModal, Manager, StudioModalList } from '../easy-viewer';
import { useTopToolbarStore } from './store';
import { useDetailStore } from '../../store';
import { useEffect } from 'react';
import { EasyViewStore } from '../easy-viewer/model';
import { ToolWrapper as IconWrapper } from '@/layout/header';

export const EasyViewerButton = () => {
  const { anchorEl, isPopover, open, close } = ToolPopover.useToolPopover();
  const { viewIsSingle, setViewIsSingle } = useTopToolbarStore();
  const easyViewStore = EasyViewStore.useStore();
  const { device, ...detailStore } = useDetailStore((state) => {
    return {
      device: state.device,
      setOpenBottom: state.setOpenBottom,
      openBottom: state.openBottom,
      setBottomKey: state.setBottomKey,
    };
  });
  const id = device?.id;
  useEffect(() => {
    if (!id) return;
    easyViewStore.fetch();
  }, [id]);
  const openPinTab = (id) => {
    if (!detailStore.openBottom) {
      detailStore.setOpenBottom?.(true);
      detailStore.setBottomKey?.('Easy viewer');
    }
  };
  return (
    <>
      <IconWrapper onClick={open}>
        <Viewer className='h-6 w-6' />
      </IconWrapper>
      <ToolPopover
        {...{ isPopover, anchorEl, close }}
        isSingle={viewIsSingle}
        onPopup={() => {
          setViewIsSingle(!viewIsSingle);
        }}
        title={'Easy Viewer'}
        icon={
          <div className='mr-2 w-6'>
            <ViewerIcon />
          </div>
        }
      >
        <Manager closePopover={close} openPinTab={openPinTab} />
      </ToolPopover>
      <ToolPopover.PopoverSingle
        isSingle={viewIsSingle}
        title={'Easy Viewer'}
        onClose={() => {
          setViewIsSingle(false);
        }}
      >
        <Manager openPinTab={openPinTab} />
      </ToolPopover.PopoverSingle>
      <EditModal />
      <StudioModalList />
    </>
  );
};
