import { Routes, Route } from 'react-router-dom';
import { Device } from './Device';
import { AuthRoute } from '@/module/auth';
import { Detail } from '../device-detail';

export const DeviceRouter = () => {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route index element={<Device />} />
        <Route path=':id' element={<Detail />} />
      </Route>
    </Routes>
  );
};

export * from './type';

export * from './component';
