import styled from '@emotion/styled';

export const ToolWrapper = styled('div')((props) => {
  return {
    '&': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      // padding: '10px 12px',
      width: '48px',
      cursor: 'pointer',
      justifyContent: 'center',
    },
    '&:hover': {
      backgroundColor: '#7DDDEE',
    },
  };
});
