import { useEffect, useState } from 'react';

export type Option<T = any> = {
  label: string;
  value: string;
  data?: T;
};

type UseOptions = {
  api?: () => Promise<any>;
  transform?: (data: any) => Option[];
  /** list */
  listKey?: string;
  /** name */
  nameKey?: string;
  /** id */
  valueKey?: string;
};
export const useOptions = (opts: UseOptions) => {
  const { api = () => Promise.resolve([]), transform, listKey = 'list', nameKey = 'name', valueKey = 'id' } = opts;
  const [options, setOptions] = useState<Option[]>([]);
  useEffect(() => {
    init();
  }, [api]);
  const init = async () => {
    const res = await api?.();
    if (transform) {
      setOptions(transform(res));
      return;
    }
    if (res.success) {
      const data = res.data || {};
      const list = data[listKey] || [];
      const options = list.map((item: any) => {
        return {
          label: item[nameKey],
          value: item[valueKey],
          data: item,
        };
      });
      setOptions(options);
    }
  };
  return {
    options,
  };
};
