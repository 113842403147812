import { useEffect, useState } from 'react';
import { message, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import styled from '@emotion/styled';
import { Upload as UploadIcon } from '@nisar/icons';

const UplaodWrapper = styled('div')((props) => {
  return {
    '& .ant-upload-select': {
      border: 'none' + ' !important',
    },
  };
});
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return false;
  // return isJpgOrPng && isLt2M;
};

/**
 * Only use for upload company avatar
 * @returns
 */
type AvatarUploadProps = {
  value?: string;
  onChange?: (value: string) => void;
};
export const AvatarUpload = (props: AvatarUploadProps) => {
  const [imageUrl, setImageUrl] = useState<string>();

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    info.file.status = 'done'; // change status to done
    if (info.file.status === 'done') {
      const originFileObj = info.fileList[0].originFileObj as RcFile;
      // Get this url from response in real world.
      getBase64(originFileObj as RcFile, (url) => {
        setImageUrl(url);
        props.onChange?.(url);
      });
    }
  };
  useEffect(() => {
    if (props.value) {
      setImageUrl(props.value);
    }
  }, [props.value]);
  const uploadButton = (
    <div
      style={{
        background: 'rgba(7, 18, 27, 0.45)',
        width: '100%',
        height: '100%',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 14,
        color: '#fff',
      }}
      className='flex justify-center items-center flex-col'
    >
      <div className='flex items-center '>
        <div
          style={{
            width: 16,
            marginRight: 8,
          }}
        >
          <UploadIcon />
        </div>

        <div>Upload</div>
      </div>
    </div>
  );

  return (
    <UplaodWrapper>
      <Upload
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        action=''
        beforeUpload={beforeUpload}
        onChange={handleChange}
        maxCount={1}
      >
        {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </UplaodWrapper>
  );
};
