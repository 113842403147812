import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const Content = styled('div')((props) => {
  const theme = props.theme as Theme;
  return {
    color: theme?.palette?.secondary.main,
    padding: '20px',
  };
});

export const Header = styled('div')((props) => {
  // const theme = props.theme as Theme;
  return {
    background: '#172F41',
    height: '48px',
    color: '#fff',
  };
});
