import { Modal, DialogActions } from '@mui/material';
import Box from '@mui/material/Box';
import { useModal } from './hooks';
import { basicSX as bsx } from './BasicModal';
import { Close } from '@nisar/icons';
import { SpaceAction } from '../space';
const basicSX = {
  ...bsx,
};

type FormModalProps = {
  open?: boolean;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  noHeader?: boolean;
  bodyStyle?: React.CSSProperties;
  actions?: React.ReactNode;
  keepMounted?: boolean;
};
export const FormModal = (props: FormModalProps) => {
  const { open = false, onClose, style, bodyStyle, title, keepMounted, noHeader = false } = props;
  // background: '#D8E0E8'
  return (
    <Modal open={open} onClose={onClose} keepMounted={keepMounted}>
      <Box sx={basicSX} style={{ ...style }}>
        {!noHeader && (
          <div style={{ cursor: 'move', display: 'flex', justifyContent: 'space-between', padding: '16px 24px 0 24px' }}>
            <div style={{ display: 'flex' }}>{title && <div>{title}</div>}</div>
            <div style={{ cursor: 'pointer' }}>
              <Close onClick={onClose} style={{ marginRight: 3, width: 16 }} />
            </div>
          </div>
        )}
        <div style={{ padding: '24px 24px 0 24px', ...bodyStyle }}>{props?.children}</div>
        <SpaceAction style={{ padding: '10px 24px 8px 0' }}>{props?.actions}</SpaceAction>
      </Box>
    </Modal>
  );
};

FormModal.useModal = useModal;
