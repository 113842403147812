import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const InputForm = styled('input')((props) => {
  const theme = props.theme as Theme;
  // const isLight = theme?.palette?.mode === 'light';
  return {
    // background: isLight ? '#fff' : '#000',
    color: theme?.palette?.secondary?.main,
    border: 0,
    outline: 'none',
  };
});
