// upload file component

import { useRef, useState } from 'react';
import classnames from 'classnames';
type UploadProps = {
  onChange?: (value?: string) => void;
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
};
export const Upload = (props: UploadProps) => {
  const [isUpload, setIsUpload] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [filename, setFilename] = useState('abc');
  const onChange = (e: any) => {
    const files: FileList = e.target.files;
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    const name = file.name;
    setFilename(name);
    setIsUpload(true);

    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      const result = evt.target?.result;
      // console.log('result', result);
      if (typeof result === 'string') {
        props.onChange?.(result);
      }
      // clear input file
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };
  };

  return (
    <div className='flex'>
      <div
        className={classnames(
          {
            hidden: isUpload,
          },
          'px-2 py-1 cursor-pointer hover:bg-neutral-light-neutral-light-2',
        )}
        style={{
          color: 'var(--neutral-light-neutral-light-4, var(--neutral-light-neutral-light-4, #6D848F))',
          fontSize: 12,
          fontWeight: 500,
          border: '1px dashed var(--neutral-light-neutral-light-5, #C2CFD4)',
          background: 'var(--neutral-light-neutral-light-1, #F9FBFF)',
          borderRadius: 2,
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        Select the file
      </div>
      <div
        className={classnames({
          hidden: !isUpload,
        })}
        style={{
          color: 'var(--neutral-light-neutral-light-4, var(--neutral-light-neutral-light-4, #6D848F))',
          fontSize: 12,
          fontWeight: 500,
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {filename}
      </div>
      <input ref={inputRef} type='file' accept='.json' className='hidden' onChange={onChange} />
    </div>
  );
};
