import { request } from '@/module';

export const login = <T = any>(data: any) => {
  return request.post<T>('/tenant-user/login', data);
};

export const postLogin = login;

export const getUserInfo = <T = any>(data?: any) => {
  return request.get<T>('/tenant-user/info');
};

export const postSendResetEmail = <T = any>(data: { email: string }) => {
  return request.post<T>('/tenant-user/sendResetPwEmail', data);
};

export type ResetPasswordOpts = {
  newPassword: string;
  token: string;
};
export const postResetPassword = <T = any>(data: ResetPasswordOpts) => {
  return request.post<T>('/tenant-user/resetPassword', data);
};
