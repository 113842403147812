import { DeviceData } from '../../type';
import { MsgSingal, GPSSignal, StarIcon, Status, NoStarIcon } from '../../component';
import { BodyTitle, Icon } from '@/theme';
import { toDevice } from '../../module';
import { Device } from '@nisar/icons';
import { Tooltip } from 'antd';
import { Fragment } from 'react';

type BodyProps = { title?: string; children?: React.ReactNode; bodyStyle?: React.CSSProperties };
const Body = (props: BodyProps) => {
  const { title, bodyStyle } = props;
  if (!props.children && typeof props.children !== 'string') return null;
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {title && <div className='mr-2'>{title}</div>}
      <Tooltip title={props.children}>
        <div style={{ opacity: 0.65, width: 160, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', ...bodyStyle }}>{props.children}</div>
      </Tooltip>
    </div>
  );
};
type DeviceCardProps = {
  data?: DeviceData;
  updateStar?: (data: DeviceData) => void;
  [key: string]: any;
};
export const DeviceCard = (props: DeviceCardProps) => {
  const { data, updateStar } = props;
  return (
    <div
      className='cursor-pointer'
      style={{
        boxSizing: 'border-box',
        padding: 16,
        width: 271,
        height: 140,
        border: '1px solid #c2cfd4',
        borderRadius: 4,
        position: 'relative',
        color: '#172F41',
        fontWeight: 500,
        fontSize: 10,
      }}
      onClick={() => {
        toDevice(data);
      }}
    >
      <div className='flex'>
        <div className='w-12'>
          LTE: <MsgSingal singal={data?.gsmSignal} />
        </div>
        <div className='w-18 ml-2'>
          GPS: <GPSSignal singal={data?.gpsMode} />
        </div>
        <div className='w-12 ml-2'>
          <Status status={data?.status !== 'OFFLINE' ? 1 : 2} />
        </div>
      </div>
      <div className='flex'>
        <div className='flex items-center mr-2'>
          <Icon icon={<Device />} />
        </div>
        <BodyTitle>{data?.name}</BodyTitle>
      </div>
      <Body title='ID:' bodyStyle={{ width: 178 }}>
        {data?.id}
      </Body>
      <Body title='IP Address:'>{data?.ip}</Body>
      <Body title='State:'>{data?.status}</Body>
      <Body title='Target List:'>{data?.targets?.map?.((item) => item.name)?.join?.(', ')}</Body>
      <div
        className='cursor-pointer'
        style={{ position: 'absolute', right: 24, top: 24, transform: 'scale(2)' }}
        onClick={(e) => {
          e.stopPropagation();
          updateStar?.(data!);
        }}
      >
        {data?.star ? <StarIcon /> : <NoStarIcon />}
      </div>
    </div>
  );
};
type Props = {
  dataSource: any[];
  updateStar?: (data: DeviceData) => void;
  [key: string]: any;
};

export const DeviceCardList = (props: Props) => {
  const { dataSource = [], updateStar } = props;
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 271px)',
        gap: 26,
        gridRowGap: 16,
      }}
    >
      {dataSource.map((item, index) => {
        return <DeviceCard key={item.id} data={item} updateStar={updateStar} />;
      })}
    </div>
  );
};
type DeviceCardByLevelProps = DeviceCardProps & { level?: 1 | 2 | 3; zoom?: number; setZoom?: (zoom: number) => void };

export const DeviceCardByLevel = (props: DeviceCardByLevelProps) => {
  const { level = 3, data, zoom, setZoom } = props;
  const { name, id, ip, status, targets, gsmSignal, gpsMode, star } = data || {};
  const wrapperStyle = {
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#172F41',
    fontWeight: 500,
  };
  const onExpandZoom = (l: 1 | 2, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    toDevice(data);
    // setZoom?.(17);
    e.stopPropagation();
  };
  const Header = (
    <div className='flex'>
      <div className='w12'>
        LTE: <MsgSingal singal={data?.gsmSignal} />
      </div>
      <div className='w-18 ml-2'>
        GPS: <GPSSignal singal={data?.gpsMode} />
      </div>
      <div className='w-12 ml-2 flex items-center'>
        Status: <Status className='ml-1' status={data?.status !== 'OFFLINE' ? 1 : 2} noText={true} />
      </div>
    </div>
  );
  const Title = (
    <div className='flex'>
      <div className='flex items-center mr-2'>
        <Icon icon={<Device />} />
      </div>
      <BodyTitle>{data?.name}</BodyTitle>
    </div>
  );
  if (level === 1) {
    return (
      <div className='cursor-pointer' style={{ padding: 8, ...wrapperStyle }} onClick={(e) => onExpandZoom(1, e)}>
        {Title}
      </div>
    );
  }
  const Level2Content = (
    <div className='map-device-content cursor-pointer' onClick={(e) => onExpandZoom(2, e)} style={{ padding: 8, ...wrapperStyle }}>
      {Header}
      {Title}
      <Body title='ID:' bodyStyle={{ width: 178 }}>
        {data?.id}
      </Body>
      <Body title='IP Address:'>{data?.ip}</Body>
      <Body title='State:'>{data?.status}</Body>
    </div>
  );
  if (level === 2) {
    return Level2Content;
  }
  return (
    <div
      className='flex map-device-content cursor-pointer'
      style={{
        ...wrapperStyle,
      }}
      onClick={() => {
        toDevice(data);
      }}
    >
      <div>{Level2Content}</div>
      <div style={{ padding: 8, marginLeft: 2, borderLeft: '1px solid #bac8ce' }}>
        <div
          style={{
            borderBottom: '1px solid #bac8ce',
            paddingBottom: 4,
          }}
        >
          Target
        </div>
        <div
          style={{
            width: 120,
            display: 'flex', //
            flexWrap: 'wrap',
            padding: 4,
            marginTop: 3,
            opacity: 0.65,
            wordBreak: 'break-all',
            whiteSpace: 'break-spaces',
          }}
        >
          {targets?.map?.((item, index) => {
            return (
              <Fragment key={index}>
                {item.name} {targets.length - 1 !== index ? ', ' : ''}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
