import { Header, LayoutTitle } from '@/theme';
import { DeviceContent } from './home/content';
import { Divider } from 'antd';
import { Left } from './home/left/Left';
import { UserAvatarInfo as UserAvatar } from '@/module';
import { useDeviceStore } from './store';
import { useEffect, useState } from 'react';
import { useStore } from '@/store';
import { shallow } from 'zustand/shallow';
import { HeaderLogo } from '@/components/company';
// import Split from 'react-split';
// import Styles from './Device.module.scss';
// import cs from 'classnames';

export const Device = () => {
  const store = useDeviceStore((state) => {
    return {
      setFormData: state.setFormData,
      query: state.query,
      refresh: state.refresh,
      formData: state.formData,
    };
  }, shallow);
  const { tenant } = useStore((state) => {
    return { tenant: state.tenant };
  }, shallow);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (tenant?.id) {
      const formData = { tenantId: tenant.id, page: 1, pageSize: 9999 };
      store.setFormData?.(formData);
      setMounted(true);
    }
  }, [tenant]);
  useEffect(() => {
    if (mounted) {
      store.query?.();
    }
  }, [mounted, store.refresh, store.formData]);
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Header className='flex justify-between' style={{ padding: '0px 30px 0px 30px' }}>
        <div className='flex items-center'>
          <HeaderLogo />
        </div>
        <UserAvatar />
      </Header>
      <div style={{ height: '100%', display: 'flex' }}>
        {/* <Split className={cs('flex', Styles.split)} style={{ width: '100%' }} gutterSize={4} sizes={[15, 85]} minSize={200}>
          <div style={{ height: '100%', background: '#F4F7FD' }}>
            <Left />
          </div>
          <div style={{ height: 'calc(100% - 50px)', padding: '24px 32px 32px 32px', display: 'flex', flexDirection: 'column' }}>
            <LayoutTitle>Devices</LayoutTitle>
            <Divider style={{ margin: 12, color: '#bac8ce' }} />
            <DeviceContent />
          </div>
        </Split> */}
        <div style={{ width: 208, height: '100%', background: '#F4F7FD' }}>
          <Left />
        </div>
        <div style={{ height: 'calc(100% - 50px)', width: 'calc(100% - 208px)', padding: '24px 32px 32px 32px', display: 'flex', flexDirection: 'column' }}>
          <LayoutTitle>Devices</LayoutTitle>
          <Divider style={{ margin: 12, color: '#bac8ce' }} />
          <DeviceContent />
        </div>
      </div>
    </div>
  );
};
