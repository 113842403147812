import { useEffect, useState } from 'react';
import { getAclPermissions, getAclRoles, UserRole, UserPermission } from './api';
import { TenantData } from '../tenant/api';
type Options = {
  label: string;
  value: string | number;
};
export const useAcl = (tenant?: TenantData) => {
  const { id: tenantId } = tenant || {};
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [roleItems, setRoleItems] = useState<Options[]>([]);
  const [permissions, setPermissions] = useState<UserPermission[]>([]);
  const [permissionItems, setPermissionItems] = useState<Options[]>([]);
  const [tenantItems, setTenantItems] = useState<Options[]>([]);
  useEffect(() => {
    tenantId && init();
    if (tenantId) {
      setTenantItems([{ label: tenant?.name || '', value: tenantId }]);
    }
  }, [tenantId]);
  const init = async () => {
    if (!tenantId) return;
    const [roles, permissions] = await Promise.all([getAclRoles({ tenantId }), getAclPermissions({ tenantId })]);
    if (roles.success) {
      const _roles = roles.data || [];
      setRoles(_roles);
      setRoleItems(_roles.map((item) => ({ label: item.name, value: item.id })));
    }
    if (permissions.success) {
      const _permissions = permissions.data || [];
      setPermissions(_permissions);
      setPermissionItems(_permissions.map((item) => ({ label: item.name, value: item.id })));
    }
  };

  return {
    roles,
    permissions,
    roleItems,
    permissionItems,
    tenantItems,
  };
};
