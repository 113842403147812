import { IconProps } from '@/theme';
export const HeaderIcon = (props: IconProps) => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8 8.5C6.89688 8.5 6 9.39688 6 10.5V20.5C6 21.6031 6.89688 22.5 8 22.5H13.3375C13.1219 22.0469 13 21.5375 13 21C13 19.6031 13.8188 18.3969 15.0031 17.8344C15.0906 15.9781 16.6219 14.5 18.5 14.5C19.0375 14.5 19.5469 14.6219 20 14.8375V10.5C20 9.39688 19.1031 8.5 18 8.5H8ZM9.5 11.25V15.75C9.5 16.1656 9.16563 16.5 8.75 16.5C8.33438 16.5 8 16.1656 8 15.75V11.25C8 10.8344 8.33438 10.5 8.75 10.5C9.16563 10.5 9.5 10.8344 9.5 11.25ZM12.5 11.25V15.75C12.5 16.1656 12.1656 16.5 11.75 16.5C11.3344 16.5 11 16.1656 11 15.75V11.25C11 10.8344 11.3344 10.5 11.75 10.5C12.1656 10.5 12.5 10.8344 12.5 11.25ZM16.5 23.5H23.5C24.8813 23.5 26 22.3813 26 21C26 19.7812 25.1281 18.7656 23.975 18.5437C23.9906 18.4469 24 18.35 24 18.25C24 17.2844 23.2156 16.5 22.25 16.5C21.6844 16.5 21.1844 16.7687 20.8625 17.1812C20.525 16.2031 19.5938 15.5 18.5 15.5C17.1187 15.5 16 16.6187 16 18C16 18.1844 16.0187 18.3656 16.0594 18.5375C14.8875 18.7469 14 19.7688 14 21C14 22.3813 15.1188 23.5 16.5 23.5Z'
        fill='white'
      />
    </svg>
  );
};
