import { StateCreator } from 'zustand';
import { DetailStore as GlobalStore } from '.';
import { Target } from '../type';

export type State = {
  /** left target active key */
  leftKey?: string;
  /** show online target */
  showOnline?: boolean;
  /** show offline targe */
  showOffline?: boolean;
};
export const state: State = {
  showOffline: true,
  showOnline: true,
};
export type Action = {
  setLeftKey?: (key?: string) => void;
  setShowOnline?: (show: boolean) => void;
  setShowOffline?: (show: boolean) => void;
  onActiveTarget?: (target?: Target) => void;
};
export type LeftStore = State & Action & { [key: string]: any };
export const createLeftStore: StateCreator<GlobalStore, [], [], LeftStore> = (set, get) => ({
  ...state,
  setLeftKey: (key) => {
    set({ leftKey: key });
  },
  setShowOffline: (show) => {
    set({ showOffline: show });
  },
  setShowOnline: (show) => {
    set({ showOnline: show });
  },
  onActiveTarget: (target) => {
    const { leftKey, setLeftKey, setActiveTarget, getCommand } = get();
    if (leftKey === target?.id || target === undefined) {
      if (leftKey === undefined) return;
      setLeftKey?.(undefined);
      setActiveTarget?.(undefined);
      return;
    }
    setLeftKey?.(target?.id);
    setActiveTarget?.(target);
    getCommand?.(target?.id);
  },
});
