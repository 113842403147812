import { useRequest as useAntRequest, usePagination as useAntPagination } from 'ahooks';
import type { Options, Plugin, Service, Result } from 'ahooks/lib/useRequest/src/types';
// import type { Data, Params, PaginationOptions } from 'ahooks/lib/usePagination/types';
import { useState } from 'react';
export type UseRequset<TData, TParams extends any[]> = (
  service: Service<TData, TParams>,
  options?: Options<TData, TParams>,
  plugins?: Plugin<TData, TParams>[],
) => Result<TData, TParams>;

export const useRequest = <TData, TParams extends any[]>(
  service: Service<TData, TParams>,
  options?: Options<TData, TParams>,
  plugins?: Plugin<TData, TParams>[],
): Result<TData, TParams> => {
  return useAntRequest(service, { manual: true, ...options }, plugins);
};

// import type { Data, PaginationOptions, Params, Service, PaginationResult } from './types';
// declare const usePagination: <TData extends Data, TParams extends Params>(service: Service<TData, TParams>, options?: PaginationOptions<TData, TParams>) => PaginationResult<TData, TParams>;
// export default usePagination;

type Param = {
  current?: number;
  pageSize?: number;
  [key: string]: any;
};
export type Params = [Param?, ...any[]];
type PaginationResult<TData, TParams extends any[]> = {
  pagination: {
    current?: number;
    pageSize?: number;
    total?: number;
    onChange?: (current: number, pageSize: number) => void;
  };
  dataSource: any[];
} & Result<TData, TParams>;

export const usePagination = <TData, TParams extends Params>(
  service: Service<TData, TParams>,
  options?: Options<TData, TParams>,
  plugins: Plugin<TData, TParams>[] = [],
): PaginationResult<TData, TParams> => {
  type PaginInfo = {
    current?: number;
    pageSize?: number;
    total?: number;
  };
  const { onSuccess, ...restOptions } = options || {};
  const [pagination, setPagination] = useState<PaginInfo>({ current: 1, pageSize: 10 });
  const [dataSource, setDataSource] = useState<TData[]>([]);

  const requsetData = useAntRequest(
    service,
    {
      manual: true,
      onSuccess: (res: TData, params) => {
        const { current, total, pageSize, list } = (res as any).data || {};
        if (current && total && pageSize) {
          setPagination({ current: current, pageSize: pageSize, total: total });
        }
        setDataSource(list || []);
        onSuccess && onSuccess(res, params);
      },
      ...restOptions,
    },
    plugins,
  );
  return {
    ...requsetData,
    dataSource: dataSource,
    pagination: {
      ...pagination,
      onChange: (current: number, pageSize: number) => {
        setPagination({ ...pagination, current: current, pageSize: pageSize });
        const [param] = requsetData.params;
        (requsetData.run as any)?.({ ...param, current: current, pageSize: pageSize });
      },
    },
  };
};
