import { request } from '@/module';

export const getDeviceList = (params: any) => {
  return request.get('/tenant-device/list', { params });
};

export const postDeviceStar = (data: { deviceId: string; star: boolean }) => {
  return request.post('/tenant-device/addStar', data);
};
export const postDevice = (data: { id: string; name: string }) => {
  return request.post('/tenant-device/updateDevice', data);
};
/**
 * define the type of api response
 * get reverse geocoding by longitude and latitude
 * api is from https://nominatim.org/release-docs/develop/api/Reverse/
 */
type CityInfo = {
  address: {
    city: string;
    town: string;
    country: string;
    country_code: string;
    postcode: string;
    road: string;
  };
  addresstype: string; // road
  boundingbox: string[]; // ["-37.8142175", "-37.8142175", "144.9631608", "144.9631608"]
  category: string; // place
  display_name: string; // "Melbourne, City of Melbourne, Greater Melbourne, Victoria, 3000, Australia"
  importance: number; // 0.7969999999999999
  lat: number; // -37.8142175
  licence: string; // "Data © OpenStreetMap contributors, ODbL 1.0. https://www.openstreetmap.org/copyright"
  name: string; // "Melbourne"
  osm_id: number; // 175905
  osm_type: string; // "relation"
  place_id: number; // 258932
  place_rank: number; // 16
  type: string; // "city"
};
export const getCityByLonLat = async (longitude: number | string, latitude: number | string): Promise<{ data: CityInfo; city: string }> => {
  // need foreign network so develop temporary return empty data
  if (DEV_SERVER) return { data: {}, city: '' } as any;
  const data = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.error('fetch logitude and latitue city error:', err);
      return {};
    });
  // Retrieve the city name from the API response
  const city = data?.address?.city || data?.address?.town || data?.address?.village || data?.address?.hamlet;
  return { data, city };
};
