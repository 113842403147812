import { Button, Form, Tabs, Pattern, InputLogin, BasicModal } from '@/theme';

import { checkEmail, checkUsername, checkPassword, checkCode, checkSignUpPassword } from '@/utils/check';
import { UserOutlined, LockOutlined as PasswordOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useStore } from '@/store';
import { App, Checkbox } from 'antd';
import { ConfirmModal } from './ConfirmModal';
import useMessage from 'antd/es/message/useMessage';
import { useCheckRemember } from '@/utils/remember';

type ForgetModalProps = {
  open: boolean;
  setOpen?: (open: boolean) => void;
};
export const ForgetModal = (props: ForgetModalProps) => {
  const { open, setOpen } = props;
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const t = (str: string) => str;
  const store = useStore();
  const onClose = () => {
    setOpen?.(false);
  };
  const onFinish = async (value: any) => {
    const { email } = value;
    // message.loading('Sending email...');
    const success = await store?.sendEmail?.(email);
    if (success) {
      message.success('Send email successfully!');
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };
  const footer = (
    <div
      style={{
        marginTop: 26,
        marginRight: -60,
        fontSize: 14,
      }}
    >
      <Button
        style={{
          width: 112,
        }}
        onClick={onClose}
        type='text'
      >
        Cancel
      </Button>
      <Button
        type='primary'
        style={{
          width: 112,
        }}
        htmlType='submit'
      >
        {t('Confirm')}
      </Button>
    </div>
  );
  return (
    <ConfirmModal
      open={open}
      title='Forget Passowrd'
      onClose={() => {
        setOpen?.(false);
      }}
      // footer={Footer}
      footer={null}
      style={{
        width: 420,
      }}
    >
      <Form form={form} onFinish={onFinish} validateTrigger={['onBlur']}>
        <Form.Item>
          <div style={{ marginTop: 18, textAlign: 'center', color: '#405768', fontSize: 12, width: 280 }}>{t('We will email you to reset the password.')}</div>
        </Form.Item>
        <Form.Item
          name='email'
          rules={[
            { required: true, message: 'Please input your Email!' },
            {
              pattern: Pattern.email,
              message: 'Please input a valid email address!',
            },
          ]}
        >
          <InputLogin placeholder='Email' />
        </Form.Item>
        <Form.Item>
          <div>{footer}</div>
        </Form.Item>
      </Form>
    </ConfirmModal>
  );
};

export const SignIn = () => {
  const [form] = Form.useForm();
  const checkRemember = useCheckRemember();
  const store = useStore();
  const navigate = useNavigate();
  const { open, setOpen } = ConfirmModal.useModal({ open: false });
  const t = (str) => str;

  const onForget = () => {
    setOpen(true);
  };
  const onFinish = async (value: any) => {
    const { username, password } = value;
    const res = await store?.login?.(username, password);
    if (res) {
      navigate('/device');
    }
  };
  return (
    <>
      <Form form={form} style={{ marginTop: 4 }} onFinish={onFinish}>
        <Form.Item name='username' rules={[{ required: true, message: 'Please input your Email!' }]}>
          <InputLogin prefix={<UserOutlined />} placeholder='Email' />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>
          <InputLogin prefix={<PasswordOutlined />} type='password' placeholder='Password' />
        </Form.Item>
        <Form.Item style={{ marginTop: -10 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex' }}>
              <Checkbox {...checkRemember} style={{ marginRight: 8, padding: 0 }} />
              <div>Remember me</div>
            </div>
            <div onClick={onForget} style={{ color: '#4797A5', cursor: 'pointer' }}>
              {t('Forgot Password')}
            </div>
          </div>
        </Form.Item>
        <Form.Item style={{ marginTop: -10 }}>
          <Button type='primary' htmlType='submit' style={{ height: 36, width: 89 }}>
            Sign in
          </Button>
        </Form.Item>
      </Form>
      <ForgetModal open={open} setOpen={setOpen} />
    </>
  );
};
const tabItems = [
  {
    label: `Login`,
    key: 'Login',
    children: <SignIn />,
  },
  // {
  //   label: `Signup`,
  //   key: 'SignUp',
  //   children: <SignUp />,
  // },
];
export const Login = () => {
  return (
    <Tabs
      items={tabItems}
      style={{
        width: 360,
        margin: 'auto',
        fontSize: 14,
        fontWeight: 500,
      }}
    />
  );
};
