import { SVGProps } from 'react';

/**
 * Title Icon
 * TODO: replace with real icon
 * @param props
 * @returns
 */
export const ViewerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.967215 0.849976C0.66138 0.849976 0.413452 1.0979 0.413452 1.40374V10.2639C0.413452 10.5698 0.66138 10.8177 0.967215 10.8177H9.35415C9.45747 10.8177 9.50652 10.6863 9.43218 10.6145C8.91595 10.1162 8.60907 9.56969 8.60907 9.15641C8.60907 8.15964 10.3943 6.38762 12.5963 6.38762C13.345 6.38762 14.0456 6.5925 14.6441 6.90212C14.7195 6.94113 14.8113 6.88745 14.8113 6.80255V1.40374C14.8113 1.0979 14.5634 0.849976 14.2575 0.849976H0.967215ZM11.8803 3.4566L8.00393 7.33294L7.61236 7.72451L7.22079 7.33294L5.95107 6.06322L4.12759 7.8867L3.34445 7.10357L5.5595 4.88852L5.95107 4.49695L6.34264 4.88852L7.61236 6.15823L11.0971 2.67347L11.8803 3.4566ZM15.5867 9.15643C15.5867 9.7102 14.2479 11.15 12.5963 11.15C10.9448 11.15 9.60602 9.7102 9.60602 9.15643C9.60602 8.60265 10.9448 7.16288 12.5963 7.16288C14.2479 7.16288 15.5867 8.60265 15.5867 9.15643ZM13.4934 9.15645C13.4934 9.65191 13.0918 10.0535 12.5963 10.0535C12.1009 10.0535 11.6992 9.65191 11.6992 9.15645C11.6992 8.661 12.1009 8.25936 12.5963 8.25936C13.0918 8.25936 13.4934 8.661 13.4934 9.15645Z'
      fill='#172F41'
    />
  </svg>
);
