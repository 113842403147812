import { Navigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { Main } from '@/layout/login-layout/Main';
// import { Login } from './login/Login';
import { Login } from './login/LoginV2';
import { ResetPassword } from './reset-password/ResetPassword';

type SignRouterProps = {
  isAdmin?: boolean;
};
export const SignRouter = (props: SignRouterProps) => {
  const navigateTo = props.isAdmin ? '/admin/login' : '/user/login';
  return (
    <Routes>
      <Route index element={<Navigate to={navigateTo} replace={true} />}></Route>
      <Route element={<Main isAdmin={false} />}>
        <Route path='login' element={<Login />}></Route>
        <Route path='reset-password' element={<ResetPassword />}></Route>
        <Route path='*'>404</Route>
      </Route>
    </Routes>
  );
};
