import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useModal } from '@/theme';
import { Close } from '@nisar/icons';
type ConfirmModalProps = {
  title?: string;
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  footer?: React.ReactNode | false;
};
const basicSX = {
  position: 'absolute' as const,
  bgcolor: '#F9FBFF',
  color: '#405768',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  letterSpacing: 1,
  transform: 'translate(-50%, -50%)',
  width: 500,
  // bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 'px',
  padding: '16px 0 24px 0',
};

export const ConfirmModal = (props: ConfirmModalProps) => {
  const { open, onClose, style, title, footer } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={basicSX} style={{ ...style }}>
        <div
          className='confirm-modal-title'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '90%',
            margin: 'auto',
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: '#000',
            }}
          >
            {title}
          </div>
          <Close
            className='w-6 h-6'
            style={{
              color: '#172F41',
            }}
            onClick={onClose}
          />
        </div>
        <div className='confirm-modal-content' style={{ marginTop: 20 }}>
          {props.children}
        </div>
        {footer && <div className='confirm-modal-footer'>{footer}</div>}
      </Box>
    </Modal>
  );
};

ConfirmModal.useModal = useModal;
