import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { Form as AntForm, FormProps as AntFormProps } from 'antd';

export type FormProps = {
  children?: React.ReactNode;
} & AntFormProps;

export const Form: typeof AntForm = styled((props: FormProps) => {
  return <AntForm {...props}>{props.children}</AntForm>;
})((props) => {
  // const { size, theme } = props;
  return {
    '& .ant-form-item-required::before': {
      color: '#4797A5' + ' !important',
    },
  };
}) as any;

Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.useForm = AntForm.useForm;
Form.useWatch = AntForm.useWatch;
