import { Button, Space, App } from 'antd';
import { Form, InputLogin as Input } from '@/theme';
import { FormLayout } from '@/components/form';
import { ModalTitle, Select, BasicModal } from '@/theme';
import { useDeviceOptions } from '@/admin/pages/device';
import { useTargetOptions } from '@/admin/pages/target';

import { TenantData, useDrawStore } from '@/admin/pages/tenant';
import { postTarget, postTargetUpdate } from '../../api';
import { useTargetEditStore } from './store';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
type Props = {
  tenant?: TenantData;
};

/**
 * Add And Edit Target Modal
 * @param props
 * @returns
 */
export const EditModal = (props: Props) => {
  const { tenant } = props;
  const { id: tenantId } = tenant || {};
  const [form] = Form.useForm();
  const deviceId = Form.useWatch('deviceId', form);
  const store = useTargetEditStore();
  const { options: deviceOptions } = useDeviceOptions(tenantId || store.tenantId, store.open);
  const { options: targetOptions, getValue } = useTargetOptions(deviceId, store.open);
  const { message } = App.useApp();
  const drawStore = useDrawStore(
    (state) => ({
      refresh: state.refresh,
    }),
    shallow,
  );
  const { open, setOpen } = store;
  useEffect(() => {
    if (open) {
      const targetInfo = store.getTargetInfo();
      if (targetInfo) {
        form.setFieldsValue(targetInfo);
        form.setFieldValue('id', targetInfo.id);
      }
      form.setFieldValue('deviceId', store.deviceId);
    }
  }, [tenantId, store.deviceId, open]);
  const onFinish = async () => {
    const values = await form.validateFields();

    const device = deviceOptions.find((item) => item.value === store.deviceId)?.data;
    if (!device?.id) {
      message.error('Please select a device');
      return;
    }
    const tenantId = device?.tenantId;
    const requset = values.id ? postTargetUpdate : postTarget;
    const res = await requset({ ...values, tenantId });
    if (res.success) {
      message.success('Add success');
      // refresh draw page and target page
      store.refresh();
      drawStore.refresh();
      setOpen(false);
      form.resetFields();
    }
  };
  const onChange = (value: string) => {
    const v = getValue(value);
    if (v) {
      const { name, protocol, through, status } = v;
      form.setFieldValue('name', name);
      form.setFieldValue('protocol', protocol);
      form.setFieldValue('through', through);
      form.setFieldValue('status', status);
    }
  };
  const id = Form.useWatch('id', form);
  return (
    <BasicModal open={open} onClose={() => setOpen(false)} style={{ width: 500 }}>
      <ModalTitle>{!id ? 'Add New Target' : 'Edit Target'}</ModalTitle>
      <Form form={form} {...FormLayout} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} onFinish={onFinish}>
        <Form.Item label={'id'} name={'id'} hidden>
          <Input />
        </Form.Item>
        <Form.Item label={'tenantId'} name={'tenantId'} hidden>
          <Input />
        </Form.Item>
        <Form.Item label={'status'} name={'status'} hidden>
          <Input />
        </Form.Item>
        <Form.Item label={'Parent Device'} name={'deviceId'}>
          <Select options={deviceOptions} disabled={deviceId} />
        </Form.Item>
        <Form.Item label={'Target ID'} name={'targetId'} required style={{ marginBottom: 0 }}>
          <Select options={targetOptions} onChange={onChange} disabled={id} />
        </Form.Item>
        <Form.Item label=' ' colon={false} style={{ marginBottom: 0 }}>
          <div style={{ color: '#6D848F' }}>Manual input is available</div>
        </Form.Item>
        <Form.Item label={'Name'} name={'name'}>
          <Input />
        </Form.Item>
        <Form.Item label={'Protocol'} name={'protocol'}>
          <Input />
        </Form.Item>
        <Form.Item label={'Through'} name={'through'}>
          <Select
            options={[
              { label: 'Direct', value: 'direct' },
              { label: 'Gateway', value: 'gateway' },
            ]}
          />
        </Form.Item>
        <Form.Item label=' ' colon={false}>
          <Space>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
            <Button
              htmlType='reset'
              onClick={() => {
                setOpen(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </BasicModal>
  );
};
