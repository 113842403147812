// import { BarChart } from './Bar';
import { D3Bar } from './D3Bar';
export const TimeBar = () => {
  return (
    <div className='flex'>
      <div
        style={{
          width: 600,
          height: 400,
        }}
      >
        {/* <BarChart /> */}
        <D3Bar dynamic={{ isDemo: true, rangeMax: 5 }} />
      </div>
      <div
        style={{
          width: 600,
          height: 400,
        }}
      >
        {/* <BarChart /> */}
        <D3Bar id='dr-test-2' dynamic={{ isDemo: true, rangeMax: 10 }} />
      </div>
    </div>
  );
};
