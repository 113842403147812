import { querystring } from './query';

// type WSState = 'connecting' | 'connected' | 'closed' | 'error';
type WSOptions = {
  tokenName?: string;
  params?: Record<string, any>;
};
type SocketOpts = {
  url: string;
  tokenName: string;
  params?: Record<string, any>;
};
/**
 * @class WS
 * @description websocket
 * @waring 1. place not use in hooks, because it will not update hook state
 * 2. it use in store, it will update store state
 */
export class WS {
  socket: WebSocket;
  isOpen = false;
  limitConnectCount = 10;
  connectCount = 0;
  beartPack = 0;
  constructor(url: string, opts?: WSOptions) {
    const { tokenName = 'token', params } = opts || {};
    this.socket = this.createSocket({ url, tokenName, params });
    this.init();
  }
  /**create socket */
  createSocket(opts: SocketOpts) {
    const { url, tokenName, params = {} } = opts;
    const protocol = location.protocol === 'https:' ? 'wss' : 'ws';
    const host = location.host;
    const token = localStorage.getItem(tokenName);
    const searchQuery = querystring({ token, ...params });
    const href = `${protocol}:${host}${url}?${searchQuery}`;
    const socket = new WebSocket(href);
    return socket;
  }
  /** init socket */
  private init() {
    const socket = this.socket;
    socket.onerror = () => {
      this.clear();
      this.reconnect();
    };
    socket.onclose = () => {
      this.clear();
      this.reconnect();
    };
    socket.onopen = () => {
      this.isOpen = true;
      this.connectCount = 0;
      this.sendBeartPack();
    };
  }
  /**on socket message */
  onMessage<T = any>(cb: (data: T) => void) {
    this.socket.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        cb(data);
      } catch (error) {
        cb(e.data);
      }
    };
  }
  /**on socket open */
  async onOpen() {
    return new Promise((resolve) => {
      if (this.isOpen) return resolve(true);

      this.socket.onopen = () => {
        resolve(true);
      };
    });
  }
  /** add socket beartbeat pack interval time */
  sendBeartPack() {
    this.beartPack = setInterval(() => {
      this.socket.send(JSON.stringify({ type: 'beart' }));
    }, 35 * 1000) as any;
  }
  reconnect() {
    this.isOpen = false;
    if (this.connectCount < this.limitConnectCount) {
      this.connectCount++;
      this.socket = new WebSocket(this.socket.url);
    }
  }
  private clear() {
    clearInterval(this.beartPack);
  }
  /**
   * @description close socket
   */
  close() {
    this.clear();
    this.socket.close();
  }
}
