import Styles from './index.module.scss';
import NisarLogoPNG from './img/nisar-logo.png';
import { NisarLogo } from './Icon';
// import { NisarRightTop } from './Icon';
export const CopyWrite = () => {
  return <div className={`${Styles['text']}`}>Copyright ©2023 Produced by Manhe Technology Ltd., Co</div>;
};

type LogoProps = {
  style?: React.CSSProperties;
};
export const Logo = (props: LogoProps) => {
  return (
    <img
      src={NisarLogoPNG}
      style={{
        width: 175,
        height: 42,
        position: 'absolute',
        right: 48,
        top: 30,
        display: 'flex',
        ...props?.style,
      }}
    />
  );
};

// export const Logo = (props: LogoProps) => {
//   return (
//     <>
//       <NisarRightTop
//         style={{
//           width: 175,
//           height: 42,
//           position: 'absolute',
//           right: 48,
//           top: 30,
//           display: 'flex',
//           ...props?.style,
//         }}
//       />
//     </>
//   );
// };

type NisarLogoTextProps = {
  style?: React.CSSProperties;
  text?: string;
  size?: number;
};
export const NisarLogoText = (props: NisarLogoTextProps) => {
  const { text = 'NISAR Admin', size = 32, style } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Orbitron',
        fontWeight: 500,
        ...style,
      }}
    >
      <NisarLogo
        style={{
          width: size,
          height: size,
        }}
      />
      {text}
    </div>
  );
};
