import { create } from 'zustand';
import { getCharts, ViewerChartData } from '../../api';
import { produce } from 'immer';

type TemperatureChartData = {
  type: 'temperature';
  labels: number[];
  data: number[];
  /** all chart need same name */
  title?: string;
  xLable?: string;
  yLable?: string;
  /** all chart need key */
  id?: string;
  dev?: boolean;
} & ViewerChartData;

const transfromTempChart = (data: ViewerChartData[]) => {
  return data.map((item) => {
    return {
      ...item,
      id: item.targetId,
      title: item.title ?? 'Temperature',
      type: 'temperature' as const,
      yLabel: item.yLabel ?? 'T [℃]',
      xLabel: item.xLabel ?? 'Time [Seconds]',
      labels: [],
      data: [],
    };
  });
};
export const demo: TemperatureChartData[] = [
  {
    type: 'temperature' as const,
    labels: [],
    data: [],
    title: 'demo',
    id: 'demo',
    deviceId: 'nisar1648497612922880000:0',
    lowerThreshold: 22,
    targetId: 'demo',
    targetName: 'demo',
    upperThreshold: 27,
    dev: true,
  },
];
type Data = {
  /** @deprecated */
  viewerType?: 'temperature';
  viewerTitle?: string;
  viewerValue?: number;
  targetId: string;
  temperature?: number;
};
type Store<T = any> = {
  /** temperature list */
  tempList: T[];
  addTargetLabel: (data: T) => void;
  init: (deviceId: string) => void;
  addData: (data: Data) => void;
  dispose(): void;
};

/**
 * @description: data viewer store
 */
export const useDataViewerStore = create<Store<TemperatureChartData>>((set, get) => ({
  tempList: [],
  addTargetLabel: (data) => {},
  init: async (deviceId) => {
    const res = await getCharts(deviceId);
    if (res.success) {
      const data = res.data ?? [];
      set(
        produce((state) => {
          const temp = transfromTempChart(data);
          state.tempList = [...temp];
          // state.tempList = [...temp, ...demo]; // TODO: remove demo
        }),
      );
    }
  },
  addData: (data) => {
    const { tempList } = get();
    const findQuery = (item: any) => item.id === data.targetId && item.title === data.viewerTitle;
    const temp = tempList.find(findQuery);
    if (temp) {
      const _labels = temp.labels;
      const _data = temp.data;
      const count = (_labels[_labels.length - 1] || 0) as number;
      _labels.push(count + 1);
      _data.push(Number(data.viewerValue));
      if (_labels.length > 100) {
        _labels.shift();
        _data.shift();
      }
      temp.labels = _labels;
      temp.data = _data;
      set(
        produce((state) => {
          state.tempList = tempList.map((item) => {
            if (findQuery(item)) {
              return temp;
            }
            return item;
          });
        }),
      );
    }
  },
  dispose: () => {
    // set({ list: [] });
  },
}));
