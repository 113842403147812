import { Table } from '@/theme';
import { ColumnsType } from 'antd/es/table';
import { MsgSingal, GPSSignal, NoStarIcon, StarIcon, Status } from '../../component';
import { DeviceData } from '../../type';
import { toDevice } from '../../module';
import { useDeviceStore } from '../../store';
export const sortTarget = (a: DeviceData, b: DeviceData) => {
  const _a = a?.targets?.map?.((i) => i.name)?.join('') || '';
  const _b = b?.targets?.map?.((i) => i.name)?.join('') || '';
  return _a.localeCompare(_b);
};
type Props = {
  dataSource: any[];
  [key: string]: any;
};
export const DeviceList = (props: Props) => {
  const { dataSource = [] } = props;
  const { loading, updateStar } = useDeviceStore((state) => {
    return {
      loading: state.loading,
      updateStar: state.updateStar,
    };
  });
  const columns: ColumnsType<DeviceData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      render: (text, record, index) => {
        const isStar = record?.star;
        return (
          <div className='flex'>
            <div
              onClick={(e) => {
                e.stopPropagation();
                updateStar?.(record);
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {!!isStar ? <StarIcon /> : <NoStarIcon />}
            </div>
            <div className='ml-2.5'>{text}</div>
          </div>
        );
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 250,
    },
    {
      title: 'IP Address',
      dataIndex: 'ip',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (text, record, index) => {
        const isOnline = text !== 'OFFLINE';
        return <Status status={isOnline ? 1 : 2} />;
      },
    },
    {
      title: 'Target List',
      dataIndex: 'targets',
      sorter: sortTarget,
      render: (text) => {
        const _ = (text || []).map((item) => item.name).filter(Boolean);
        return <div className='flex'>{_.join(',')}</div>;
      },
    },
    {
      title: 'LTE',
      dataIndex: 'gsmSignal',
      width: 50,
      render: (text) => {
        return <MsgSingal singal={text} />;
      },
    },
    {
      title: 'GPS',
      dataIndex: 'gpsMode',
      width: 50,
      render: (text) => {
        return <GPSSignal singal={text} />;
      },
    },
  ];

  return (
    <Table
      rowKey={'id'}
      onRow={(record) => {
        return {
          onClick: () => {
            toDevice(record);
          },
        };
      }}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      showTableBorder={false}
      rowClassName={'cursor-pointer'}
    ></Table>
  );
};
