import { useEffect, useState } from 'react';
import { Tabs } from '@/theme';
import { Device } from '../device/Device';
import { User } from '../tenant-user/User';
import { Target, EditModal } from '../target';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import cs from 'classnames';
import { TenantData, getTenant, putTenant } from './api';
import { useRequest } from '@/theme';
import { useDrawStore } from './module';
import { shallow } from 'zustand/shallow';
import { useStore } from './store';
import { AvatarUpload } from './components/Upload';

type LabelTextProps = {
  children: React.ReactNode;
  label?: string;
  style?: React.CSSProperties;
};
export const LabelText = (props: LabelTextProps) => {
  const { children, label, style } = props;
  return (
    <div
      className='flex'
      style={{
        fontSize: 14,
        fontWeight: 500,
        ...style,
      }}
    >
      <div
        style={{
          color: '#6D848F',
        }}
      >
        {label}
      </div>
      <div style={{ marginLeft: 4, color: '#000' }}>{children}</div>
    </div>
  );
};
export enum TenantTab {
  Device = 'Device',
  User = 'User',
  Target = 'Target',
}
const DeviceNumber = styled('div')((props) => {
  return {
    marginRight: 64,
    position: 'relative',
    '&.hasDivider::before': {
      height: '32px',
      left: '-22px',
      top: '15px',
      content: '" "',
      position: 'absolute',
      borderLeft: '1px solid #C2CFD4',
    },
  };
});

export const Tenant = () => {
  const tenantStore = useStore();
  const { tab, setTab } = tenantStore;
  const store = useDrawStore((state) => {
    return {
      setOpen: state.setOpen,
      setDevice: state.setDevice,
    };
  }, shallow);
  const params = useParams();
  const id = params.id;
  const [tenant, setTenant] = useState<TenantData>();
  useEffect(() => {
    if (id) {
      run({ tenantId: id });
    }
  }, []);
  const { run, refresh } = useRequest(getTenant, {
    onSuccess: (res) => {
      if (res.success) {
        setTenant(res.data);
      }
    },
  });
  const onUploadTenantLogo = async (value: string) => {
    if (tenant) {
      const { id, name, contact, notes } = tenant;
      const _tenant = {
        id,
        name,
        contact,
        notes,
        logo: value,
      };
      const res = await putTenant(_tenant);
      if (res.success) {
        refresh();
      }
    }
  };
  const items = [
    {
      key: 'Device',
      label: 'Device',
      children: <Device tenant={tenant} />,
    },
    {
      key: 'User',
      label: 'User',
      children: <User tenant={tenant} />,
    },
    {
      key: 'Target',
      label: 'Target',
      children: <Target tenant={tenant} />,
    },
  ];
  const TenantNum = [
    {
      key: 'Devices',
      number: tenant?.deviceNum || 0,
    },
    {
      key: 'Targets',
      number: tenant?.targetNum || 0,
    },
    {
      key: 'User',
      number: tenant?.userNum || 0,
    },
  ];
  return (
    <div className='flex flex-col h-full' style={{ height: '100%' }}>
      <div className='flex justify-between' style={{ height: 130 }}>
        <div className='flex'>
          {/* <img
            src={NisarTenantPNG}
            style={{
              width: 104,
              height: 104,
            }}
            alt=''
          /> */}
          <div
            style={{
              width: 104,
              height: 104,
              borderRadius: 2,
              overflow: 'hidden',
            }}
          >
            <AvatarUpload value={tenant?.logo} onChange={onUploadTenantLogo} />
          </div>
          <div style={{ marginLeft: 24 }}>
            <div
              style={{
                fontSize: 24,
                fontWeight: 700,
                color: '#172f41',
              }}
            >
              {tenant?.name}
            </div>
            <div className='mt-1'>
              <LabelText label='ID'>{tenant?.id}</LabelText>
              <LabelText style={{ marginTop: 4 }} label='Contact'>
                {tenant?.contact}
              </LabelText>
            </div>
          </div>
        </div>
        <div
          className='flex'
          style={{
            color: '#172F41',
            fontWeight: 500,
          }}
        >
          {TenantNum.map((item, index) => {
            return (
              <DeviceNumber key={item.key} className={cs({ hasDivider: index !== 0 })}>
                <div
                  style={{
                    fontSize: 14,
                    color: '#6D848F',
                  }}
                >
                  {item.key}
                </div>
                <div
                  style={{
                    fontSize: 20,
                    textAlign: 'right',
                    marginTop: 8,
                  }}
                >
                  {item.number}
                </div>
              </DeviceNumber>
            );
          })}
        </div>
      </div>
      <div className='flex-grow overflow-hidden' style={{ marginBottom: 42 }}>
        <Tabs
          type='line'
          activeKey={tab}
          showScroll
          onChange={(key: any) => {
            setTab(key);
            if (key !== TenantTab.Device) {
              store.setOpen(false);
            }
          }}
          items={items}
        />
      </div>
      <EditModal tenant={tenant} />
    </div>
  );
};
