import { create } from 'zustand';

type Store = {
  selectedRowKeys?: string[];
  setSelectedRowKeys?: (selectedRowKeys: string[]) => void;
  removeRowKey?: (rowKey: string) => void;
};
export const useRealDeviceStore = create<Store>((set, get) => ({
  selectedRowKeys: [],
  setSelectedRowKeys: (selectedRowKeys: string[]) => {
    set({ selectedRowKeys });
  },
  removeRowKey: (rowKey: string) => {
    const { selectedRowKeys } = get();
    const index = selectedRowKeys?.findIndex((item) => item === rowKey);
    if (index !== undefined && index !== -1) {
      selectedRowKeys?.splice(index, 1);
      set({ selectedRowKeys });
    }
  },
}));
