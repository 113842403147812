import { useEffect, useMemo, useState } from 'react';
import Styles from './index.module.scss';
import { DeviceHeader } from './DeviceHeader';
import { DeviceList } from './DeviceList';
import { DeviceMap } from './DeviceMap';
import { DeviceCardList } from './DeviceCard';
import { DeviceData } from '../../type';
import { getCenterByDevice, useDeviceStore } from '../../store';
export type DeviceType = 'list' | 'map' | 'card';

type Props = {
  [key: string]: any;
};
export const DeviceContent = (props: Props) => {
  const { list, updateStar, activeDevice, filterData } = useDeviceStore((state) => {
    return {
      list: state.list, //
      updateStar: state.updateStar,
      activeDevice: state.activeDevice,
      filterData: state.filterData,
    };
  });
  const [type, setType] = useState<DeviceType>('card');
  const [data, setData] = useState<DeviceData[]>([]);
  const { filter, status } = filterData || {};
  useEffect(() => {
    // setData(list!);
    if (!list) {
      return;
    }
    const _list = list
      .filter((item) => {
        if (filter === 'star') {
          return item.star === true;
        }
        return true;
      })
      .filter((item) => {
        if (status === 'ONLINE') {
          return item.status !== 'OFFLINE';
        }
        if (status === 'OFFLINE') {
          return item.status === 'OFFLINE';
        }
        return true;
      });
    setData(_list);
  }, [list, filter, status]);
  const content = useMemo(() => {
    switch (type) {
      case 'list':
        return (
          <div className='flex-grow mt-4'>
            <DeviceList dataSource={data} />
          </div>
        );
      case 'map':
        let [device] = data || [];
        device = activeDevice || device;
        const center = getCenterByDevice(device);
        return (
          <div className='flex-grow mt-4'>
            <DeviceMap dataSource={data} center={center} />
          </div>
        );
      case 'card':
        return (
          <div className='flex-grow mt-4' style={{ overflow: 'hidden' }}>
            <div
              className='no-scrollbar'
              style={{
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <DeviceCardList dataSource={data} updateStar={updateStar} />
            </div>
          </div>
        );
    }
  }, [type, data, activeDevice]);
  return (
    <div style={{ flexGrow: 1, overflow: 'hidden' }}>
      <div className={Styles.main}>
        <DeviceHeader type={type} setType={setType} />
        {content}
      </div>
    </div>
  );
};
