import { getDeviceByDetail } from '../api';
import { create } from 'zustand';
import { DeviceData, Target } from '../type';

import { RightStore, createRightStore } from './right';
export { RightKey } from './right';
import { LeftStore, createLeftStore } from './left';
import { getCityByLonLat } from '@/pages/device/api';

export type BottomKey = 'Console' | 'Data viewer' | 'Location' | 'Easy viewer';
type DeviceState = {
  device?: DeviceData;
  targets?: Target[];
  bottomKey: BottomKey;
  openBottom: boolean;
  city?: string;
};
type DeviceAction = {
  getDevice?: (deviceId: string) => void;
  setBottomKey?: (key: BottomKey) => void;
  setOpenBottom?: (open: boolean) => void;
  setTargetToTop?: (target: Target) => void;
  setTargetVisible?: (target: Target) => void;
  getCity?: (longitude: string | number, latitude: string | number) => void;
  dispose?: () => void;
};
export type DetailStore = DeviceAction & DeviceState & RightStore & LeftStore;
export const useDetailStore = create<DetailStore>((set, get, store) => ({
  ...createRightStore(set, get, store),
  ...createLeftStore(set, get, store),
  device: undefined,
  targets: [],
  bottomKey: 'Console',
  // bottomKey: 'Data viewer', // TODO: remove
  setBottomKey: (key: BottomKey) => {
    set({ bottomKey: key });
  },
  openBottom: false,
  // openBottom: true, // TODO: remove
  setOpenBottom: (open: boolean) => {
    set({ openBottom: open });
  },
  getDevice: async (deviceId: string) => {
    const res = await getDeviceByDetail(deviceId);
    if (res.success) {
      const data = res.data ?? {};
      const targets = data.targets ?? [];
      set({ device: data, targets });
      const { gpsLon, gpsLat } = data;
      if (gpsLon && gpsLat) {
        get().getCity?.(gpsLon, gpsLat);
      }
      // // TODO: remove this
      // const [firstTarget] = targets;
      // get().setLeftKey?.(firstTarget?.id);
      // get().setActiveTarget?.(firstTarget);
      // get().getCommand?.(firstTarget?.id);
    }
  },
  getCity: async (longitude, latitude) => {
    const data = await getCityByLonLat(longitude, latitude);
    if (data?.city) {
      set({ city: data.city });
    } else {
      set({ city: '' });
    }
  },
  setTargetToTop: (target: Target) => {
    const { targets } = get();
    const newTargets = targets?.filter((item) => item.id !== target.id);
    newTargets?.unshift(target);
    set({ targets: newTargets });
  },
  setTargetVisible: (target: Target) => {
    const { targets } = get();
    const newTargets = targets?.map((item) => {
      if (item.id === target.id) {
        return { ...item, noShow: !item.noShow };
      }
      return item;
    });
    set({ targets: newTargets });
  },
  dispose: () => {
    set({ bottomKey: 'Console', openBottom: false, device: undefined, targets: [] });
  },
}));
