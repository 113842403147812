import React from 'react';

export { ThemeProvider, useModeStore, useTheme } from './ThemeProvider';

export * from './rc/blank';
export * from './rc/breadcrumb';
export * from './rc/checkbox';
export * from './rc/layout';
export * from './rc/text';
export * from './rc/icon';
export * from './rc/tab';
export * from './rc/loading';
export * from './rc/table';
export * from './rc/button';
export * from './rc/input';
export * from './rc/dropdown';
export * from './rc/modal';
export * from './rc/form';
export * from './rc/menu';
export * from './rc/select';
export * from './rc/space';

// for rc-hooks
export * from './rc-hooks';

/** demo */
export const SwitchDemo = React.lazy(() => import('./rc/switch-mode/'));
