import { Table, sorterStr } from '@/theme';
import Styles from './Console.module.scss';
import { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import { useConsoleStore } from './store';
type ConsoleData = {
  message: string;
  severity: '1' | '2' | '4' | '8' | '16';
  targets: string;
  stamp: string;
  location: string;
  id: string;
};
type SeverityLableProps = {
  severity: '1' | '2' | '4' | '8' | '16';
};
export const SeverityLable = (props: SeverityLableProps) => {
  const label = severityInfoMap()[props.severity]?.label;
  if (!label) return <>-</>;
  const statusColor = severityInfoMap()[props.severity]?.color;
  return (
    <div className='flex' style={{ color: statusColor }}>
      <div
        style={{
          width: 6,
          height: 6,
          borderRadius: 6,
          background: statusColor,
          marginRight: 4,
          alignSelf: 'center',
          display: 'flex',
        }}
      ></div>
      <span>{label}</span>
    </div>
  );
};
const severityInfoMap = () => {
  return {
    1: { label: 'Debug', value: 1, color: '#d2331e' },
    2: { label: 'Info', value: 2, color: '#49AA19' },
    4: { label: 'Warn', value: 4, color: '#FFCD29' },
    8: { label: 'Error', value: 8, color: '#D32029' },
    16: { label: 'Fatal', value: 16, color: '#D32029' },
  };
};

export const Console = () => {
  // const { device } = useDetailStore((state) => {
  //   return { device: state.device };
  // });
  const store = useConsoleStore();

  useEffect(() => {
    const cs = '.' + Styles.main + ' .last-row';
    const q = document.querySelector(cs);
    if (q) {
      q.scrollIntoView({ behavior: 'smooth' });
    }
  }, [store.list.length]);
  const columns: ColumnsType<ConsoleData> = [
    {
      title: '#',
      dataIndex: 'id',
      width: 70,
      render: (_, _record, index) => {
        return <>#{index + 1}</>;
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      width: 320,
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      render: (value) => {
        return <SeverityLable severity={value} />;
      },
    },
    // {
    //   title: 'Devices',
    //   dataIndex: 'devices',
    //   render: (value) => {
    //     return <div>{device?.name}</div>;
    //   },
    // },
    {
      title: 'Stamp',
      dataIndex: 'stamp',
      sorter: (a, b) => sorterStr(a.location, b.location),
    },
    {
      title: 'Targets',
      dataIndex: 'targets',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: (a, b) => sorterStr(a.location, b.location),
    },
  ];
  return (
    <Table
      scroll={{ y: 270, x: '100%', scrollToFirstRowOnChange: true }}
      style={{ height: '100%' }}
      className={Styles.main}
      rowKey={'id'}
      columns={columns}
      rowClassName={(record, index) => {
        if (index === store.list.length - 1) {
          return 'last-row';
        }
        return '';
      }}
      dataSource={store.list}
      pagination={false}
    />
  );
};
