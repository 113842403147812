import { Button, Input, TextArea, Form, FormModal, Select } from '@/theme';
import { useCallback, useEffect, useMemo } from 'react';
import { EasyViewStore } from '../model';

export type EditModalProps = {
  Action?: (props?: { toggle?: () => void }) => JSX.Element;
  showAction?: boolean;
  [key: string]: any;
};

export const EditModal = (props: EditModalProps) => {
  const { showAction = false, Action } = props;
  const easyViewStore = EasyViewStore.useStore();
  const formData = easyViewStore?.useKey('formData') || ({} as any);
  const open = easyViewStore?.useKey('modalOpen');
  const { title, description, category } = formData;

  const toggle = useCallback(() => {
    easyViewStore?.toggleModal();
  }, [easyViewStore]);

  const close = () => {
    toggle();
  };

  const onChange = (key: 'title' | 'description' | 'foxgloveUrl' | 'category', value: string) => {
    easyViewStore?.changeFormData(key, value);
  };

  const handleSave = () => {
    easyViewStore?.saveFormData();
  };
  const actions = (
    <>
      <Button type='text' onClick={close}>
        Cancel
      </Button>
      <Button type='primary' onClick={handleSave}>
        Save
      </Button>
    </>
  );

  const ActionInfo = useMemo(() => {
    if (!showAction) return null;
    const AddNew = <Button onClick={toggle}>+ Create</Button>;
    return Action ? <Action toggle={toggle} /> : AddNew;
  }, [Action, showAction, toggle]);

  return (
    <>
      {ActionInfo}
      <FormModal
        title={!formData?.id ? 'Create' : 'Edit'}
        open={open}
        onClose={close}
        actions={actions}
        style={{ width: 420 }}
        bodyStyle={{
          paddingTop: 12,
        }}
      >
        <Form wrapperCol={{ span: 24 }}>
          <Form.Item label='' style={{ marginBottom: 2 }}>
            <Select
              value={category || 'plot'}
              options={[
                { label: 'Plot', value: 'plot' },
                { label: 'Image', value: 'image' },
                { label: '3D', value: '3d' },
                { label: 'Dashboard', value: 'gauge' },
                { label: 'Raw Message', value: 'rawMessage' },
              ]}
              onChange={(value: any) => {
                onChange('category', value);
              }}
            />
          </Form.Item>
          <div
            style={{
              color: '#6d848f',
            }}
          >
            {!formData?.id ? 'CANNOT be modified later.' : 'CANNOT be modified.'}
          </div>
          <Form.Item label='' style={{ marginBottom: 12 }}>
            <Input placeholder='Title' value={title || ''} onChange={(e) => onChange('title', e.target.value)} />
          </Form.Item>
          <Form.Item label='' style={{ marginBottom: 0 }}>
            <TextArea placeholder='Describe...' rows={4} value={description || ''} onChange={(e) => onChange('description', e.target.value)} />
          </Form.Item>
        </Form>
      </FormModal>
    </>
  );
};
