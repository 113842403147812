import { BodyTitle, FormModal, LabelContent } from '@/theme';
import { useDownloadStore, DownloadFile } from './store';
import { Button } from 'antd';
import { useExtraData } from '../mitt';

export const Download = () => {
  const store = useDownloadStore();
  const { file } = store;
  useExtraData<DownloadFile>('DOWNLOAD_FILE', (file) => {
    store.openFile?.(file);
  });
  return (
    <FormModal
      open={store.open}
      title={<BodyTitle>Download</BodyTitle>}
      onClose={() => {
        store.setOpen(false);
      }}
    >
      <LabelContent
        title='FileName:'
        span={8}
        sx={{
          marginBottom: '20px',
        }}
      >
        <div>{file?.fileName}</div>
      </LabelContent>
      <LabelContent title='File Description:' span={8}>
        <div
          className='no-scrollbar'
          style={{
            maxHeight: '100px',
            wordBreak: 'break-all',
          }}
        >
          {file?.desc}
        </div>
      </LabelContent>
      <LabelContent span={8}>
        <Button type='primary' onClick={() => store.download()} style={{ marginTop: '20px' }}>
          Download
        </Button>
      </LabelContent>
    </FormModal>
  );
};
