import { request } from '@/module';

export type EasyConfig = {
  id?: number | string;
  title?: string;
  pin?: boolean;
  foxgloveUrl?: string;
  category?: string; // 3d plot chart
  description?: string;
  config?: string;
  createdBy?: string;
  createTime?: string;
};

export const postSave = (data: EasyConfig) => {
  return request.post('/easy-view/saveOrUpdate', data);
};

type ListPrarms = {
  deviceNo?: string;
  pageSize?: number;
  page?: number;
};
export const getList = <T = EasyConfig[]>(params: ListPrarms) => {
  return request.get<T>('/easy-view/list', { params });
};

export const deleteEasy = (params: { id: string }) => {
  return request.get(`/easy-view/delete`, { params });
};
