import { getDeviceList as getList, postDeviceStar } from '../api';
import { create } from 'zustand';
import { DeviceData } from '../type';
import { LatLngExpression } from 'leaflet';
import { createModalStore } from './modal';

export const getCenterByDevice = (device: DeviceData) => {
  let center: LatLngExpression | undefined = undefined;
  if (device && device.gpsLat && device.gpsLon) {
    const { gpsLat, gpsLon } = device;
    center = [Number(gpsLat), Number(gpsLon)];
  }
  return center;
};
type FormData = {
  page: number;
  pageSize: number; //

  [key: string]: any;
} & { tenantId?: string };
type FilterData = {
  filter?: string;
  status?: string;
};
type DeviceState = {
  loading: boolean;
  list?: DeviceData[];
  // refresh list when change
  refresh: number;
  // query params
  formData?: FormData;
  // filter data by status and filter
  filterData?: FilterData;
  // fly to device in map
  activeDevice?: DeviceData;
};
type DeviceAction = {
  query?: () => void;
  getList?: (params: any) => void;
  setFormData?: (params: any) => void;
  setFilterData?: (params: any) => void;
  setRefresh?: () => void;
  updateStar?: (data?: DeviceData) => void;
  setActiveDevice?: (data?: DeviceData) => void;
};
export type DeviceStore = DeviceAction & DeviceState;
export const useDeviceStore = create<DeviceStore>((set, get, store) => ({
  loading: false,
  list: [],
  formData: { page: 1, pageSize: 10 },
  filterData: { filter: '', status: '' },
  refresh: 0,
  activeDevice: undefined,
  ...createModalStore(set, get, store),
  getList: async (params) => {
    set({ loading: true });
    const res = await getList({ ...params });
    set({ loading: false });
    if (res.success) {
      const data = res.data ?? {};
      set({ list: data?.list || [] });
    }
  },
  setRefresh: () => {
    set((state) => ({ refresh: state.refresh + 1 }));
  },
  query: () => {
    const { formData, getList, loading } = get();
    if (loading) return;
    getList?.(formData);
  },
  setFormData: (params) => {
    const formData = get().formData;
    set({ formData: { ...formData, ...params } });
  },
  setFilterData: (params) => {
    const filterData = get().filterData;
    set({ filterData: { ...filterData, ...params } });
  },
  updateStar: async (data) => {
    if (!data?.id) return;
    const newStar = !data.star;
    const res = await postDeviceStar({ deviceId: data.id, star: newStar });
    if (res.success) {
      set((state) => {
        const list = state.list?.map((item) => {
          if (item.id === data.id) {
            return { ...item, star: newStar };
          }
          return item;
        });
        return { list };
      });
    }
  },
  setActiveDevice: (data) => {
    set({ activeDevice: data });
  },
}));
