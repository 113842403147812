import { StateCreator } from 'zustand';
import { Target } from '../type';

type Store = {
  open?: boolean;
  modalData?: Target;
  setOpen?: (open: boolean) => void;
  close?: () => void;
  setModalData?: (data?: Target) => void;
  [key: string]: any;
};
export const createModalStore: StateCreator<Store, [], [], Store> = (set, get) => ({
  open: false,
  modalData: undefined,
  setOpen: (open) => {
    set({ open: open });
  },
  close: () => {
    set({ open: false });
  },
  setModalData: (data) => {
    set({ modalData: data });
  },
});
