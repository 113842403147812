import { LoadingData } from '@/theme';
import { useDetailStore } from '../../store';
import { shallow } from 'zustand/shallow';
import { ProtocolTab } from '../protocol/ProtocolTab';
import { Target } from '../../type';
import { useCommondList } from '../protocol/model';
import { CommandCard, Divider } from '../protocol/Protocol';
import { postTargetApply } from '../../api';

type LabelContentProps = {
  className?: string;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
};
export const LabelContent = (props: LabelContentProps) => {
  return (
    <div className={props?.className}>
      <div
        style={{
          fontWeight: 500,
          marginTop: 10,
        }}
      >
        {props?.title}
      </div>
      <div>{props?.children}</div>
    </div>
  );
};
export const TargetProperties = () => {
  const { activeTarget, device } = useDetailStore((state) => {
    return {
      activeTarget: state.activeTarget,
      device: state.device,
    };
  }, shallow);
  const LC = LabelContent;
  return (
    <div style={{ fontSize: 12, color: '#172F41' }}>
      <LC title='Target Name:'>
        <div>{activeTarget?.name}</div>
      </LC>
      <LC title='Target ID:'>{activeTarget?.id}</LC>
      <LC title={<div className='text-red-500'>I/F Type:</div>}>-</LC>
      <LC title='Status:'>{activeTarget?.status}</LC>
      <LC title='Parent Device:'>{device?.name}</LC>
      <LC title='Feed Detection Status'>No</LC>
      <LC title='Device Properties'>
        <div>IP - {device?.ip}</div>
        <div>PORT - {(device as any)?.port}</div>
        <div>PROTOCOL - {device?.protocol}</div>
        <div>MEMO -</div>
      </LC>
    </div>
  );
};
type PropertiesProps = {
  data?: Target;
  [key: string]: any;
};
export const Properties = (props: PropertiesProps) => {
  const { data } = props;
  const { protocols, loaddingCommand } = useDetailStore((state) => {
    return {
      protocols: state.protocols,
      loaddingCommand: state.loaddingCommand,
    };
  });
  const { active, setActive, protocolList } = ProtocolTab.useProtocol(data);
  const { commandList } = useCommondList({ protocols, activeProtocol: active, target: data, commandKey: 'properties' });
  return (
    <div>
      <ProtocolTab active={active} setActive={setActive} protocolList={protocolList} />
      <div
        style={{
          flexGrow: 1,
          overflow: 'hidden',
          marginTop: 10,
        }}
      >
        <LoadingData loading={loaddingCommand}>
          <div className='no-scrollbar' style={{ height: '100%', paddingBottom: '10px', overflow: 'scroll' }}>
            {commandList.map((item, index) => {
              return <CommandCard commandKey='properties' data={item} key={index} onCommand={postTargetApply} />;
            })}
            {commandList.length === 0 && <div>No Data</div>}
          </div>
        </LoadingData>
      </div>
      <Divider />
      <div style={{ marginTop: 4 }}>
        <TargetProperties />
      </div>
    </div>
  );
};
