import { useState } from 'react';
import { Popover } from '@mui/material';
import Draggable from 'react-draggable';
import { createPortal } from 'react-dom';
import { Close, Popup, Topic } from '@nisar/icons';

/**
 * useToolPopover for toolPopover
 * @returns
 */
const useToolPopover = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isPopover = Boolean(anchorEl);

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const close = () => {
    setAnchorEl(null);
  };
  return {
    anchorEl,
    isPopover,
    open,
    close,
  };
};

export type PopoverSingleProps = {
  children?: React.ReactNode;
  // iconName: string // TODO
  title?: string;
  icon?: React.ReactNode | string;
  isSingle: boolean;
  onClose?: () => void;
};

const PopoverSingle = (props: PopoverSingleProps) => {
  const { isSingle = true, title, icon, onClose: pOnClose } = props;
  const draggable = document.querySelector('#id-container-draggable');
  const onClose = () => {
    pOnClose?.();
  };
  if (draggable && isSingle) {
    return createPortal(
      <Draggable handle='[data-role="handle"]' bounds={'body'}>
        <div className='flex flex-col overflow-hidden' style={{ marginTop: 48 }}>
          <div
            data-role='handle'
            className='flex items-center  py-2 pl-4 pr-2'
            style={{
              height: 36,
              display: 'flex',
              background: '#D8E0E8',
              borderBottom: '1px solid #C2CFD4',
            }}
          >
            <Topic className='cursor-pointer h-6 w-6' />
            <div className='grow hover:cursor-move'>{title || 'modal'}</div>
            <Close className='cursor-pointer h-6 w-6' onClick={onClose} />
          </div>

          <div style={{ minWidth: 300 }}>{props.children}</div>
        </div>
      </Draggable>,
      draggable,
    );
  }
  return null;
};

type ToolPopoverProps = {
  isPopover: boolean;
  anchorEl: null | HTMLElement;
  close: () => void;
  title?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode | string;
  isSingle?: boolean;
  /** pop up, to set single true */
  onPopup?: () => void;
};

export const ToolPopover = (props: ToolPopoverProps) => {
  const { isPopover, anchorEl, icon, close, title = 'modal', isSingle = false, onPopup } = props;
  if (isSingle) {
    return null;
  }
  const onClick = () => {
    onPopup?.();
  };
  const ContentWrapper = (
    <div className='flex max-h-[80vh] flex-col overflow-hidden'>
      <div
        data-role='handle'
        className='flex items-center  py-2 pl-4 pr-2'
        style={{
          height: 36,
          display: 'flex',
          background: '#D8E0E8',
          borderBottom: '1px solid #C2CFD4',
        }}
      >
        {icon}
        <div
          className='grow'
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: '#1F2937',
          }}
        >
          {title}
        </div>
        <Popup className='cursor-pointer h-6 w-6' onClick={onClick} />
      </div>

      <div style={{ minWidth: 300 }}>{props.children}</div>
    </div>
  );
  return (
    <Popover
      classes={{ paper: 'bg-neutral-light-7' }}
      open={isPopover}
      anchorEl={anchorEl}
      onClose={close}
      container={document.querySelector('#root')}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {ContentWrapper}
    </Popover>
  );
};
ToolPopover.useToolPopover = useToolPopover;
ToolPopover.PopoverSingle = PopoverSingle;
