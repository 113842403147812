import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Auth } from './Auth';
import Styles from './Layout.module.scss';
import { useTheme } from '@/theme';
import { useEffect, useState } from 'react';
import { Input } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { SearchOutlined } from '@ant-design/icons';
import { LayoutMenu } from './LayoutMenu';
import { UserAvatarInfo } from './Avatar';
import { Breadcrumb } from '@/theme';
export const LayoutDrawerId = 'layout-drawer-id';
import { breadcrumbNameMap } from '@/admin/routes';

export const getLayoutDrawer = () => {
  return document.querySelector('#' + LayoutDrawerId);
};
export const LayoutHeader = () => {
  return (
    <div className={Styles.LayoutHeader}>
      <div style={{ paddingLeft: 20 }}>
        <Input addonBefore={<SearchOutlined />} />
      </div>
      <div
        style={{
          borderLeft: '1px solid #C2CFD4',
          height: '100%',
          paddingLeft: 16,
          paddingRight: 26,
        }}
        className='flex items-center'
      >
        <UserAvatarInfo />
      </div>
    </div>
  );
};
export const LayoutBreadCrumb = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [itemList, setItemList] = useState<ItemType[]>([]);
  // const pathList = location.pathname.split('/').slice(2);
  useEffect(() => {
    init();
  }, [location.pathname]);
  const init = () => {
    const pathList = location.pathname.split('/').slice(2);
    const _itemList: ItemType[] = [];
    console.log('l', location);
    if (pathList[0] === 'tenant') {
      const state = (location.state as any) || {};
      const tenantInfo: ItemType = {
        title: 'tenant',
        href: '',
      };

      if (state.name) {
        tenantInfo.href = '/admin/tenant';
      }
      _itemList.push(tenantInfo);
      if (state.name) {
        _itemList.push({
          title: state?.name,
          href: '',
        });
      }
      setItemList(_itemList);
    }
    const item0 = pathList[0];
    if (breadcrumbNameMap[item0]) {
      _itemList.push({
        title: breadcrumbNameMap[item0],
        href: '',
      });
      setItemList(_itemList);
      return;
    }
  };

  return (
    <div className={Styles.LayoutBreadCrumb}>
      <Breadcrumb items={itemList} />
    </div>
  );
};
export const Layout = () => {
  const theme = useTheme();
  return (
    <Auth>
      <div className={Styles.Layout}>
        <LayoutMenu />
        <div className={Styles.LayoutMain}>
          <LayoutHeader />
          <div className={Styles.LayoutContent}>
            <LayoutBreadCrumb />
            <div className={Styles.LayoutContentBody}>
              <div style={{ height: '100%', width: '100%', display: 'flex', position: 'relative' }}>
                <div
                  style={{
                    flexGrow: 1,
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      width: '98%',
                      margin: '0 auto',
                      marginTop: '20px',
                      height: '100%',
                    }}
                  >
                    <Outlet />
                  </div>
                </div>
                <div id={LayoutDrawerId} style={{ position: 'absolute', height: '100%', right: 0, zIndex: 11 }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Auth>
  );
};
