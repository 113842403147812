import { Button, ButtonProps } from 'antd';
import cs from 'classnames';
import { MouseEventHandler } from 'react';

export type IconProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

type Props = {
  icon?: JSX.Element;
  scale?: number;
  className?: string;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler;
};
/**
 * wrapper for icon
 * @param props
 * @returns
 */
export const Icon = (props: Props) => {
  const { icon, className, scale, style = {}, ...rest } = props;
  scale && (style.transform = `scale(${scale})`);

  return (
    <div
      className={cs('flex items-center cursor-pointer', className)}
      style={{
        width: 16,
        height: 16,
        ...style,
      }}
      {...rest}
    >
      {icon}
    </div>
  );
};

export const IconButton = (props: ButtonProps) => {
  return <Button type='text' {...props} />;
};
