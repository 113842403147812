import { Tabs } from '@/theme';
import { useDetailStore, DetailStore, RightKey } from '../store';
import { DeviceData, Target } from '../type';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { Protocol } from '../module/protocol/Protocol';
import { Properties } from '../module';
import Styles from './index.module.scss';

type InfoProps = {
  data?: DeviceData;
};
export const Info = (props: InfoProps) => {
  const { data } = props;

  const infoItem = useMemo(() => {
    return (
      <div className='px-3' style={{ fontWeight: 500 }}>
        <div style={{ fontSize: 16 }}>{data?.name}</div>
        <div className='mt-1'>ID: {data?.id}</div>
        <div>IP Address: {data?.ip}</div>
        <div>Type: {data?.type}</div>
        <div>State: {data?.status}</div>
      </div>
    );
  }, [data]);
  const items = [
    {
      key: 'info',
      label: 'Info', //
      children: <>{infoItem}</>,
    },
  ];
  return <Tabs items={items} />;
};

type TargetDetailProps = {
  device?: DeviceData;
  target?: Target;
  rightKey?: RightKey;
  setRightKey?: (key: RightKey) => void;
  [key: string]: any;
};
export const TargetDetail = (props: TargetDetailProps) => {
  const { target, rightKey } = props;
  const items = [
    {
      label: RightKey.Protocol,
      key: RightKey.Protocol,
      children: (
        <div className='px-4' style={{ height: '100%' }}>
          <Protocol data={target} />
        </div>
      ),
    },
    {
      label: RightKey.Properties,
      key: RightKey.Properties,
      children: (
        <div className='px-4 no-scrollbar' style={{ height: '100%', paddingBottom: 15 }}>
          <Properties data={target} />
        </div>
      ),
    },
  ];
  return (
    <Tabs
      className={Styles.rightTabs}
      style={{ height: '100%' }}
      items={items}
      activeKey={rightKey}
      onChange={(activeKey) => {
        props?.setRightKey?.(activeKey as RightKey);
      }}
    />
  );
};
const selector = (state: DetailStore) => {
  return {
    rightKey: state.rightKey,
    isInfo: state.rightKey === 'Info',
    activeTarget: state.activeTarget,
  };
};
export const Right = () => {
  const deviceStore = useDetailStore();
  const { isInfo, rightKey, activeTarget } = useDetailStore(selector, shallow);

  return (
    <div
      className='h-full'
      style={{
        width: 320,
        background: '#F4F7FD',
        borderLeft: '1px solid #c2cfd4',
      }}
    >
      {isInfo && <Info data={deviceStore.device} />}
      {!isInfo && (
        <TargetDetail
          device={deviceStore.device} //
          target={activeTarget}
          rightKey={rightKey}
          setRightKey={deviceStore.setRightKey}
        />
      )}
    </div>
  );
};
