import { menuIcons } from './module/admin-auth';

export const SpecialRoutes = {
  Hawkbit: '/Hawkbit',
};
export type RoutesMenu = {
  path?: string;
  title?: string;
  icon?: React.ReactNode;
  /** whether tab hidden in menus */
  hidden?: boolean;
  /** whether tab is in development. */
  isDev?: boolean;
  /** open by new tab */
  open?: boolean;
  key?: string;
};
export const routesMenu: RoutesMenu[] = [
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    key: 'dashboard',
    icon: menuIcons['Dashboard'],
  },
  {
    path: '/admin/tenant',
    title: 'Tenant',
    key: 'tenant',
    icon: menuIcons['Tenant'],
  },
  {
    path: '/admin/device',
    title: 'Device',
    icon: menuIcons['Device'],
  },
  {
    path: '/admin/target',
    title: 'Target',
    icon: menuIcons['Target'],
  },
  {
    path: '/admin/user',
    title: 'User',
    icon: menuIcons['User'],
  },
  {
    path: SpecialRoutes.Hawkbit,
    title: 'Hawkbit',
    icon: menuIcons['Link'],
    open: true,
  },
  {
    path: '/admin/config',
    title: 'Config File',
    isDev: true,
    hidden: true,
  },
];

export const breadcrumbNameMap: SimpleObject = {
  dashboard: 'dashboard',
  device: 'device',
  target: 'target',
};
