import { createTheme, PaletteMode, ThemeProvider as MuiThemeProvider, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { getDesignTokens } from './design-token';
import muiComponents from './mui-components';

type ModeValue = {
  toggleMode: () => void;
};
const noop = () => {
  //
};
const ColorModeContext = React.createContext<ModeValue>({ toggleMode: noop });
export const useModeStore = () => useContext(ColorModeContext);
export { useTheme };

export const ThemeProvider = (props: { children?: React.ReactElement }) => {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const colorMode = React.useMemo(
    () => ({
      toggleMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={{ ...theme, components: muiComponents(theme) }}>{props.children}</MuiThemeProvider>
    </ColorModeContext.Provider>
  );
};
