import { isArray, isString } from 'lodash';

export const checkUsername = function (username: string) {
  return isString(username) && username.length > 0;
};

export const checkPassword = function (password: string) {
  return isString(password) && password.length >= 5 && password.length <= 64;
};

export const checkEmail = function (email: string) {
  if (!isString(email)) {
    return false;
  }
  const l = email.split('@');
  if (l.length !== 2) {
    return false;
  }
  const [user, host] = l;
  if (user.length < 1) {
    return false;
  }
  if (host.split('.').length < 2) {
    return false;
  }
  if (host.split('.').some((s) => s.length < 1)) {
    return false;
  }
  return true;
};

export const checkCode = function (code: string) {
  return isString(code) && code.length > 0;
};

export const checkSignUpPassword = function (value: string) {
  return isString(value) && value.length > 0;
};

export const checkToken = function (value: string) {
  if (!isString(value)) {
    return false;
  }
  const l = value.split('.');
  if (l.length !== 3) {
    return false;
  }
  const [user, host] = l;
  if (user.length < 1) {
    return false;
  }
  if (host.split('.').length < 2) {
    return false;
  }
  if (host.split('.').some((s) => s.length < 1)) {
    return false;
  }
  return true;
};

export const isArrayNotNone = function (target: any) {
  return isArray(target) && target.length > 0;
};
