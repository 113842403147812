import { ThemeProvider, useTheme } from './theme';
import './assets/fonts/font.css';
import './assets/tailwindcss/tailwind.css';
import './app.css';

import { MainRoutes } from './routes/MainRoutes';

export const App = () => {
  return (
    <ThemeProvider>
      <MainRoutes />
    </ThemeProvider>
  );
};
