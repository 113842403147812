import { useEffect, useState } from 'react';
import { DeviceData, getList, postDevice, postDeviceList, getRealDeviceList, CreateDeviceParam } from './api';
import { Space, App } from 'antd';
import { FormLayout } from '@/components/form';
import { ModalTitle, BasicModal, FormModal, TextSpan, SpaceAction, Select } from '@/theme';
import { ColumnsType } from 'antd/es/table';
import { TenantData } from '../tenant';
import { TargetDraw, useDrawStore, useTenantInfo, useTenantOptions } from '../tenant';
import { shallow } from 'zustand/shallow';
import { DeviceStatus, MsgSingal, GPSSignal } from '@/pages/device';
import { Button, TableAction, Form, InputLogin as Input, usePagination } from '@/theme';
import { Table, TableWrapper } from '@/theme';
import { useRequest } from 'ahooks';
import { useRealDeviceStore } from './store';
import cs from 'classnames';
import { EditModal } from '../target';

type DeviceListModalProps = {
  open: boolean;
  onClose: () => void;
  onOk?: () => void;
};

export const DeviceListModal = (props: DeviceListModalProps) => {
  const { open, onClose, onOk } = props;
  const [data, setData] = useState<DeviceData[]>([]);
  const store = useRealDeviceStore();
  const { run } = useRequest(getRealDeviceList, {
    onSuccess: (res) => {
      if (res.success) {
        const { data = [] } = res;
        setData(
          data?.map((item) => {
            return {
              id: item,
            };
          }),
        );
      }
    },
  });
  const tenant = useTenantInfo();
  const { id } = tenant || {};
  useEffect(() => {
    id && open && run({ tenantId: id });
  }, [open]);
  return (
    <FormModal
      open={open}
      title='Add New'
      onClose={() => {
        onClose();
      }}
      style={{ width: 340, background: '#F9FBFF' }}
      bodyStyle={{ paddingTop: '10px' }}
    >
      <div style={{ height: 180, marginBottom: 10 }}>
        <Table
          rowKey={'id'}
          columns={[
            {
              title: (
                <div style={{ width: '85%', display: 'flex', justifyContent: 'space-between' }}>
                  <div>All</div>
                  <div>{data.length}</div>
                </div>
              ),
              dataIndex: 'id',
            },
          ]}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              store.setSelectedRowKeys?.(selectedRowKeys as string[]);
            },
            selectedRowKeys: store.selectedRowKeys,
          }}
          dataSource={data}
          pagination={false}
          scroll={{ y: 140 }}
        />
      </div>
      <SpaceAction>
        <Button
          type='primary'
          onClick={() => {
            onOk?.();
          }}
        >
          Add
        </Button>
      </SpaceAction>
    </FormModal>
  );
};

type Props = {
  tenant?: TenantData;
  isSingle?: boolean;
};
export const Device = (props: Props) => {
  const { tenant, isSingle } = props;
  const { id: tenantId } = tenant || {};
  const [open, setOpen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [form] = Form.useForm();
  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit] = Form.useForm();
  const { message } = App.useApp();
  const onFormEditFinish = async () => {
    const values = await formEdit.validateFields();
    const res = await postDevice({ ...values });
    if (res.success) {
      message.success('success');
      setOpenEdit(false);
      refresh();
    }
  };
  const store = useDrawStore((state) => {
    return {
      setOpen: state.setOpen,
      setDevice: state.setDevice,
    };
  }, shallow);
  const { tenantOptions } = useTenantOptions(tenant);
  const realDevie = useRealDeviceStore();
  const {
    run,
    params: [param],
    pagination,
    dataSource,
    loading,
    refresh,
  } = usePagination(getList);
  useEffect(() => {
    if (!tenantId && !isSingle) return;
    run({ tenantId });
  }, [tenantId]);

  const onDeviceListOk = () => {
    setOpenList(false);
    onChooseDeviceList();
  };

  const onFinish = async () => {
    const values = await form.validateFields();
    const { device = [] } = values;
    const createDeviceParams: CreateDeviceParam[] = device.map((item): CreateDeviceParam => {
      return {
        deviceName: item.name,
        deviceId: item.id,
      };
    });
    const res = await postDeviceList({ createDeviceParams, tenantId: tenantId! });
    if (res.success) {
      message.success('Add success');
      refresh();
      setOpen(false);
      form.resetFields();
    }
  };
  /**
   * choose real device to form list
   */
  const onChooseDeviceList = () => {
    const { selectedRowKeys } = realDevie;
    const formValue = form.getFieldsValue();
    let { device = [] } = formValue;
    // step1: if no selected device add clear form
    if (!selectedRowKeys?.length) {
      form.setFieldsValue({ tenantId, device: [] }); // clear device
      return;
    }
    // step2: fillter not in device list
    const newDevice = selectedRowKeys.filter((item) => {
      return !device.find((d) => d.id === item);
    });
    // clear device of no in selectedRowKeys
    device = device
      .map((item) => {
        const findDevice = selectedRowKeys.find((d) => d === item.id);
        if (findDevice) return item;
        return false;
      })
      .filter(Boolean);
    // step3: add new device to form list
    newDevice.map((item) => {
      device.push({ id: item, name: item });
    });
    form.setFieldsValue({ tenantId, device });
  };
  const columns: ColumnsType<DeviceData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Tenant',
      dataIndex: 'tenantId',
      width: 250,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
    },
    {
      title: 'IP Address',
      dataIndex: 'ip',
      width: 150,
    },
    {
      title: 'Version',
      dataIndex: 'version',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        return <DeviceStatus text={text} />;
      },
    },
    {
      title: 'LTE',
      dataIndex: 'gsmSignal',
      render: (text) => {
        return <MsgSingal singal={text} />;
      },
    },
    {
      title: 'GPS',
      dataIndex: 'gpsMode',
      render: (text) => {
        return <GPSSignal singal={text} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Space>
              {/* <TableAction
                type='delete'
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Delete
              </TableAction> */}
              <TableAction
                type='edit'
                onClick={(e) => {
                  e.stopPropagation();
                  formEdit.setFieldsValue(record);
                  setOpenEdit(true);
                }}
              >
                Edit
              </TableAction>
              <TableAction
                type='viewer'
                onClick={(e) => {
                  e.stopPropagation();
                  store.setDevice(record);
                  store.setOpen(true);
                }}
              >
                View
              </TableAction>
            </Space>
          </>
        );
      },
    },
  ];
  return (
    <div className={cs(!isSingle && 'h-full overflow-hidden')}>
      <TableWrapper
        search={
          <TableWrapper.QueryForm
            onFinsh={(v) => {
              run?.({ ...param, ...v });
            }}
          />
        }
        tools={
          <>
            {!isSingle && (
              <TableWrapper.AddButton
                onClick={() => {
                  setOpen(true);
                  form.setFieldsValue({
                    tenantId,
                  });
                }}
              />
            )}
          </>
        }
      >
        <Table
          dataSource={dataSource} //
          rowKey={'id'}
          columns={columns}
          loading={loading}
          pagination={pagination}
          onRow={(record) => {
            return {
              onClick: () => {
                store.setDevice(record);
                store.setOpen(true);
              },
            };
          }}
        />
      </TableWrapper>
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          width: 580,
          background: '#F9FBFF',
          paddingLeft: '10px',
        }}
      >
        <ModalTitle>{'Add New Device'}</ModalTitle>
        <Form form={form} {...{ labelCol: { span: 4 } }} onFinish={onFinish}>
          <Form.Item label={'Device'} required>
            <Form.List name='device'>
              {(fields, { add, remove }) => {
                const columns = [
                  { title: 'ID', width: 180 },
                  {
                    title: 'Name',
                    width: 164,
                  },
                  {
                    title: 'Action',
                    width: 46,
                  },
                ];
                return (
                  <div>
                    <Button
                      onClick={() => {
                        // add({ name: '233', id: '233' });
                        setOpenList(true);
                        const { device = [] } = form.getFieldsValue();
                        realDevie.setSelectedRowKeys?.(device.map((item) => item.id));
                      }}
                    >
                      + Add New
                    </Button>
                    <div
                      style={{
                        background: '#F4F7FD',
                        padding: '7px 24px',
                        marginTop: 10,
                      }}
                    >
                      {columns.map((col, index) => {
                        return (
                          <div key={col.title} style={{ width: col.width, display: 'inline-block', marginRight: index !== 0 ? '0' : 10 }}>
                            {col.title}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className='no-scrollbar'
                      style={{
                        height: 170,
                        background: '#fff',
                        marginRight: 24,
                        paddingLeft: 24,
                      }}
                    >
                      {fields.map((field, index) => {
                        const cl = columns.map((item) => item.width);
                        return (
                          <div
                            key={field.key}
                            style={{
                              display: 'flex', //
                              alignItems: 'center',
                              paddingLeft: 24,
                              fontSize: 12,
                              padding: '10px 0',
                              borderBottom: '1px solid #C2CFD4',
                            }}
                          >
                            <Form.Item label={''} name={[field.name, 'id']} noStyle>
                              <TextSpan maxLength={20} maxWidth={cl[0]} style={{ width: cl[0], marginRight: 10 }} />
                            </Form.Item>
                            <Form.Item label={''} name={[field.name, 'name']} noStyle>
                              <Input style={{ width: cl[1] }} />
                            </Form.Item>
                            <div style={{ width: cl[2], marginLeft: 10 }}>
                              <TableAction
                                type='delete'
                                onClick={() => {
                                  remove(field.name);
                                }}
                              >
                                Delete
                              </TableAction>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }}
            </Form.List>
          </Form.Item>
          <Form.Item label={'Tenant'} name={'tenantId'} required>
            <Select options={tenantOptions} style={{ width: '80%' }} disabled />
          </Form.Item>
          <Form.Item label=' ' colon={false}>
            <Space>
              <Button type='primary' htmlType='submit'>
                Confirm
              </Button>
              <Button
                htmlType='reset'
                onClick={() => {
                  setOpen(false);
                  form.resetFields();
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </BasicModal>
      <DeviceListModal
        onOk={onDeviceListOk}
        open={openList}
        onClose={() => {
          setOpenList(false);
        }}
      />
      <BasicModal open={openEdit} onClose={() => setOpenEdit(false)}>
        <ModalTitle>{'Edit Device'}</ModalTitle>
        <Form form={formEdit} {...FormLayout} onFinish={onFormEditFinish}>
          <Form.Item label={'ID'} name={'id'} hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'Name'} name={'name'}>
            <Input />
          </Form.Item>
          <Form.Item label=' ' colon={false}>
            <Space>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              <Button
                onClick={() => {
                  setOpenEdit(false);
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </BasicModal>
      <TargetDraw />
      {isSingle && <EditModal tenant={tenant} />}
    </div>
  );
};
