import { admin } from '@/admin/module';
import { Page } from '@/module';

/**
 * getTenant
 */
export type TenantBody = {
  id?: string;
  name?: string;
  contact?: string;
  logo?: string;
  notes?: string;
};
export type TenantData = {
  createdTime?: string;
  deviceNum?: number;
  targetNum?: number;
  domain?: string;
  updatedTime?: string;
  userNum?: number;
} & TenantBody;

export const getTenant = <T = TenantData>(data: { tenantId: string }) => {
  const { tenantId } = data;
  return admin.get<T>(`/tenant/${tenantId}`);
};
/**
 * postTenantList
 * @param data
 * @returns
 */
export const getTenantList = <T = Page<TenantData>>(data?: any) => {
  return admin.get<T>('/tenant/listTenants', { params: { search: '', ...data } });
};

/**
 * addTenant
 * @param data
 * @returns
 */
export const postTenant = <T = any>(data?: any) => {
  return admin.post<T>('/tenant/createTenant', data);
};

export const putTenant = <T = any>(data?: any) => {
  return admin.post<T>('/tenant/updateTenant', data);
};

/**
 * delete Tenant
 * @param data
 * @returns
 */
export const deleteTenant = <T = any>(data: { tenantId: string }) => {
  return admin.get<T>('/tenant/deleteTenant', { params: data });
};

export type AclRole = {
  id?: string;
  code?: string;
  name?: string;
};
export const getAclRole = <T = AclRole[]>() => {
  return admin.get<T>('/acl/getRoles');
};

export type AclPermission = {
  id?: string;
  code?: string;
  name?: string;
  intro?: string;
};

export const getAclPermissions = <T = AclPermission[]>() => {
  return admin.get<T>('/acl/getPermissions');
};
