import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { loadComponent, useDynamicScript } from '@/components/remote/LoadRemote';
import Skeleton from '@mui/material/Skeleton';
import './index.css';

type FloxgLove3D = {
  unmount: (el: HTMLDivElement) => void;
  Render: (el: HTMLDivElement) => void;
};

type StudioProps = {
  style?: React.CSSProperties;
};

export type FoxgloveBuildProps = {
  type?: string; // 3d, image, plot
  showSetting?: boolean; // 是否显示设置
  sourceId?: string; // sample-nuscenes rosbridge-websocket
  datasourceArgs?: any; // rosbridge-websocket { type: "connection", params: { url: "ws://localhost:9090" }
  config?: any; // panel overflow conifg
  getConfig?: (config: any) => void; // 获取配置
};

/**
 * 这是foxglove-build的远程组件
 * @param props
 * @returns
 */
export const Studio = (props: StudioProps & FoxgloveBuildProps) => {
  const url = '/apps/foxglove-build/remoteEntry.js';
  const ref = useRef<HTMLDivElement | null>(null);
  const moduleRef = useRef<FloxgLove3D | null>(null);
  const { type } = props;
  const scope = 'FoxgloveBuild';
  const { ready, failed } = useDynamicScript({
    url,
    scope,
  });
  const [mount, setMount] = useState(false);
  const init = useCallback(async () => {
    const module = './App';
    const ModuleFun = loadComponent(scope, module);
    const Module = await ModuleFun();
    moduleRef.current = Module;
    const { showSetting, sourceId, datasourceArgs, type, config, ...rest } = props;
    Module.default({ rootEl: ref.current, showSetting, sourceId, datasourceArgs, type, config, ...rest });
    setMount(true);
  }, [props.sourceId, props.datasourceArgs, props.type, props.config]);
  const destroy = async () => {
    // TODO: has been destroyed, 内存是否被释放需要测试
    // moduleRef.current?.unmount?.(ref.current!)
  };
  useEffect(() => {
    if (!mount && ready && !failed) {
      init();
    }
  }, [ready, failed]);
  useLayoutEffect(() => {
    if (mount) {
      // destroy()
      init();
    }
  }, [props.sourceId, props.datasourceArgs, type, props.config]);

  useLayoutEffect(() => {
    return () => {
      destroy();
    };
  }, []);
  return (
    <div
      style={{
        // height: 400,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {!mount && (
        <Skeleton
          sx={{ bgcolor: 'grey.900' }}
          variant='rectangular'
          // width={800}
          // height={400}
          style={{ width: '100%', height: '100%' }}
        />
      )}
      <div ref={ref} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};
/**
 * get http or https by location.href
 */
const getProtocol = () => {
  const { protocol } = window.location;
  if (protocol === 'http:') return 'ws:';
  if (protocol === 'https:') return 'wss:';
};

/**
 * if no url, return sample data sourceId
 * @param url http://20.52.8.160:9995/?ds=rosbridge-websocket&ds.url=ws://20.52.8.160:9998/api/ads-foxglove?eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE2ODEwOTUyNTAsImRldmljZU5vIjoiMDAxLWFnZW50MS0yMDIyMTAxNzEzNDMiLCJleHAiOjE2ODExMDA2NTAsImlhdCI6MTY4MTA5NTI1MCwidXNlcm5hbWUiOiJhZG1pbiJ9.a8N0EPUVQTXJv_kIwHxXbMuvKniQyHWqlB8h-5jbpqs
 * @returns
 */
export const getDataSourceArgsByUrl = (url?: string) => {
  let sourceId = 'sample-nuscenes';
  if (!url) return { sourceId };
  const myUrl = new URL(url);
  const ds = myUrl.searchParams.get('ds');
  let dsUrl = myUrl.searchParams.get('ds.url');
  if (dsUrl && dsUrl.startsWith('ws://')) {
    dsUrl = getProtocol() + dsUrl.slice(5);
  }
  if (ds === 'rosbridge-websocket') {
    sourceId = 'rosbridge-websocket';
    const datasourceArgs = { type: 'connection', params: { url: dsUrl } };
    return { sourceId, datasourceArgs };
  } else if (ds === 'foxglove-websocket') {
    sourceId = 'foxglove-websocket';
    const datasourceArgs = { type: 'connection', params: { url: dsUrl } };
    return { sourceId, datasourceArgs };
  }
  return { sourceId };
};
