// import { Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';

type TextProps = {
  value?: string;
  // max text number
  maxLength?: number;
  maxWidth?: number;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};
export const TextSpan = (props: TextProps) => {
  const { maxLength, maxWidth, className, style, onClick } = props;
  const { value: text } = props;
  const len = text?.length || 0;
  if (maxLength && len > maxLength) {
    const newText = text?.slice(0, maxLength) + '...';
    return (
      <>
        <Tooltip title={text} zIndex={5000}>
          <div
            style={{
              overflow: 'hidden', //
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              ...style,
            }}
          >
            {newText}
          </div>
        </Tooltip>
      </>
    );
  }
  return (
    <div
      className={className}
      style={{
        overflow: 'hidden', //
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...style,
      }}
    >
      {text}
    </div>
  );
};

export const NoWrapText = styled('div')((props) => {
  return {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };
});
