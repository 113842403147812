import { admin } from '@/admin/module';
import { Page, PageParams } from '@/module';

export const postDeviceStatus = (data: { deviceId: string; status: 'ONLINE' | 'OFFLINE' }) => {
  return admin.post('/tenant-device/updateStatus', data);
};
export type DeviceStaus = 'ONLINE' | 'OFFLINE';
export type DeviceData = {
  id?: string;
  name?: string;
  type?: string;
  tenantId?: string;
  ip?: string;
  version?: string;
  protocol?: string;
  lastActiveTime?: string;
  /** is star */
  star?: boolean;
  createTime?: string;
  gpsMode?: number;
  gpsLon?: string | number;
  gpsLat?: string | number;
  gsmSignal?: number;
  status?: DeviceStaus;
};

export const postDevice = <T = DeviceData>(data: DeviceData) => {
  return admin.post<T>('/tenant-device/updateDevice', data);
};

export type CreateDeviceParam = {
  deviceId: string;
  deviceName: string;
};
export type AddDeviceListData = {
  tenantId: string;
  createDeviceParams: CreateDeviceParam[];
};
export const postDeviceList = <T = DeviceData>(data: AddDeviceListData) => {
  return admin.post<T>('/tenant-device/create', data);
};

export const getList = <T = Page<DeviceData>>(data: PageParams<{ tenantId?: string }>) => {
  return admin.get<T>('/tenant-device/list', { params: data });
};

export const getRealDeviceList = <T = any>(data: { tenantId: string }) => {
  return admin.get<T>('/tenant-device/deviceIdList', { params: data });
};
