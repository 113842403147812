type Props = {
  /** status 1 is Online */
  status: 1 | 2;
  noText?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const Status = (props: Props) => {
  const { status = 1, noText = false } = props;
  const text = status === 1 ? 'Online' : 'Offline';
  const statusColor = status === 1 ? '#49AA19' : '#D32029';
  return (
    <div style={{ display: 'flex' }} className={props?.className}>
      <div
        style={{
          width: 6,
          height: 6,
          borderRadius: 6,
          background: statusColor,
          marginRight: 4,
          alignSelf: 'center',
          display: 'flex',
        }}
      ></div>
      <div style={{ color: statusColor, display: noText ? 'none' : 'inherit' }}>{text}</div>
    </div>
  );
};

export const StatusColor = [
  {
    text: 'IDLE',
    color: '#66D7EB',
  },
  {
    text: 'Error',
    color: '#D32029',
  },
  {
    text: 'Active',
    color: '#49AA19',
    value: 'connected',
  },
  {
    text: 'OFFLINE',
    color: '#808080',
    value: 'disconnected',
  },
];
type DeviceStatusProps = {
  text?: string;
  className?: string;
  style?: React.CSSProperties;
  noText?: boolean;
};
export const DeviceStatus = (props: DeviceStatusProps) => {
  const { noText = false } = props;
  const text = props.text;
  const _Status = StatusColor.find((item) => item.value === text) || { text: 'OFFLINE', color: '#808080' };
  const statusColor = _Status.color;
  return (
    <div style={{ display: 'flex' }} className={props?.className}>
      <div
        style={{
          width: 6,
          height: 6,
          borderRadius: 6,
          background: statusColor,
          marginRight: 4,
          alignSelf: 'center',
          display: 'flex',
        }}
      ></div>
      <div style={{ color: statusColor, display: noText ? 'none' : 'inherit' }}>{_Status.text}</div>
    </div>
  );
};
