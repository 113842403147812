import { GPSIcon } from '../icon';
type Props = {
  singal?: number;
};
/*
信号备注：
格数    信号强度  (dBm) <br>
5格 -51 dBm 或以上 <br>
4格 -70 dBm 至 -51 dBm <br>
3格 -85 dBm 至 -71 dBm <br>
2格 -100 dBm 至 -86 dBm <br>
1格 -109 dBm 至 -101 dBm <br>
*/
const getLevel = (singal: number | undefined) => {
  if (!singal) return 0;
  if (singal >= -51) {
    return 5;
  } else if (singal >= -70) {
    return 4;
  } else if (singal >= -85) {
    return 3;
  } else if (singal >= -100) {
    return 2;
  } else {
    return 1;
  }
};
/**
 *
 * @param props
 * @returns
 */
export const MsgSingal = (props: Props) => {
  const { singal } = props;
  const level = getLevel(singal);
  if (level === 0) return <div></div>;
  return <GPSIcon level={level} />;
};

export const getGPS = (singal: number | undefined) => {
  if (singal === 0) {
    return 'Unknown';
  } else if (singal === 1) {
    return 'No Fix';
  } else if (singal === 2) {
    return '2D';
  } else if (singal === 3) {
    return '3D';
  }
  return 'Unknowd';
};
export const GPSSignal = (props: Props) => {
  const { singal } = props;
  // 0 1 2 3
  return (
    <span
      style={{
        fontSize: 10,
        fontWeight: 300,
      }}
    >
      {getGPS(singal)}
    </span>
  );
};
