import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useRef, useState } from 'react';
import { Chart, LineElement, PointElement, LineController, CategoryScale, LinearScale, Legend } from 'chart.js';
import { max as maxFn, min as minFn } from 'lodash-es';
// https://www.chartjs.org/docs/latest/getting-started/integration.html
// register by need

Chart.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale, // must
  Legend,
  annotationPlugin,
);

type Props = {
  data?: number[];
  upperThreshold: number;
  lowerThreshold: number;
  labels?: number[];
  title?: string;
  yLabel?: string;
  xLabel?: string;
  dev?: boolean;
};
export const TemperatureChart = (props: Props) => {
  const { data = [], labels = [], upperThreshold = 35, lowerThreshold = 20 } = props;
  const { title = 'Temperature', yLabel = 'T [℃]', xLabel = 'Time [Seconds]' } = props;

  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | null>(null);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(upperThreshold + 10);
  const [mount, setMount] = useState(false);
  const dataRef = useRef<{ upper: number; lower: number }>({
    upper: upperThreshold,
    lower: lowerThreshold,
  });
  dataRef.current.upper = upperThreshold;
  dataRef.current.lower = lowerThreshold;

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.labels = labels;
      chartRef.current.data.datasets[0].data = data;
      // set chartRef title
      // chartRef.current.options.plugins!.title!.text = title;
      chartRef.current.update();
      const { lowerThreshold, upperThreshold } = props;
      let lowest = 0;
      if (title === 'Temperature') {
        lowest = lowerThreshold - 10;
      }
      const _min = minFn([...data, lowest]);
      const _max = maxFn([...data, upperThreshold + 10]);
      if (_min && _min < min) setMin(_min);
      if (_max && _max > max) setMax(_max);
    }
  }, [props]);
  useEffect(() => {
    if (chartRef.current && chartRef.current.options.scales && chartRef.current.options.scales.y) {
      chartRef.current.options.scales.y.min = min;
      chartRef.current.options.scales.y.max = max;
      chartRef.current.update();
    }
  }, [min, max]);
  useEffect(() => {
    let min = lowerThreshold - 10;
    if (title === 'Temperature') {
      setMin(min);
    } else {
      if (min < 0) {
        min = 0;
        setMin(0);
      }
    }
    const elRef = ref.current;
    const _chart = new Chart(elRef!, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            // label: 'Temperature',
            label: title,
            data: data,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              // text: ['Time [Seconds]'],
              text: [xLabel],
              padding: { top: 10 },
              align: 'end',
            },
          },
          y: {
            min: min, //for temperature range
            max: max, //for temperature range
            // min: 20,
            // max: 40,
            title: {
              display: true,
              // text: 'T [℃]',
              text: yLabel,
              padding: { top: 0, bottom: 10 },
              align: 'end',
            },
          },
        },
        plugins: {
          legend: {
            position: 'top',
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                value: () => {
                  return dataRef.current.upper;
                },
                borderColor: '#000',
                scaleID: 'y',
                borderWidth: 2,
                label: {
                  content: 'Upper Threshold',
                  position: 'end',
                  display: true,
                  xAdjust: -20,
                  yAdjust: -12,
                  font: {
                    size: 10,
                  },
                },
              },
              line2: {
                type: 'line',
                value: () => dataRef.current.lower,
                scaleID: 'y',
                borderColor: '#000',
                borderWidth: 2,
                label: {
                  content: 'Lower Threshold',
                  position: 'end',
                  display: true,
                  xAdjust: -20,
                  yAdjust: 12,
                  font: {
                    size: 10,
                  },
                },
              },
            },
          },
        },
      },
    });
    chartRef.current = _chart;
    return () => {
      _chart.destroy();
    };
  }, []);
  return <canvas ref={ref} width={'100%'} height={250} />;
};

export default TemperatureChart;
