import styled from '@emotion/styled';
import { Input as AntInput, InputProps } from 'antd';
import { Theme } from '@mui/material';

export const Input = styled(AntInput)((props) => {
  const theme = props.theme as Theme;
  // antd input maybe wrpper by span
  // type list
  // - affix-wrpper
  let isWrapper = false;
  const { prefix, suffix } = props;
  if (props.allowClear || prefix || suffix) {
    isWrapper = true;
  }

  const b = isWrapper ? ' ' : '';
  return {
    borderRadius: '2px',
    outline: 'none',
    border: '1px solid #C2CFD4',
    background: '#F9FBFF' + ' !important',

    [`&${b}.ant-input`]: {
      backgroundColor: '#F9FBFF' + ' !important',
      fontWeight: 400,
    },
    [`&:hover, &:focus`]: {
      border: '1px solid #57B7C8',
      borderColor: '#57B7C8' + ' !important',
      boxShadow: '0px 0px 2px #57B7C8',
    },
    /** remove autofill select color */
    [`&${b}.ant-input:-internal-autofill-selected, &${b}.ant-input:-internal-autofill-previewed`]: {
      // '-webkit-text-fill-color': '#172F41 !important',
      WebkitTextFillColor: '#172F41 !important',
      fontSize: 14,
      fontFamily: 'D-DIN',
      fontWeight: 400,
      transition: 'background-color 5000s ease-in-out 0s !important',
    },
    [`&${b}.ant-input:-internal-autofill-previewed`]: {
      fontWeight: '500 !important',
    },
    /** add margin inline end */
    [`&${b}.ant-input-prefix`]: {
      marginInlineEnd: '15px',
    },
    [`&${b}.ant-input::placeholder`]: {
      color: '#6D848F',
      fontFamily: 'D-DIN',
      fontSize: 14,
      fontWeight: 400,
    },
  };
});

export const TextArea = styled(AntInput.TextArea)((props) => {
  let isWrapper = false;
  const { prefix } = props;
  if (props.allowClear || prefix) {
    isWrapper = true;
  }

  const b = isWrapper ? ' ' : '';
  return {
    borderRadius: '2px',
    outline: 'none',
    border: '1px solid #C2CFD4',
    background: '#F9FBFF' + ' !important',
    [`&${b}.ant-input`]: {
      backgroundColor: '#F9FBFF' + ' !important',
      fontWeight: 400,
    },
    [`&:hover, &:focus`]: {
      border: '1px solid #57B7C8',
      borderColor: '#57B7C8' + ' !important',
      boxShadow: '0px 0px 2px #57B7C8',
    },
    /** add margin inline end */
    [`&${b}.ant-input-prefix`]: {
      marginInlineEnd: '15px',
    },
    [`&${b}.ant-input::placeholder`]: {
      color: '#6D848F',
      fontFamily: 'D-DIN',
      fontSize: 14,
      fontWeight: 400,
    },
  };
});
