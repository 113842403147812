import { admin } from '@/admin/module';
import { Page } from '@/module';
export type TenantUserData = {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  lastActive?: string;
  tenantId: string;
  roleId?: number;
  permissionId?: number[];
};
export const postTenantUser = (data: any) => {
  return admin.post('/tenant-user/add', data);
};

export const getTenantUserList = <T = Page<TenantUserData>>(data: { tenantId?: string }) => {
  return admin.get<T>('/tenant-user/list', { params: data });
};

export const deleteTenantUser = (data: { tenantUserId: string }) => {
  return admin.post('/tenant-user/delete', data);
};

export type UserPermission = {
  id: number;
  code: 'string';
  name: 'string';
  intro: 'string';
};
export const getAclPermissions = <T = UserPermission[]>(data?: { tenantId: string }) => {
  return admin.get<T>('/acl/getPermissions');
};

export type UserRole = {
  id: number;
  code: 'string';
  name: 'string';
};

export const getAclRoles = <T = UserRole[]>(data?: { tenantId: string }) => {
  return admin.get<T>('/acl/getRoles', { params: data });
};
