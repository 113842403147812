import { create } from 'zustand';

type Store = {
  viewIsSingle: boolean;
  setViewIsSingle: (viewIsSingle: boolean) => void;
};
export const useTopToolbarStore = create<Store>((set, get) => ({
  viewIsSingle: false,
  setViewIsSingle: (viewIsSingle: boolean) => set({ viewIsSingle }),
}));
