import { message } from 'antd';
import { Options, createAppRequest } from '../../module/request';
import { CreateAxiosDefaults } from 'axios';

const tokenName = 'admin-token';
const requestIntercept = {
  onSuccess: (config: any) => {
    const headers = config.headers || {};
    const token = localStorage.getItem(tokenName);
    token && (headers['Authorization'] = token);
    if (DEV_SERVER) {
      console.groupCollapsed('request url:', config.url);
      console.log('request body', config);
      console.groupEnd();
    }
    return config;
  },
};
const responseIntercept = {
  onSuccess: (response: any) => {
    const { data = {} } = response;
    if (data?.code === 403) {
      message.error('token expired, please login again');
      setTimeout(() => {
        location.href = '/admin/login';
      }, 400);
      return { list: [] };
    } else if (data?.code === 401) {
      console.error('no permission');
      setTimeout(() => {
        location.href = '/admin/login';
      }, 400);
      return {};
    }
    data.msg = data?.message || '';
    return data;
  },
  onError: (error: any) => {
    console.log('error', error);
    return {
      isSuccess: false,
    };
  },
};
const defaultConfig: CreateAxiosDefaults = {
  baseURL: '/api/c2cb-admin-biz/',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
};

let admin = createAppRequest({ config: defaultConfig, request: requestIntercept, response: responseIntercept });
const reCreateAdmin = (options?: Options) => {
  admin = createAppRequest({ config: defaultConfig, request: requestIntercept, response: responseIntercept, ...options });
};
export const saveToken = (token: string) => {
  localStorage.setItem(tokenName, token);
  console.log('token', token);
};
export const getToken = () => {
  return localStorage.getItem(tokenName);
};
export { admin, reCreateAdmin };
