import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useStore, UserStore } from '@/store';

import { useNavigate } from 'react-router-dom';
import { Avatar as AvatarIcon, Drop as DropIcon } from '@nisar/icons';

type Props = {
  showName?: boolean;
  logoutRedirect?: string;
  useStore: () => UserStore;
};
export const UserAvatar = (props: Props) => {
  const { showName = false, logoutRedirect = '/user/login', useStore } = props;
  // const store = useStore();
  const store = useStore();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const clickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const toResetPassword = () => {
    //
  };
  const logout = () => {
    store.logout?.();
    navigate(logoutRedirect);
  };
  return (
    <>
      <div className='flex items-center cursor-pointer' onClick={clickOpen}>
        <AvatarIcon className='mr-2 h-full w-6' />
        {showName && <div className='mr-2'>{store?.user?.username}</div>}
        <DropIcon className='w-2' />
      </div>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* <MenuItem onClick={toResetPassword}>Reset Password</MenuItem> */}
        <MenuItem onClick={logout}>Log out</MenuItem>
      </Menu>
    </>
  );
};

export const UserAvatarInfo = () => {
  return <UserAvatar useStore={useStore} />;
};
