import { DeviceData } from '@/admin/type';
import { create } from 'zustand';

type Store = {
  open: boolean;
  setOpen: (open: boolean) => void;
  device?: DeviceData;
  setDevice: (device: DeviceData) => void;
  refreshCount: number;
  refresh: () => void;
  clearRefresh: () => void;
};
export const useDrawStore = create<Store>((set) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
  device: undefined,
  setDevice: (device: DeviceData) => set({ device }),
  refreshCount: 0,
  refresh: () => set((state) => ({ refreshCount: state.refreshCount + 1 })),
  clearRefresh: () => set({ refreshCount: 0 }),
}));
