import { useDetailStore } from '../store';
import { SearchOutlined } from '@ant-design/icons';
import { Target } from '../type';
import { Plus, Radar, EyeVisible, ToTop, EyeInvisible } from '@nisar/icons';
import styled from '@emotion/styled';
import { Icon, Input } from '@/theme';
import Styles from './index.module.scss';

import cs from 'classnames';
import { useEffect, useState } from 'react';
import { useTargetEditStore } from '../module/edit-modal/store';
const TargetItem = styled('div')((props) => {
  return {
    cursor: 'pointer',
    '&:hover': {
      background: '#7dddee' + ' !important',
    },
    '&:hover .targetStar': {
      display: 'block !important',
    },
  };
});
export const Left = () => {
  const targets = useDetailStore((state) => state.targets);

  const detailStore = useDetailStore();
  const targetStore = useTargetEditStore();
  const leftKey = useDetailStore((state) => state.leftKey);
  const onClickTarges = (item: Target) => {
    detailStore.onActiveTarget?.(item);
  };
  const padding = '8px 20px 8px 20px';
  const [value, setValue] = useState('');
  const onSearch = (e: any) => {
    setValue(e.target.value);
  };
  const [showTargets, setShowTargets] = useState<Target[]>([]);
  useEffect(() => {
    if (!value) {
      setShowTargets(targets || []);
      return;
    }
    const newTargets = targets?.filter?.((item) => {
      return item.name?.includes?.(value);
    });
    setShowTargets(newTargets || []);
  }, [value, targets]);
  return (
    <div
      className='h-full flex flex-col'
      style={{
        width: 208,
        background: '#F4F7FD',
        borderRight: '1px solid #c2cfd4',
        color: '#172F41',
        fontSize: 12,
      }}
    >
      <div
        style={{
          padding,
        }}
      >
        <Input placeholder='Search' value={value} onChange={onSearch} prefix={<SearchOutlined />} style={{ marginTop: 10 }} />
      </div>
      <div className='flex justify-between' style={{ padding }}>
        <div>Target</div>
        <div className='mr-1'>
          <Icon
            icon={<Plus />}
            onClick={() => {
              const device = detailStore.device;
              const deviceId = device?.id;
              if (!deviceId) return;
              targetStore.setOpen?.(true);
              targetStore.setDeviceId(deviceId);
              device && targetStore.setTenantId(device.tenantId!);
              targetStore.setOpen(true);
            }}
          ></Icon>
        </div>
      </div>
      <div className='flex-grow'>
        <div className='no-scrollbar h-full' style={{ fontWeight: 500 }}>
          {showTargets.map((item, index) => {
            const isActive = item.id === leftKey;
            const onVisibleClick = (e: any) => {
              e.stopPropagation();
              detailStore.setTargetVisible?.(item);
            };
            return (
              <TargetItem
                className={cs('flex items-center justify-between', { [Styles.noShowTarget]: !!item.noShow })}
                key={index}
                style={{
                  background: isActive ? '#7dddee' : 'transparent',
                  padding: '0 16px',
                  height: 32,
                }}
                onClick={() => {
                  onClickTarges(item);
                }}
              >
                <div className='flex flex-grow mr-2' style={{ overflow: 'hidden' }}>
                  <div className='flex items-center' style={{ width: '100%' }}>
                    <Radar className='w-4 h-4 mr-2' />
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.name}</div>
                  </div>
                </div>

                <div className='targetStar' style={{ display: 'none' }}>
                  <div className='flex items-center'>
                    <Icon
                      icon={<ToTop />}
                      onClick={(e) => {
                        e.stopPropagation();
                        detailStore.setTargetToTop?.(item);
                      }}
                    />
                    {!!item.noShow ? <Icon icon={<EyeVisible />} onClick={onVisibleClick} /> : <Icon icon={<EyeInvisible />} onClick={onVisibleClick} />}
                  </div>
                </div>
              </TargetItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};
