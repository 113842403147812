export const PatternType = {
  email: 'email',
};
export const Pattern = {
  email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
};

export const PatternMsg = {
  email: 'Please input a valid email address!',
};
