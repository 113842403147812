import * as d3 from 'd3';
import { uniq, random } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { create } from 'zustand';
type ChartProps = {
  data: any;
  yTricks?: 5 | 10;
};
const runChart = (props: ChartProps) => {
  const { data } = props;
  // Declare the chart dimensions and margins.
  const width = 640;
  const height = 400;
  const marginTop = 20;
  const marginRight = 20;
  const marginBottom = 30;
  const marginLeft = 40;

  // Declare the x (horizontal position) scale.

  const x = d3
    .scaleLinear()
    .domain([0, 10])
    .range([marginLeft, width - marginRight]);
  const xUnit = (width - marginLeft - marginRight) / 10;
  // Declare the y (vertical position) scale.
  const yTricks = props?.yTricks ?? 10;
  const y = d3
    .scaleLinear()
    .domain([0, 10])
    .range([height - marginBottom, marginTop]);
  const yUnit = (height - marginTop - marginBottom) / yTricks;
  // Create the SVG container.
  const svg = d3
    .create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('style', 'width:100%;height:100%')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('preserveAspectRatio', 'xMidYMid meet');

  // Add the x-axis.
  svg
    .append('g')
    .attr('transform', `translate(0,${height - marginBottom})`)
    .call(
      d3
        .axisBottom(x)
        .ticks(10)
        .tickFormat((v) => v.toString()),
    );

  // Add the y-axis.
  svg
    .append('g')
    .attr('transform', `translate(${marginLeft - 1},0)`)
    .call(
      d3
        .axisLeft(y)
        .ticks(yTricks)
        .tickFormat((v) => v.toString()),
    );
  const showData = [
    { x: 0, y: 0, color: 'red' },
    { x: 1, y: 0, color: 'yellow' },
    { x: 2, y: 0, color: 'green' },
    { x: 2, y: 2, color: 'green' },
  ];
  const transformData = data.map((item) => {
    return {
      x: item.x * xUnit + marginLeft,
      y: (yTricks - 1 - item.y) * yUnit + marginTop,
      color: item.color,
    };
  });
  // 创建星星
  svg
    .selectAll('rect')
    .data([
      ...transformData,
      // 添加其他星星的数据
    ])
    .enter()
    .append('rect')
    .attr('x', (d) => d.x) // 星星位置以为单位
    .attr('y', (d) => d.y)
    .attr('width', xUnit + 1) // 星星大小
    .attr('height', yUnit)
    .attr('fill', (d) => d.color); // 星星颜色
  // Return the SVG element.
  return svg.node();
};
const brightColors = [
  '#FF0000', // 红色
  '#FFA500', // 橙色
  '#FFFF00', // 黄色
  '#008000', // 绿色
  '#0000FF', // 蓝色
  '#800080', // 紫色
  '#FFC0CB', // 粉红色
  '#00FFFF', // 青色
  '#FFD700', // 金色
  '#C0C0C0', // 银色
];

type DataSets = {
  data: boolean[];
  key: string;
  color: string;
};
type Store = {
  data: DataSets[];
  setData: (data: DataSets[]) => void;
  getData: () => DataSets[];
  color: string[];
  setColor: (color: string[]) => void;
  [key: string]: any;
};

type Opts = {
  isDemo?: boolean;
  rangeMax?: 5 | 10;
};
const useDynimicData = (opts?: Opts) => {
  const [dataset, setDataSets] = useState<any[]>([]);
  const [len, setLen] = useState(0);
  const useDynimicStore = create<Store>((set, get) => {
    return {
      data: [],
      getData: () => {
        return get().data;
      },
      setData: (data) => {
        set({ data });
      },
      color: brightColors,
      setColor: (color) => {
        set({ color });
      },
      getColor: () => {
        return get().color;
      },
    };
  });
  const store = useDynimicStore();
  const { data, setData, getData } = store;
  const { isDemo = false, rangeMax = 5 } = opts || {};
  useEffect(() => {
    if (!isDemo) return;
    const t = setInterval(function () {
      runDemo();
    }, 1000);
    return () => {
      clearInterval(t);
    };
  }, []);
  const pushList = (list: string[]) => {
    const data = store.getData();
    const colorList = store.getColor();
    const keys = uniq(list);
    let notHasKeys = [...keys];
    let _data = [...data];
    const _colorList = [...colorList];
    _data = _data.map((item) => {
      if (keys.includes(item.key)) {
        notHasKeys = notHasKeys.filter((key) => key !== item.key);
        item.data.unshift(true);
      } else {
        item.data.unshift(false);
      }
      if (item.data.length > 10) {
        item.data.pop();
      }
      return item;
    });
    if (notHasKeys.length > 0) {
      notHasKeys.forEach((key) => {
        const color = _colorList.shift();
        if (!color) {
          console.error('not more');
        }
        _data.push({
          key,
          data: [true],
          color: color || 'red',
        });
      });
    }
    // clear data
    _data = _data.filter((item) => {
      const { data } = item;
      const has = data.some((d) => d);
      if (!has) _colorList.unshift(item.color);
      return has;
    });
    setData(_data);

    store.setColor(_colorList);
    transformData(_data);
  };
  const transformData = (data: DataSets[]) => {
    const newData: any[] = [];
    data.forEach((item, y) => {
      const { data, color } = item;
      data.forEach((d, x, index) => {
        newData.push({
          x: x,
          y: y, // 不变
          color: d ? color : 'white',
        });
      });
    });
    setDataSets(newData);
    setLen(data.length);
  };
  const runDemo = () => {
    const randKey = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
    const t = random(1, 4);
    const pushKey: string[] = [];
    for (let i = 0; i < t; i++) {
      pushKey.push(randKey[random(0, rangeMax - 1)]);
    }
    // console.log('pushKey', pushKey);
    pushList(pushKey);
  };

  return {
    len,
    store,
    dataset,
  };
};
type D3BarProps = {
  dynamic?: {
    isDemo?: boolean;
    rangeMax?: 5 | 10;
  };
  id?: string;
};
export const D3Bar = (props: D3BarProps) => {
  const { id = 'd3-arb' } = props || {};
  const { dataset, len, store } = useDynimicData(props?.dynamic);
  useEffect(() => {
    init();
  }, [dataset]);
  const init = () => {
    const y = len > 5 ? 10 : 5;
    const n = runChart({ data: dataset, yTricks: y });
    const d3Bar = document.querySelector(`#${id}`);
    if (!d3Bar) return;
    d3Bar.innerHTML = '';
    d3Bar.appendChild(n!);
  };
  return <div id={id}>D3</div>;
};
