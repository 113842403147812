import { Breadcrumb as AntBreadcrumb } from 'antd';
import styled from '@emotion/styled';

export const Breadcrumb = styled(AntBreadcrumb)((props) => {
  return {
    color: '#405768',
    '& a': {
      color: 'unset' + ' !important',
      textTransform: 'capitalize',
    },
    // '& a[href=""]': {
    //   color: 'red',
    // },
    '& li:nth-of-type(3)': {
      color: '#4797A5' + ' !important',
    },
  };
});
