type TitleProps = {
  className?: string;
  text?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};
export const Text = (props: TitleProps) => {
  const { className, style } = props;
  return (
    <div className={className} style={style}>
      {props?.text ? props.text : props.children}
    </div>
  );
};
