import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { Table as AntTable, TableProps } from 'antd';

type BaseTableProps<T = any> = {
  /** show header divider, default is false */
  showHeaderDivider?: boolean;
  /** show table td,tr borderBottom, defualt is false */
  showTableBorder?: boolean;
  styled?: SimpleObject;
} & TableProps<T>;

/**
 * @showHeaderDivider
 * @param props
 * @returns
 */
const BaseTable = (props: BaseTableProps) => {
  const { showHeaderDivider, showTableBorder, styled, pagination, ...antTableProps } = props;
  return <AntTable {...antTableProps} pagination={pagination} />;
};
export const Table = styled(BaseTable)((props) => {
  const theme = props.theme as Theme;
  const h6 = (theme.typography as any)?.body1;
  const { showHeaderDivider = false, showTableBorder = true, styled: pStyled } = props;
  let styled = {};
  if (!showHeaderDivider) {
    styled = {
      '& .ant-table-thead>tr>th::before': {
        display: 'none',
      },
    };
  }

  return {
    '& .ant-table': {
      fontWeight: 500,
    },
    '& .ant-table-thead > tr > th': {
      backgroundColor: theme.palette.background.paper,
      color: '#6D848F',
      fontSize: 12,
      padding: '5px 10px',
    },
    '& .ant-table-tbody > tr > td': {
      padding: '5px 10px',
      color: '#172F41',
    },
    '& .ant-table-cell-fix-right': {
      backgroundColor: 'unset' + ' !important',
    },
    '& .ant-table-tbody >tr.ant-table-row:hover>td': {
      backgroundColor: '#D8E0E8',
    },
    '& .ant-table-tbody>tr>td, .ant-table-thead>tr>th': {
      borderBottom: showTableBorder ? '1px solid #C2CFD4' : 'none',
    },
    '& .ant-table-body::-webkit-scrollbar': {
      display: 'none',
    },
    ...styled,
    ...pStyled,
  };
});
export const TableColumn = AntTable.Column;
/**
 *
'& .ant-table ': {
    background: 'unset' + ' !important',
  },
  '& .ant-table-thead>tr>th ': {
    background: 'unset' + ' !important',
  },
  '& .ant-table-placeholder': {
    background: 'unset' + ' !important',
  },
  '& .ant-spin-dot-item': {
    backgroundColor: '#D8E0E8' + ' !important',
  },
}
 */
