import { Text } from '@/components/text';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const ModalTitle = styled(Text)((props) => {
  const theme = props.theme as Theme;
  const h6 = (theme.typography as any)?.body1;
  return {
    color: theme?.palette?.secondary.main,
    padding: '20px',
    textAlign: 'center',
    ...h6,
    fontWeight: theme.typography.fontWeightMedium,
  };
});

export const LayoutTitle = styled(Text)((props) => {
  const theme = props.theme as Theme;
  return {
    color: '#172F41',
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    fontWeight: theme.typography.fontWeightBold,
  };
});

export const BodyTitle = styled(Text)((props) => {
  const theme = props.theme as Theme;
  return {
    color: '#172F41',
    fontSize: '1.14rem',
    fontFamily: 'Montserrat',
    fontWeight: 600,
  };
});

/**
 * Login title
 */
export const LoginTitle = styled(Text)((props) => {
  // const theme = props.theme as Theme;
  return {
    color: '#172F41',
    fontSize: '32px',
    fontWeight: 700,
    fontFamily: 'Orbitron',
  };
});

export * from './Text';
export * from './Label';
