import Lottie, { LottieComponentProps } from 'lottie-react';
import loading from '@/assets/lottie/loading.json';

const Loading = function (props: Omit<LottieComponentProps, 'animationData'>) {
  return <Lottie className='h-4' animationData={loading} loop={true} {...props} />;
};

export const LoadingFull = function (props: Omit<LottieComponentProps, 'animationData'>) {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <Loading {...props} />
    </div>
  );
};
type LoadingDataProps = {
  loading?: boolean;
  children: React.ReactNode;
  lottie?: React.ReactNode;
};
/**
 * for finish code to wrapper way to loading data
 * @param props
 * @returns
 */
export const LoadingData = (props: LoadingDataProps) => {
  const { loading, lottie } = props;
  if (loading) {
    if (lottie) {
      return <>{lottie}</>;
    }
    return <Loading />;
  } else {
    return <>{props.children}</>;
  }
};
export { Loading };
