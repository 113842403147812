import React, { Suspense, useEffect } from 'react';
import { Badge, Checkbox } from 'antd';
import { Loading, Tabs } from '@/theme';
import styled from '@emotion/styled';
import { DoubleUp } from '@nisar/icons';

import { useDetailStore, BottomKey } from '../store';
import { useMemo } from 'react';
import { DeviceMap } from '@/pages/device/home/content/DeviceMap';
import { Console, useConsoleStore } from '../module/console';
import { shallow } from 'zustand/shallow';
import { getCenterByDevice } from '@/pages/device/store';
import { add } from 'lodash-es';
import { DataViewer as EasyViewer } from '../module/easy-viewer';
import { DataViewer } from '../module/data-viewer/DataViewer';
const Container = React.lazy(() => import('../flow'));

export const WrapContent = styled('div')((props) => {
  return {
    position: 'relative',
    padding: '8px 16px 8px 16px',
    background: '#F9FBFF',
  };
});
export const TopTool = () => {
  const store = useDetailStore();
  const { showOnline, showOffline } = useDetailStore((state) => {
    return {
      showOnline: state.showOnline,
      showOffline: state.showOffline,
    };
  }, shallow);
  return (
    <div
      style={{
        position: 'absolute', //
        display: 'flex',
        justifySelf: 'center',
        top: '10px',
        right: 24,
      }}
    >
      <div className='flex whitespace-nowrap items-center'>
        <Checkbox
          checked={showOnline}
          onChange={(e) => {
            store.setShowOnline?.(!showOnline);
          }}
        />{' '}
        <div className='ml-2'>Online Targets</div>
      </div>
      <div className='ml-3 flex whitespace-nowrap items-center'>
        <Checkbox
          checked={showOffline}
          onChange={(e) => {
            store.setShowOffline?.(!showOffline);
          }}
        />{' '}
        <div className='ml-2'>Offline Targets</div>
      </div>
    </div>
  );
};

export const Bottom = () => {
  const { bottomKey, device, openBottom } = useDetailStore();
  const store = useDetailStore();
  const consoleStore = useConsoleStore();
  useEffect(() => {
    if (device?.id) consoleStore.init(device?.id);
    return () => {
      consoleStore.destory?.();
    };
  }, [device?.id]);
  useEffect(() => {
    if (!openBottom && consoleStore.msgCount > 0) {
      onChangeKey('Console');
    }
  }, [consoleStore.msgCount]);
  const showBottom = () => {
    if (!openBottom) {
      store.setOpenBottom?.(true);
    }
  };
  const items = [
    {
      key: 'Console',
      label: (
        <div onClick={showBottom}>
          <Badge count={consoleStore.unreadMsgCount} size='small'>
            Console
          </Badge>
        </div>
      ), //
    },
    {
      key: 'Data viewer',
      label: 'Data viewer',
    },
    {
      key: 'Easy viewer',
      label: 'Easy viewer',
    },
    {
      key: 'Location',
      label: 'Location',
    },
  ];
  const content = useMemo(() => {
    if (!device) return <Loading />;
    switch (bottomKey) {
      case 'Console':
        return (
          <div style={{ height: 300 }}>
            <Console />
          </div>
        );
      case 'Data viewer':
        return (
          <div style={{ height: 300 }}>
            <DataViewer />
          </div>
        );
      case 'Easy viewer':
        return (
          <div style={{ height: 300 }}>
            <EasyViewer />
          </div>
        );
      case 'Location':
        const center = getCenterByDevice(device);
        if (center) {
          // for offest, because the map center, and device card is not show full
          center[1] = add(center[1], 0.0005);
          center[0] = add(center[0], 0.0005);
        }
        return (
          <div style={{ height: 300 }}>
            <DeviceMap dataSource={[device]} center={center} />
          </div>
        );
      default:
        return <div>no Data</div>;
    }
  }, [bottomKey, device]);
  const onChangeKey = (activeKey: BottomKey) => {
    store.setBottomKey?.(activeKey);
    if (activeKey === 'Console') {
      consoleStore.clearMsg();
    } else {
      consoleStore.recordUnredMsg();
    }
    if (!openBottom) {
      store.setOpenBottom?.(true);
    }
  };
  return (
    <div
      style={{
        position: 'absolute',
        width: '90%', //
        display: 'flex',
        justifySelf: 'center',
        bottom: '0px',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          background: '#D8E0E8',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '80%',
          }}
        >
          <Tabs
            items={items}
            activeKey={bottomKey}
            onChange={(activeKey) => {
              onChangeKey(activeKey as BottomKey);
            }}
            showBottomBorder={false}
            showBottomMargin={false}
            tabNavWrapHeight={true}
            sx={{
              '& .ant-tabs-tab+.ant-tabs-tab': {
                margin: '0px 0px 0px 16px',
              },
            }}
          />
        </div>
        <div
          className='h-5 w-5 mr-8'
          style={{ transform: openBottom ? 'rotate(180deg)' : 'unset' }}
          onClick={() => {
            store.setOpenBottom?.(!openBottom);
          }}
        >
          <DoubleUp />
        </div>
      </div>

      {openBottom && <WrapContent>{content}</WrapContent>}
      {!openBottom && <div className='h-4' style={{ background: '#f9fbff' }}></div>}
    </div>
  );
};
export const Center = () => {
  return (
    <div className='flex-grow h-full'>
      <div
        className='w-hull h-full flex'
        style={{
          position: 'relative',
          justifyContent: 'center',
        }}
      >
        <Suspense fallback={<Loading />}>
          <Container />
        </Suspense>
        <TopTool />
        <Bottom />
      </div>
    </div>
  );
};
