import styled from '@emotion/styled';
import React from 'react';
import { merge } from 'lodash-es';

type MyLabelProps = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  span?: number;
  sx?: any;
};
const MyLabel = (props: MyLabelProps) => {
  const { title, children, className, style, onClick } = props;
  return (
    <div className={className + ' label'} style={style} onClick={onClick}>
      <div className='label-title'>{title}</div>
      <div className='label-content'>{children}</div>
    </div>
  );
};

export const Label = styled(MyLabel)((props) => {
  const { sx, span } = props;
  const mySx = {};
  if (span) {
    mySx['& .label-title'] = {
      width: (span / 24) * 100 + '%',
      textAlign: 'right',
    };
  }
  return merge(
    {
      ...mySx,
    },
    sx,
  );
});

export const LabelContent = React.forwardRef((props: MyLabelProps, ref: any) => {
  const { sx = {}, ...rest } = props;
  return (
    <Label
      {...rest}
      sx={merge(
        {
          '&': {
            display: 'flex',
          },
          '& .label-title': {
            fontWeight: 500,
            flexShrink: 0,
          },
          '& .label-content': {
            marginLeft: 10,
            flexGrow: 1,
            overflow: 'hidden',
          },
        },
        sx,
      )}
    />
  );
});
