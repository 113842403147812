import { useEffect, useState } from 'react';
import { Target } from '../../type';
import { Icon, Button, InputCommand, LoadingData, Select } from '@/theme';
import { CaretDown } from '@nisar/icons';
import { Collapse } from '@mui/material';

import { CommandData, CommandParams, useCommondList } from './model';
import { Tooltip, message } from 'antd';
import { useDetailStore } from '../../store';
import { postTargetCall } from '../../api';
import { ProtocolTab } from './ProtocolTab';
import styled from '@emotion/styled';
import { Upload } from '../../components/upload';

type CommandCardProps = {
  data?: CommandData;
  onCommand?: (data?: any) => Promise<any>;
  commandKey?: 'commands' | 'properties';
};
type ExpandCardProps = {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  defaultExpand?: boolean;
};
export const ExpandCard = (props: ExpandCardProps) => {
  const { title, children } = props;
  const [isExpand, setIsExpand] = useState(() => {
    return props?.defaultExpand ?? false;
  });
  const expandTitle = (
    <div
      className='flex items-center cursor-pointer'
      onClick={() => {
        setIsExpand(!isExpand);
      }}
      style={{
        color: '#172F41',
        fontWeight: 500,
        marginBottom: 10,
      }}
    >
      <Icon
        icon={
          <CaretDown
            style={{
              transform: isExpand ? 'rotate(0deg)' : 'rotate(270deg)',
            }}
          />
        }
        className='mr-2'
      />
      {title}
    </div>
  );
  const expandInfo = (
    <div
      className='expand-card'
      style={{
        background: '#D8E0E8',
        padding: '10px 16px',
        fontWeight: 500,
        fontSize: 12,
      }}
    >
      {children}
    </div>
  );
  return (
    <>
      {expandTitle}
      <Collapse in={isExpand} timeout={200}>
        {expandInfo}
      </Collapse>
    </>
  );
};
export const CommandCard = (props: CommandCardProps) => {
  const { data, onCommand, commandKey = 'commands' } = props;
  const [command, setCommand] = useState<CommandData | null>(null);
  const [CommandResult, setCommandResult] = useState<SimpleObject | null>(null);
  useEffect(() => {
    if (data) {
      setCommand(data);
    }
  }, [data]);
  const buttonText = commandKey === 'commands' ? 'Call' : 'Apply';
  const call = async () => {
    if (!command || !onCommand) return;
    const res = await onCommand?.(command);
    if (res.success) {
      const { data } = res;
      setCommandResult(data);
    } else if (res.msg) {
      message.error(res.msg);
      setCommandResult(null);
    }
  };
  const changeCommandByParams = (commandParams: CommandParams) => {
    if (!command) return;
    const params =
      command?.params?.map?.((param) => {
        if (param.name === commandParams.name) {
          return commandParams;
        }
        return param;
      }) || [];
    setCommand({
      ...command,
      params,
    });
  };
  const ResultJSON = (props: { data: SimpleObject }) => {
    const { data } = props;
    const dataString = JSON.stringify(data, null, 2);
    let value = dataString.split('\n');
    value.shift();
    value.pop();
    value = value.map((item) => {
      return item.slice(2);
    });
    const showValue = value.join('\n');
    return (
      <div
        className='no-scrollbar flex'
        style={{
          background: '#fff', //
          padding: '4px 8px',
          maxHeight: 300,
          border: '2px',
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <pre className='mb-0'>{showValue}</pre>
      </div>
    );
  };
  const expandInfo = (
    <>
      <div>
        {command?.params?.map?.((item, index) => {
          let Com: any = null;
          if (item.valueType === 'VALUE') {
            Com = (
              <InputCommand
                value={item.value}
                style={{ width: 72 }}
                onChange={(e) => {
                  const value = e.target.value;
                  changeCommandByParams({ ...item, value });
                }}
              />
            );
          }
          if (item.valueType === 'SELECT') {
            const selects = item.selects || [];
            const options = selects.map((item) => {
              return {
                label: item,
                value: item,
              };
            });
            Com = (
              <div key={index}>
                <Select
                  className='w-32'
                  size='small'
                  options={options}
                  value={item.value}
                  onChange={(value) => {
                    changeCommandByParams({ ...item, value });
                  }}
                ></Select>
              </div>
            );
          }
          if (item.valueType === 'JSON_FILE') {
            Com = (
              <div key={index}>
                <Upload
                  onChange={(value) => {
                    changeCommandByParams({ ...item, value });
                  }}
                />
              </div>
            );
          }
          const isJson = item.valueType === 'JSON_FILE';
          return (
            <div key={index} className='flex justify-between items-center mx-2' style={{ padding: '7px 0', fontSize: 12 }}>
              <div
                style={{
                  width: '40%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Tooltip title={item.name}>{item.name}</Tooltip>
              </div>
              <div className='flex items-center'>
                {!isJson && <div style={{ marginRight: 16 }}>{item.type}</div>}
                {Com}
              </div>
            </div>
          );
        })}
      </div>

      {CommandResult && <ResultJSON data={CommandResult} />}
      <Button
        type='primary'
        style={{
          width: '100%',
          borderRadius: 2,
          fontWeight: 500,
        }}
        onClick={call}
      >
        {buttonText}
      </Button>
    </>
  );
  return (
    <div>
      <ExpandCard title={data?.command} defaultExpand={false}>
        {expandInfo}
      </ExpandCard>
    </div>
  );
};
export const Divider = styled('div')((props) => {
  return {
    border: '0.5px solid #C2CFD4',
  };
});

type ProtocolProps = {
  data?: Target;
};
export const Protocol = (props: ProtocolProps) => {
  const { data } = props;
  const { protocols, loaddingCommand } = useDetailStore((state) => {
    return {
      protocols: state.protocols,
      loaddingCommand: state.loaddingCommand,
    };
  });
  const { active, setActive, protocolList } = ProtocolTab.useProtocol(data);
  const { commandList } = useCommondList({ protocols, activeProtocol: active, target: data });

  return (
    <div
      className='flex'
      style={{
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <ProtocolTab protocolList={protocolList} active={active} setActive={setActive} />
      <div
        style={{
          flexGrow: 1,
          overflow: 'hidden',
          marginTop: 10,
        }}
      >
        <LoadingData loading={loaddingCommand}>
          <div className='no-scrollbar' style={{ height: '100%', paddingBottom: '10px', overflow: 'scroll' }}>
            {commandList.map((item, index) => {
              return <CommandCard data={item} key={index} onCommand={postTargetCall} />;
            })}
            {commandList.length === 0 && <div>No Data</div>}
          </div>
        </LoadingData>
      </div>
    </div>
  );
};
