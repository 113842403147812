/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-this-alias */

import { setAutoFreeze, produce } from 'immer';
import { clone, isObject } from 'lodash';
import { useEffect, useRef } from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { shallow } from 'zustand/shallow';
setAutoFreeze(false);

type StateValue<self extends { state: unknown }, K extends keyof self['state']> = Pick<self['state'], K> | self['state'];

type StateFunc<self extends { state: unknown }, K extends keyof self['state']> = (state: self['state']) => Pick<self['state'], K> | self['state'];

export type MapInstanceType<T extends ClassInstance> = (Class: T, key: string, ...args: ConstructorParameters<T>) => InstanceType<T>;
export type MapInstanceTypeThis<Class extends abstract new (...args: any) => any> = (
  this: Class,
  key: string,
  ...args: ConstructorParameters<Class>
) => InstanceType<Class>;

type Constructor = new (...args: any[]) => any;

type InstanctObj = {
  _instance?: InstanceType<Constructor>;
  _instanceMap: Record<string, InstanceType<Constructor>>;
  single: (...args: any[]) => InstanceType<Constructor>;
};

type ClassInstance = Constructor & InstanctObj;

export type StoreClassInstance<T extends new (...args: any) => any> = (this: T, ...args: ConstructorParameters<T>) => InstanceType<T>;

export const singleInstance = function <T extends ClassInstance>(Class: T, ...args: ConstructorParameters<T>): InstanceType<T> {
  if (Class._instance == null) {
    Class._instance = new Class(...args);
  }
  return Class._instance;
};

export const mapInstance = function <T extends ClassInstance>(Class: T, key: string, ...args: ConstructorParameters<T>): InstanceType<T> {
  if (!(key in Class._instanceMap)) {
    const inst = new Class(...args);
    inst.key = key;
    Class._instanceMap[key] = inst;
    return inst;
  }
  return Class._instanceMap[key];
};

export class Store {
  stateInit: any = {
    loading: false,
  };

  _store?: UseBoundStore<StoreApi<this['stateInit']>>;
  destroyed = false;
  destroyList: (() => void)[] = [];
  subStoreList: Store[] = [];
  parent?: Store;
  setLoading = (loading = true) => {
    this.store.setState({ loading: !!loading });
  };
  setLoaded = () => {
    this.store.setState({ loading: false });
  };
  /**
   * add sub store
   * @param storeList
   */
  add(...storeList: Store[]) {
    storeList.forEach((store) => {
      this.subStoreList.push(store);
      store.parent = this;
    });
  }

  removeSub(...storeList: Store[]) {
    const l = this.subStoreList;
    storeList.forEach((store) => {
      const i = l.findIndex((e) => e === store);
      if (i !== -1) {
        l.splice(i, 1);
        store.destroy();
      }
    });
  }
  genStore() {
    this._store = create<this['stateInit']>(() => this.stateInit);
    return this._store;
  }
  get store() {
    if (this._store == null) {
      this.genStore();
    }
    return this._store as UseBoundStore<StoreApi<this['stateInit']>>;
  }

  get subscribe() {
    return this.store.subscribe;
  }

  key?: string;
  get state() {
    return this.store.getState() as this['stateInit'];
  }
  useSelectState = <T extends Record<string, any>>(selector: (store: this['stateInit']) => T) => {
    const ref = useRef<T | null>(null);
    const state = this.store(selector, (a, b) => {
      if (shallow(a, b)) {
        Object.assign(ref.current as T, b);
        return true;
      } else {
        ref.current = { ...b };
        return false;
      }
    });
    if (ref.current == null) {
      ref.current = { ...state };
    }
    return ref.current as T;
  };

  useKey = <Key extends keyof this['stateInit']>(key: Key) => {
    return this.store((state) => state[key]) as this['stateInit'][Key];
  };

  useKeys = <Key extends keyof this['stateInit']>(...keys: Key[]) => {
    return this.useSelectState((state) => {
      return keys.reduce((prev, key) => {
        prev[key] = state[key];
        return prev;
      }, {} as Pick<this['stateInit'], Key>);
    });
  };

  setState = <K extends keyof this['state']>(input: StateValue<this, K> | StateFunc<this, K>, replace?: boolean | undefined) =>
    this.store.setState(input, replace);

  setProduce = (func: (state: this['state']) => void) => {
    this.setState(produce(this.state, func));
  };

  setStateDeep = (keys: (string | number)[], value: unknown) => {
    type RecordObj = {
      parent: null | RecordObj;
      target: object;
      key: string | number;
    };
    const state = this.state;
    let cur: RecordObj = {
      parent: null,
      target: state,
      key: '',
    };
    const end = keys.length - 1;
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      const nextTarget = cur.target[k];
      if (i === end) {
        if (value === nextTarget) {
          return state;
        } else {
          cur.target[k] = value;

          const loop = (obj: RecordObj) => {
            const { parent, target, key } = obj;
            if (parent != null) {
              parent.target[key] = clone(target);
              loop(parent);
            }
          };
          loop(cur);
          this.setState({ ...state });
        }
      } else if (isObject(nextTarget)) {
        const next: RecordObj = {
          parent: cur,
          key: k,
          target: nextTarget,
        };
        cur = next;
      } else {
        return state;
      }
    }
    return state;
  };

  destroy() {
    this.removeSub(...this.subStoreList);
    this.destroyed = true;
    this.destroyList.forEach((f) => f());
    this.store.destroy();
    const Class = this.constructor as unknown as typeof Store;
    if (this.key && this.key in Class._instanceMap) {
      delete Class._instanceMap[this.key];
    }
    if (this === Class._instance) {
      delete Class._instance;
    }
  }
  static _instance?: Store;

  static single<T extends ClassInstance>(this: T, ...args: ConstructorParameters<T>): InstanceType<T> {
    const Class = this;
    if (Class._instance == null) {
      Class._instance = new Class(...args);
    }
    return Class._instance;
  }

  static destroySingle() {
    this.single().destroy();
  }

  static newSingle<T extends ClassInstance>(this: T): InstanceType<T> {
    const Class = this;
    if (Class._instance) {
      Class._instance.destroy();
    }
    return Class.single();
  }

  static _instanceMap: Record<string, InstanceType<typeof Store>> = {};

  static mapInstance<T extends ClassInstance>(this: T, key: string, ...args: ConstructorParameters<T>): InstanceType<T> {
    const Class = this;
    if (!(key in Class._instanceMap)) {
      const inst = new Class(...args);
      inst.key = key;
      Class._instanceMap[key] = inst;
      return inst;
    }
    return Class._instanceMap[key];
  }

  static destroyMapInstance(key: string) {
    if (key in this._instanceMap) {
      this._instanceMap[key].destroy();
    }
  }

  static destroyMapInstanceAll() {
    const map = this._instanceMap;
    Object.keys(map).forEach((k) => {
      this.destroyMapInstance(k);
    });
  }

  static useNew<T extends ClassInstance>(this: T, ...args: ConstructorParameters<T>): InstanceType<T> {
    const Class = this;
    const ref = useRef<InstanceType<T> | null>(null);
    if (ref.current == null) {
      ref.current = new Class(...args);
    } else if (ref.current.destroyed) {
      ref.current = new Class(...args);
    }

    useEffect(() => {
      return () => {
        if (ref.current) {
          ref.current.destroy();
        }
      };
    }, []);
    return ref.current as InstanceType<T>;
  }
}
