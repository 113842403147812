import styled from '@emotion/styled';
import { Select as AntSelect, SelectProps } from 'antd';

import { forwardRef } from 'react';
const MySelect = forwardRef((props: SelectProps, ref: any) => {
  const { dropdownStyle, ...rest } = props;
  return (
    <AntSelect
      ref={ref}
      {...rest}
      dropdownStyle={{
        zIndex: 1401,
        ...dropdownStyle,
      }}
    />
  );
});
export const Select = styled(MySelect)((props) => {
  return {
    '&': {
      borderRadius: '2px',
      outline: 'none',
      // border: '1px solid #C2CFD4',
      background: '#F9FBFF' + ' !important',
    },
    // [`&:hover, &:focus`]: {
    //   border: '1px solid #57B7C8',
    //   // boxShadow: '0px 0px 2px #57B7C8',
    // },
    ['&.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector']: {
      borderColor: '#57B7C8',
    },
    '& .ant-select-selector': {
      backgroundColor: '#F9FBFF' + ' !important',
    },
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: '1px solid #C2CFD4',
    },
  };
});
