import { useState, useRef, useEffect, useMemo } from 'react';
// import L from 'leaflet';
import { LatLngExpression, Map } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { RedIcon, GreenIcon } from '../../component/map/Icon';
import { DeviceData } from '../../type';
import { DeviceCardByLevel } from './DeviceCard';
import './DeviceMap.css';
import { toDevice } from '../../module';

type Props = {
  dataSource: DeviceData[];
  defaultZoom?: number;
  center?: LatLngExpression;
  [key: string]: any;
};
export const DeviceMap = (props: Props) => {
  const { dataSource = [], defaultZoom = 17, center: pCenter } = props;
  const [_center, setCenter] = useState<LatLngExpression>();
  const mapRef = useRef<Map | null>(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    const center = getCenter();
    if (center) setCenter(center);
    if (mounted && center) {
      const map = mapRef.current;
      map!.flyTo(center);
    }
  }, [pCenter, dataSource, mounted]);
  const getCenter = (): LatLngExpression | null => {
    if (pCenter) {
      return pCenter;
    } else {
      const device = dataSource[0];
      if (!device) return null;
      const lat = Number(device?.gpsLat);
      const lon = Number(device?.gpsLon);
      return [lat, lon];
    }
  };
  const OneMarker = ({ item }: any) => {
    const isOnline = item?.status !== 'OFFLINE';
    const icon = !isOnline ? RedIcon : GreenIcon;
    // TODO: fix lat lon
    const lat = Number(item?.gpsLat);
    const lon = Number(item?.gpsLon);
    return (
      <Marker
        key={item.id}
        position={[lat, lon]}
        icon={icon}
        autoPanOnFocus
        eventHandlers={{
          click: (e) => {
            toDevice(item);
          },
        }}
      >
        <Tooltip className='device-map-tooltip' direction='top' offset={[0, -2]} opacity={1}>
          <DeviceMapCard data={item} />
        </Tooltip>
        {/* <Popup closeButton={false} className='device-map-popup'>
          <DeviceMapCard data={item} />
        </Popup> */}
      </Marker>
    );
  };
  const markers = useMemo(() => {
    return dataSource.map((item, index) => {
      return <OneMarker key={index} item={item} />;
    });
  }, [dataSource]);
  return (
    <div className='flex h-full'>
      <MapContainer
        style={{ height: '100%', width: '100%', fontFamily: 'Montserrat' }}
        center={pCenter || [52.520008, 13.404954]}
        zoom={defaultZoom}
        whenReady={(map) => {
          mapRef.current = map.target!;
          setMounted(true);
        }}
      >
        {/* <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' /> */}
        <TileLayer url='https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=8b9b767da6624c4586388109bb011c7c' />
        {markers}
      </MapContainer>
    </div>
  );
};
type DeviceMapCardProps = {
  map?: Map | null;
  data?: DeviceData;
};
export const DeviceMapCard = (props: DeviceMapCardProps) => {
  const { data } = props;
  const map = useMap();
  const [zoom, setZoom] = useState(() => {
    return map.getZoom();
  });
  useEffect(() => {
    map.addEventListener('zoomstart', handleZoom);
    return () => {
      map.removeEventListener('zoomstart', handleZoom);
    };
  }, []);
  const handleZoom = () => {
    const _zoom = map.getZoom();
    setZoom(_zoom);
  };

  // 13 15 17
  const level = useMemo(() => {
    if (zoom > 1) return 3; // set fixed level 3
    if (zoom <= 13) return 1;
    if (zoom <= 15) return 2;
    return 3;
  }, [zoom]);
  const Content = useMemo(() => {
    return (
      <DeviceCardByLevel
        data={data}
        level={level}
        zoom={zoom}
        setZoom={(z) => {
          // map.setView([Number(data?.gpsLat), Number(data?.gpsLon)], z);
        }}
      />
    );
  }, [level, zoom]);
  return (
    <div
      style={{
        pointerEvents: 'auto',
      }}
    >
      {Content}
    </div>
  );
};
