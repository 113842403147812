import { create } from 'zustand';
import { postLogin, getUserInfo } from '../api/api';
import { getToken, saveToken } from '../module/admin';
import { message } from 'antd';
//==== admin info
type Role = {
  id: number;
  code?: string;
  name?: string;
};
type Permission = {
  id: number;
  code?: string;
  name?: string;
  intro?: string;
};
type AdminUser = {
  id: number;
  username: string;
};
type AdminAcl = {
  roles: Role[];
  permissions: Permission[];
};
type AdminStore = { user: AdminUser } & { acl?: AdminAcl };

/// ==== admin actions
export type LoginAction = {
  login?: (username: string, password: string) => Promise<boolean>;
  logout?: () => void;
  getUser?: () => Promise<boolean>;
};

type AdminActions = {
  setAdmin?: (admin: AdminStore) => void;
} & LoginAction;

/**
 * admin store, user info shoule be used in all pages
 */
export const useAdminStore = create<AdminStore & AdminActions>((set, get) => ({
  user: { id: 0, username: '' },
  setAdmin: (admin) => {
    set((state) => ({ ...state, ...admin }));
  },
  login: async (username: string, password: string) => {
    const res = await postLogin<AdminStore & { token: string }>({ username, password });
    if (res.success && res.data?.token) {
      const { data } = res;
      saveToken(data?.token);
      set(() => ({ ...data }));
      return true;
    } else if (res.code === 500) {
      message.error(res.msg || 'Username or password error');
      return false;
    }
    return false;
  },
  getUser: async () => {
    const token = getToken();
    if (token === '' || token === null) return false;
    const userId = get()?.user?.id;
    if (userId > 0) return true;
    const res = await getUserInfo<AdminStore>();
    if (res.success) {
      const { data } = res;
      const { acl, user } = data || {};
      set(() => ({ acl, user }));
      return true;
    }
    return false;
  },
  logout: () => {
    saveToken('');
    set(() => ({ user: { id: 0, username: '' } }));
  },
}));
