import { Space } from 'antd';
import { useState, useEffect } from 'react';

export const useProtocol = (data: any) => {
  const [active, setActive] = useState<string>();
  const [protocolList, setProtocolList] = useState<string[]>([]);
  useEffect(() => {
    const { protocol } = data ?? {};
    if (protocol) {
      const protocolList = protocol.split(',');
      setProtocolList(protocolList);
      setActive(protocolList[0]);
    }
  }, [data]);
  return {
    active,
    setActive,
    protocolList,
  };
};
type ProtocolTabProps = {
  protocolList?: string[];
  active?: string;
  setActive?: (active: string) => void;
};
export const ProtocolTab = (props: ProtocolTabProps) => {
  const { protocolList = [], active, setActive } = props;
  return (
    <Space wrap>
      {/* <div>{data?.protocol}</div> */}
      {protocolList.map((item, index) => {
        const isActive = item === active;
        let style: React.CSSProperties = {
          borderRadius: 2,
          fontWeight: 500,
          padding: '0px 12px',
          userSelect: 'none',
        };
        if (isActive) {
          style = {
            ...style,
            background: '#FFF',
            color: '#4797A5',
            border: '1px solid #4797A5',
          };
        } else {
          style = {
            ...style,
            background: '#F9FBFF',
            color: '#C2CFD4',
            border: '1px solid #C2CFD4',
          };
        }
        return (
          <div
            className='cursor-pointer'
            key={index}
            style={style}
            onClick={() => {
              setActive?.(item);
            }}
          >
            {item}
          </div>
        );
      })}
    </Space>
  );
};
ProtocolTab.useProtocol = useProtocol;
