import { useState } from 'react';
type Opts = {
  open?: boolean;
};

export const useModal = (opts?: Opts) => {
  const [open, setOpen] = useState(opts?.open ?? false);
  const onClose = () => {
    setOpen(false);
  };
  return {
    open,
    setOpen,
    onClose,
  };
};
