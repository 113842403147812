import { HeaderIcon } from './Icon';
export const HeaderLogo = () => {
  return (
    <div className='flex items-center'>
      <HeaderIcon />
      <div
        className='ml-2.5'
        style={{
          fontFamily: 'D-DIN',
          fontWeight: 700,
          marginRight: 23,
        }}
      >
        <div
          style={{
            fontSize: 10,
            color: '#66D7EB',
            marginTop: 5,
            marginBottom: -10,
          }}
        >
          NISAR
        </div>
        <div
          style={{
            fontSize: 20,
          }}
        >
          Connect
        </div>
      </div>
    </div>
  );
};
