import styled from '@emotion/styled';
import { Button as AntButton, ButtonProps } from 'antd';

type MyButtonProps = {
  htmlType?: string;
} & ButtonProps;
export const MyButton = (props: MyButtonProps) => {
  // const { htmlType, ...rest } = props;
  return <AntButton {...props} />;
};
export const Button = styled(MyButton)((props) => {
  return {
    '&': {
      fontWeight: 500,
      fontSize: 12,
      borderRadius: 2,
    },
    '& .ant-wave': {
      display: 'none',
    },
    '&:disabled': {
      color: '#F9FBFF',
      background: '#D8E0E8',
    },
  };
});
