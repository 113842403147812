import { useEffect, useState } from 'react';
import { getTenantList, TenantData, postTenant, putTenant, deleteTenant } from './api';
import { message, Space } from 'antd';
import { Button, TableAction, Form, FormModal, InputLogin as Input, usePagination } from '@/theme';
import { Table, TableWrapper } from '@/theme';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router';

export const TenantList = () => {
  const {
    run,
    refresh,
    loading,
    params: [param],
    pagination,
    dataSource,
  } = usePagination(getTenantList);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    run();
  }, []);
  const onFinish = async () => {
    const values = await form.validateFields();
    const api = values.id ? putTenant : postTenant;
    const res = await api(values);
    if (res.success) {
      message.success('success');
      setOpen(false);
      refresh();
    }
  };
  const navigateToTenant = (record) => {
    const id = record.id;
    navigate('/admin/tenant/' + id, { state: { ...record } });
  };
  const columns: ColumnsType<TenantData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 180,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 240,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      width: 200,
    },
    {
      title: 'Device',
      dataIndex: 'deviceNum',
      width: 70,
    },
    {
      title: 'User',
      dataIndex: 'userNum',
      width: 50,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      width: 250,
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      fixed: 'right',
      width: 200,
      align: 'center',
      render: (_, record) => {
        const onViewer = () => {
          if (record.id) navigateToTenant(record);
        };
        return (
          <Space>
            <TableAction
              type='delete'
              onClick={async () => {
                if (!record.id) return;
                const r = await deleteTenant({ tenantId: record.id });
                if (r.success) {
                  message.success('success');
                  refresh();
                }
              }}
            >
              Delete
            </TableAction>
            <TableAction
              type='edit'
              onClick={() => {
                if (record.id) {
                  setOpen(true);
                  form.setFieldsValue(record);
                }
              }}
            >
              Edit
            </TableAction>
            <TableAction type='viewer' onClick={onViewer}>
              View
            </TableAction>
          </Space>
        );
      },
    },
  ];
  const onQueryFinish = (value) => {
    run({ ...param, ...value });
  };
  const QueryForm = (
    <>
      <TableWrapper.QueryForm onFinsh={onQueryFinish} />
    </>
  );

  const AddButton = (
    <Button type='primary' onClick={() => setOpen(true)} size='small'>
      + add New
    </Button>
  );
  return (
    <div>
      <TableWrapper search={QueryForm} tools={AddButton}>
        <Table
          dataSource={dataSource}
          rowKey={'id'}
          columns={columns}
          loading={loading}
          scroll={{ x: 600, y: 400 }}
          pagination={{
            ...pagination,
          }}
        />
      </TableWrapper>
      <FormModal
        open={open}
        style={{ width: 540 }}
        onClose={() => {
          form.resetFields();
          setOpen(false);
        }}
      >
        <div style={{ fontSize: 20, textAlign: 'center' }}>{open && !form.getFieldValue('id') ? 'Add New Tenant' : 'Edit Tenant'}</div>
        <Form style={{ marginTop: 30 }} form={form} labelCol={{ span: 6 }} onFinish={onFinish}>
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item label='Target Name' name='name' required rules={[{ required: true, message: 'Please input name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label='Contact' name='contact' rules={[{ required: true, message: 'Please input Contact!' }]}>
            <Input placeholder='Please enter' />
          </Form.Item>
          <Form.Item label='Notes' name='notes'>
            <Input />
          </Form.Item>
          <Form.Item label=' ' colon={false}>
            <Space>
              <Button type='primary' htmlType='submit'>
                Confrim
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </FormModal>
    </div>
  );
};
