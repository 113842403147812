import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
export type DropdownItem = {
  label: string;
  key: string;
};
export type DropdownMenu = {
  items: DropdownItem[];
  value?: string;
  onChange?: (k: string, item: DropdownItem, e) => void;
  [key: string]: any;
};
type DropdownProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  menu?: DropdownMenu;
  menuProps?: any;
};
/**
 * mui dropdown
 * @param props
 * @returns
 */
export const Dropdown = (props: DropdownProps) => {
  const { className, style, menu, menuProps } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { items = [], value, onChange } = menu || {};
  const isMenuOpen = Boolean(anchorEl);

  const clickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const onMenuClick = (k: string, item: DropdownItem, e: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    onChange?.(k, item, e);
    closeMenu();
  };
  return (
    <>
      <div className={className} onClick={clickOpen} style={style}>
        {props.children}
      </div>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...menuProps}
      >
        {items?.map((item, index) => {
          return (
            <MenuItem
              key={index}
              selected={item.key === value}
              onClick={(e) => {
                onMenuClick(item.key, item, e, index);
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
