import { InputCommand, Select } from '@/theme';
import { Upload } from '../device-detail/components/upload';

const params = [
  {
    type: 'String',
    value: '',
    valueType: 'VALUE',
    selects: [],
  },
  {
    type: 'String',
    valueType: 'SELECT',
    selects: ['Nisar', 'EFS'],
    name: 'ProfileName',
    value: 'Nisar',
  },
  {
    name: 'FilePath',
    type: 'String',
    value: '',
    valueType: 'JSON_FILE',
    selects: null,
  },
];
export const Common = () => {
  return (
    <div>
      {params.map((item, index) => {
        if (item.valueType === 'VALUE') {
          return (
            <div key={index}>
              <InputCommand
                value={item.value}
                style={{ width: 72 }}
                onChange={(e) => {
                  const value = e.target.value;
                  // changeCommandByParams({ ...item, value });
                  console.log(value);
                }}
              />
            </div>
          );
        }
        if (item.valueType === 'SELECT') {
          const selects = item.selects || [];
          const options = selects.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          return (
            <div key={index}>
              <Select
                className='w-32'
                size='small'
                options={options}
                value={item.value}
                onChange={(value) => {
                  // changeCommandByParams({ ...item, value });
                  console.log(value);
                }}
              ></Select>
            </div>
          );
        }
        if (item.valueType === 'JSON_FILE') {
          return (
            <div key={index}>
              <Upload
                onChange={(value) => {
                  // changeCommandByParams({ ...item, value });
                  console.log(value);
                }}
              />
            </div>
          );
        }
        return (
          <div key={index}>
            <div>{item.type}</div>
            <div>{item.valueType}</div>
          </div>
        );
      })}
    </div>
  );
};
