import { PaletteMode, ThemeOptions } from '@mui/material';
import { dark, light } from './palette';
//  "Montserrat", "Menlo", "D-DIN", "Orbitron"
export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const isLight = mode === 'light';
  const palette = isLight ? light : dark;
  return {
    palette: {
      mode,
      ...palette,
    },
    typography: {
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      // codeFamily: 'Menlo',
    },
  };
};
