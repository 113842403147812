import { create } from 'zustand';
import { WS } from '@/utils';
import { createConsoleSocket } from './ws';
export type ConsoleData = {
  message: string;
  severity: '1' | '2' | '4' | '8' | '16';
  targets: string;
  stamp: string;
  location: string;
  id: string;
};
import { sendExtraData } from '../mitt';
import { produce } from 'immer';

const defaultData: ConsoleData[] = [
  { id: '1', severity: '1', message: 'message', stamp: 'stamp', targets: 'Targets', location: '/api/sdf' }, //
  { id: '2', severity: '2', message: 'message', stamp: 'atamp', targets: 'Target2', location: '/etc/hel' }, //
];
type State = {
  ws?: WS;
  list: ConsoleData[];
  init: (id: string) => void;
  destory?: () => void;
  msgCount: number;
  lastMsgCount?: number; // last clear msg count, unread msg count
  clearMsg: () => void;
  unreadMsgCount: number;
  recording: boolean;
  recordUnredMsg: (recording?: boolean) => void;
};

export const MessageExtra = ['TARGETS_UPDATED', 'DATA_VIEWER', 'DOWNLOAD_FILE'] as const;
type MessageExtraType = (typeof MessageExtra)[number];

type MessageData = {
  busi: 'CONSOLE' | MessageExtraType;
  data: ConsoleData;
};

export const useConsoleStore = create<State>((set, get) => ({
  ws: undefined,
  list: [],
  msgCount: 0,
  clearMsg: () => {
    const { msgCount } = get();
    set({ msgCount: 0, lastMsgCount: msgCount, unreadMsgCount: 0, recording: false });
  },
  unreadMsgCount: 0,
  recording: false,
  recordUnredMsg: (recording = true) => {
    set({ recording: recording });
  },
  init: async (id: string) => {
    const onMessageData = (data?: ConsoleData) => {
      const { list, msgCount } = get();
      if (!data) {
        console.error('no Console Data');
        return;
      }
      const newList = [...list];
      newList.push({
        ...data,
        id: '' + Math.random().toString(32).slice(2),
      });
      set(
        produce((state) => {
          state.list = newList;
          state.msgCount = msgCount + 1;
          if (state.recording) state.unreadMsgCount = state.unreadMsgCount + 1;
        }),
      );
    };
    const onMsg = (msgData) => {
      try {
        let msg: MessageData | undefined = undefined;
        if (typeof msgData === 'string') msg = JSON.parse(msgData);
        else {
          msg = msgData;
        }
        const { data: _record, busi } = msg || {};
        if (busi === 'CONSOLE') {
          onMessageData(_record);
        } else if (busi && MessageExtra.includes(busi)) {
          sendExtraData(busi, _record);
        }
      } catch (error) {
        console.error('error', error);
      }
    };
    const ws = createConsoleSocket({ deviceId: id });
    ws.onMessage(onMsg);
  },
  destory: () => {
    const { ws } = get();
    if (ws) {
      ws.close();
    }
  },
}));
