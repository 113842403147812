import { create } from 'zustand';
import { postLogin, getUserInfo, postResetPassword, postSendResetEmail } from '../pages/sign/api';
import { getToken, saveToken } from '../module';
import { TenantData } from '@/admin/pages/tenant/api';
import { message } from 'antd';
export type UserStore = {
  user?: any;
  login?: any;
  logout?: () => void;
};
//==== admin info
type Role = {
  id: number;
  code?: string;
  name?: string;
};
type Permission = {
  id: number;
  code?: string;
  name?: string;
  intro?: string;
};
type User = {
  id: number;
  username: string;
};
type Acl = {
  roles: Role[];
  permissions: Permission[];
};
type Store = { user: User; tenant?: TenantData } & { acl?: Acl };

/// ==== actions
export type LoginAction = {
  login?: (username: string, password: string) => Promise<boolean>;
  logout?: () => void;
  getUser?: () => Promise<boolean>;
  forgetPassword?: (data: any) => Promise<boolean>;
  sendEmail?: (email: string) => Promise<boolean>;
};

type AdminActions = {
  [key: string]: any;
} & LoginAction;

/**
 * store, user info shoule be used in all pages
 */
export const useStore = create<Store & AdminActions>((set, get) => ({
  user: { id: 0, username: '' },
  tenant: { id: '', name: '' },
  login: async (username: string, password: string) => {
    const res = await postLogin<Store & { token: string }>({ username, password });
    if (res.success && res.data?.token) {
      const { data } = res;
      saveToken(data?.token);
      return true;
    } else if (res.code === 500) {
      message.error(res?.msg || 'Username or password error');
    }
    return false;
  },
  logout: () => {
    saveToken('');
    set(() => ({ user: { id: 0, username: '' } }));
  },
  getUser: async () => {
    const token = getToken();
    if (token === '' || token === null) return false;
    const userId = get()?.user?.id;
    if (userId > 0) return true;
    const res = await getUserInfo<Store>();
    if (res.success) {
      const { data } = res;
      const { acl, user, tenant } = data || {};
      set(() => ({ acl, user, tenant }));
      return true;
    }
    return false;
  },
  sendEmail: async (email: string) => {
    const res = await postSendResetEmail({ email });
    return !!res.success;
  },
}));
