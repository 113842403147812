import { create } from 'zustand';
import { TargetData } from '../../api';

type Store = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshCount: number;
  refresh: () => void;
  clearRefresh: () => void;
  deviceId?: string;
  setDeviceId: (deviceId: string) => void;
  tenantId?: string;
  setTenantId: (tenantId: string) => void;
  targetInfo?: TargetData;
  getTargetInfo: () => TargetData | undefined;
  setTargetInfo: (targetInfo: TargetData) => void;
};
export const useTargetEditStore = create<Store>((set, get) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
  refreshCount: 0,
  refresh: () => set((state) => ({ refreshCount: state.refreshCount + 1 })),
  clearRefresh: () => set({ refreshCount: 0 }),
  deviceId: undefined,
  setDeviceId: (deviceId: string) => set({ deviceId }),
  tenantId: undefined,
  setTenantId: (tenantId: string) => set({ tenantId }),
  targetInfo: undefined,
  getTargetInfo: () => {
    const { targetInfo } = get();
    if (targetInfo) {
      set({ targetInfo: undefined });
    }
    return targetInfo;
  },
  setTargetInfo: (targetInfo: TargetData) => set({ targetInfo }),
}));
