import { useState, useRef } from 'react';
import { PopoverReference, Menu } from '@mui/material';

/**
 * use this hook way:
 * 1. render to default use render
 * 1. onContextMenu to setRenderItems: set the render items
 * @returns
 */
export const useContextMenu = () => {
  const [position, setPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const ref = useRef<(() => React.ReactNode) | null>(null);

  const setRenderItems = (func: (() => React.ReactNode) | null) => {
    ref.current = func;
  };
  const close = () => {
    setPosition(null);
    ref.current = null;
  };

  const onContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    let _position: any = null;
    if (position === null) {
      _position = {
        x: event.clientX + 2,
        y: event.clientY - 6,
      };
    }

    setPosition(_position);
  };

  const menuProps = {
    open: position !== null,
    onClose: close,
    anchorReference: 'anchorPosition' as PopoverReference,
    anchorPosition: position !== null ? { top: position.y, left: position.x } : undefined,
  };

  const render = () => {
    if (typeof ref.current === 'function') {
      return (
        <Menu
          {...menuProps}
          className='context-menu'
          onContextMenu={(event) => {
            event.preventDefault();
            close();
          }}
        >
          {ref.current()}
        </Menu>
      );
    } else {
      return null;
    }
  };

  return {
    onContextMenu,
    close,
    render,
    setRenderItems,
  };
};
