import { Studio, getDataSourceArgsByUrl } from '@/components/studio';
import { EasyViewStore } from './model';
import Styles from './DataViewer.module.scss';
import { Close } from '@nisar/icons';
import cs from 'classnames';

export const DataViewer = () => {
  const easyViewStore = EasyViewStore.useStore();
  const _pinList = easyViewStore.useKey('pinList');
  const activeId = easyViewStore.useKey('activeId');
  const removePin = (value) => {
    easyViewStore.removePin(value);
  };
  const openModal = (value) => {
    easyViewStore.addModal(value.id);
  };
  const pinList = _pinList.sort((a, b) => {
    if (a.id === activeId) return -1;
    return 0;
  });
  return (
    <div className={cs(Styles.DataViewer, 'no-scrollbar')}>
      {pinList.map((item, index) => {
        const { foxgloveUrl, config } = item;
        const { sourceId, datasourceArgs } = getDataSourceArgsByUrl(foxgloveUrl);
        return (
          <div className='flex flex-col' key={index} style={{ width: 'calc(50% - 10px)', marginTop: 5, marginLeft: index % 2 === 0 ? 0 : 2 }}>
            <div className='flex items-center py-2 pl-4 pr-2'>
              <div
                className='grow'
                onClick={() => {
                  openModal(item);
                }}
              >
                {item.title}
              </div>
              <Close
                className='cursor-pointer w-6 h-6'
                onClick={() => {
                  removePin(item);
                }}
              />
            </div>
            <div style={{ width: '100%', height: 300 }}>
              <Studio type={item.category} showSetting={false} config={config} sourceId={sourceId} datasourceArgs={datasourceArgs} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
