import { UserAvatarInfo as UserAvatar } from '@/module';
import { Header } from '@/theme';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDetailStore } from './store';
import { Left, Right, Center } from './content';
import { EasyViewerButton } from './module/top-toolbar';
import { NisarDropIcon } from '@/layout/header';
import { useDeviceRefresh } from './module/mitt';
import { EditModal, useTargetEditStore } from './module/edit-modal';
import { useStore } from '@/store';
import { Download } from './module/download-modal';
export const Detail = () => {
  const params = useParams();
  const store = useDetailStore();
  const userStore = useStore();
  useEffect(() => {
    init();
    return () => {
      store.dispose?.();
    };
  }, []);
  const init = async () => {
    if (!params.id) return;
    store.getDevice?.(params.id);
  };
  useDeviceRefresh(init);
  const targetStore = useTargetEditStore();
  const { open, refreshCount, clearRefresh } = targetStore;
  useEffect(() => {
    if (refreshCount > 0) {
      init();
      clearRefresh();
    }
  }, [open, refreshCount]);
  return (
    <div style={{ height: '100%' }}>
      <Header className='flex justify-between relative' style={{ padding: '0px 30px 0px 10px' }}>
        <div className='flex items-center'>
          <NisarDropIcon />
          <EasyViewerButton />
        </div>
        <div className='absolute h-full w-full pointer-events-none flex justify-center items-center'>
          <div className='text-lg'>{store?.device?.name ?? ''}</div>
        </div>
        <UserAvatar />
      </Header>
      <div className='flex' style={{ height: 'calc(100% - 48px)' }}>
        <Left />
        <Center />
        <Right />
      </div>
      <EditModal tenant={userStore.tenant} />
      <Download />
    </div>
  );
};
