import { useOptions } from '@/module';
import { getTargetRealList as getList } from './api';
import { useCallback } from 'react';

export const useTargetOptions = (id?: string, open?: boolean, query?: any) => {
  const api = useCallback(() => {
    if (!id) return Promise.resolve([]);
    const run = query ? query : getList;
    return run({ deviceId: id, pageSize: 9999 });
  }, [id, open]);
  const transfrom = (res) => {
    if (res.success) {
      const { data = [] } = res;
      return data.map((item) => {
        return {
          label: item.name,
          value: item.targetId,
          data: item,
        };
      });
    }
    return [];
  };
  const { options } = useOptions({ api, transform: transfrom });
  return {
    options,
    getValue: useCallback(
      (id: string) => {
        const option = options.find((item) => item.value === id);
        return option?.data;
      },
      [options],
    ),
  };
};
