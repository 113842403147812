import { IconProps } from '@/theme';
import { LinkOutlined, UserOutlined } from '@ant-design/icons';

export const DashboardIcon = (props: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.85714 14.9091C6.85714 14.2034 6.28436 13.6364 5.57143 13.6364C4.8585 13.6364 4.28572 14.2034 4.28572 14.9091C4.28572 15.6148 4.8585 16.1818 5.57143 16.1818C6.28436 16.1818 6.85714 15.6148 6.85714 14.9091ZM8.78572 10.4545C8.78572 9.74882 8.21293 9.18182 7.5 9.18182C6.78707 9.18182 6.21429 9.74882 6.21429 10.4545C6.21429 11.1603 6.78707 11.7273 7.5 11.7273C8.21293 11.7273 8.78572 11.1603 8.78572 10.4545ZM13.0845 15.2375L14.0989 11.439C14.1387 11.2756 14.1128 11.1033 14.0265 10.9585C13.9402 10.8137 13.8004 10.7078 13.6367 10.6633C13.4715 10.6217 13.2964 10.6464 13.1496 10.7321C13.0028 10.8178 12.8962 10.9576 12.8531 11.1208L11.8386 14.9193C11.4413 14.9504 11.0635 15.103 10.7576 15.356C10.4518 15.609 10.2329 15.9498 10.1312 16.3314C10.002 16.8219 10.0743 17.343 10.3322 17.7809C10.5901 18.2188 11.0127 18.5378 11.5076 18.6681C12.0031 18.796 12.5296 18.7245 12.9719 18.4691C13.4143 18.2138 13.7365 17.7955 13.8681 17.3056C13.9673 16.923 13.9453 16.5194 13.8051 16.1495C13.665 15.7796 13.4134 15.4612 13.0845 15.2375ZM19.7143 14.9091C19.7143 14.2034 19.1415 13.6364 18.4286 13.6364C17.7156 13.6364 17.1429 14.2034 17.1429 14.9091C17.1429 15.6148 17.7156 16.1818 18.4286 16.1818C19.1415 16.1818 19.7143 15.6148 19.7143 14.9091ZM13.2857 8.54545C13.2857 7.83973 12.7129 7.27273 12 7.27273C11.2871 7.27273 10.7143 7.83973 10.7143 8.54545C10.7143 9.25118 11.2871 9.81818 12 9.81818C12.7129 9.81818 13.2857 9.25118 13.2857 8.54545ZM17.7857 10.4545C17.7857 9.74882 17.2129 9.18182 16.5 9.18182C15.7871 9.18182 15.2143 9.74882 15.2143 10.4545C15.2143 11.1603 15.7871 11.7273 16.5 11.7273C17.2129 11.7273 17.7857 11.1603 17.7857 10.4545ZM21 14.9091C21 16.6196 20.5076 18.2697 19.5838 19.7117C19.5245 19.8005 19.4439 19.8733 19.3492 19.9236C19.2545 19.9739 19.1487 20.0002 19.0412 20H4.95879C4.73764 20 4.53707 19.8905 4.41622 19.7117C3.49033 18.2777 2.99887 16.611 3 14.9091C3 9.997 7.03779 6 12 6C16.9622 6 21 9.997 21 14.9091Z'
        fill='currentColor'
      />
    </svg>
  );
};
export const TenantIcon = (props: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.753 5.09122V18.5694H14.8225V8.30677L18.2935 9.44324C18.4437 9.53365 18.5696 9.65556 18.6614 9.79941C18.7532 9.94327 18.8083 10.1052 18.8225 10.2724L18.826 10.3571V18.5694H20V20H4V18.5699H5.168V7.63335C5.168 7.25782 5.3765 6.90864 5.7185 6.70958L11.962 4.16745C12.742 3.71381 13.753 4.23522 13.753 5.09122ZM11.175 11.4367L7.748 12.7543V14.3007L11.175 12.9835V11.4367ZM11.175 8.05453L7.748 9.41172V10.9661L11.175 9.60842V8.05547V8.05453Z'
        fill='currentColor'
      />
    </svg>
  );
};
export const DeviceIcon = (props: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6 4C4.73929 4 4 4.73929 4 6V18C4 19.2607 4.73929 20 6 20H18C19.2607 20 20 19.2607 20 18V6C20 4.73929 19.2607 4 18 4H6ZM8 7.14286V12.2857C8 12.7607 7.61786 13.1429 7.14286 13.1429C6.66786 13.1429 6.28571 12.7607 6.28571 12.2857V7.14286C6.28571 6.66786 6.66786 6.28571 7.14286 6.28571C7.61786 6.28571 8 6.66786 8 7.14286ZM11.4286 7.14286V12.2857C11.4286 12.7607 11.0464 13.1429 10.5714 13.1429C10.0964 13.1429 9.71429 12.7607 9.71429 12.2857V7.14286C9.71429 6.66786 10.0964 6.28571 10.5714 6.28571C11.0464 6.28571 11.4286 6.66786 11.4286 7.14286Z'
        fill='currentColor'
      />
    </svg>
  );
};
export const TargetIcon = (props: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.2 8.73684V10.3158H16.8V8.73684H14.4V4H16V7.15789H20V8.73684H18.4V18.2105C18.4 18.4199 18.3157 18.6207 18.1657 18.7688C18.0157 18.9168 17.8122 19 17.6 19H6.4C6.18783 19 5.98434 18.9168 5.83431 18.7688C5.68429 18.6207 5.6 18.4199 5.6 18.2105V8.73684H4V7.15789H8V4H9.6V8.73684H7.2ZM12.8 4V8.73684H11.2V4H12.8Z'
        fill='currentColor'
      />
    </svg>
  );
};
export const menuIcons = {
  Dashboard: <DashboardIcon />,
  Tenant: <TenantIcon />,
  Device: <DeviceIcon />,
  Target: <TargetIcon />,
  Link: <LinkOutlined />,
  User: <UserOutlined />,
};
