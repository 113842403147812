import { useOptions } from '@/module';
import { getList } from './api';
import { useCallback } from 'react';
/**
 *
 * @param tenantId
 * @param open
 * @param query get list api
 * @returns
 */
export const useDeviceOptions = (tenantId?: string, open?: boolean, query?: any) => {
  const api = useCallback(() => {
    // if (!tenantId) return Promise.resolve([]);
    if (!open) return Promise.resolve([]);
    const run = query ? query : getList;
    return run({ tenantId, pageSize: 9999 });
  }, [tenantId, open, query]);
  return useOptions({ api });
};
