import { useEffect, useState } from 'react';
import { TenantUserData, getTenantUserList, postTenantUser } from './api';
import { ColumnsType } from 'antd/es/table';
import { Button, Divider, Input, Select, Space } from 'antd';
import { FormLayout } from '@/components/form';
import { SimpleModal } from '@/components/modal';
import { ModalTitle, Table, TableWrapper, Form, usePagination, TableAction } from '@/theme';
import dayjs from 'dayjs';
import { useAcl } from './hooks';
import { TenantData } from '../tenant/api';
import cs from 'classnames';

type Props = {
  tenant?: TenantData;
  isSingle?: boolean;
};
export const User = (props: Props) => {
  const { tenant, isSingle } = props;
  const tenantId = tenant?.id;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const acl = useAcl(tenant);
  const {
    run,
    params: [param],
    pagination,
    dataSource,
    loading,
    refresh,
  } = usePagination(getTenantUserList);
  useEffect(() => {
    if (!tenantId && !isSingle) return;
    run({ tenantId });
  }, [tenantId]);

  const onFinish = async () => {
    const values = await form.validateFields();
    const res = await postTenantUser({ ...values, tenantId });
    if (res.success) {
      setOpen(false);
      refresh();
    }
  };
  const columns: ColumnsType<TenantUserData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render(_, record) {
        return (
          <div className='flex'>
            <div className='mr-1'>{record?.firstName}</div>
            <div>{record?.lastName}</div>
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
    },
    {
      title: 'Last Active',
      width: 200,
      dataIndex: 'lastActive',
      render: (value) => {
        return <div>{value && dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</div>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_value, record) => {
        return (
          <Space>
            <TableAction type='delete'>Delete</TableAction>
            <TableAction
              type='edit'
              onClick={() => {
                form.setFieldsValue(record);
                setOpen(true);
              }}
            >
              Edit
            </TableAction>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={cs(!isSingle && 'h-full overflow-hidden')}>
      <div>
        <TableWrapper
          search={
            <TableWrapper.QueryForm
              onFinsh={(v) => {
                run({ ...param, ...v });
              }}
            />
          }
          tools={
            <>
              {!isSingle && (
                <TableWrapper.AddButton
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              )}
            </>
          }
        >
          <Table dataSource={dataSource} rowKey={'id'} columns={columns} loading={loading} pagination={pagination} />
        </TableWrapper>
        <SimpleModal
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          style={{ top: 30 }}
        >
          <ModalTitle>{'Add New User'}</ModalTitle>
          <Form
            form={form}
            onFinish={onFinish}
            {...FormLayout}
            initialValues={{
              tenantId: tenantId,
            }}
          >
            <Form.Item name='id' hidden>
              <Input />
            </Form.Item>
            <Form.Item name='firstName' label='First Name' required>
              <Input />
            </Form.Item>
            <Form.Item name='lastName' label='Last Name' required>
              <Input />
            </Form.Item>
            <Form.Item name='email' label='Email' required>
              <Input />
            </Form.Item>
            <Form.Item name='password' label='Password' required>
              <Input type='password' />
            </Form.Item>
            <Form.Item name='phoneNumber' label='Phone' required>
              <Input />
            </Form.Item>
            <Divider />
            <Form.Item name='tenantId' label='Tenant' required>
              <Select options={acl.tenantItems} disabled />
            </Form.Item>
            <Form.Item name='roleId' label='Role' required>
              <Select options={acl.roleItems} />
            </Form.Item>
            <Form.Item name='permissionId' label='Permissions' required>
              <Select mode='multiple' options={acl.permissionItems} />
            </Form.Item>
            <Form.Item label=' ' colon={false}>
              <Space>
                <Button type='primary' htmlType='submit'>
                  Confrim
                </Button>
                <Button onClick={() => setOpen(false)} htmlType='reset'>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </SimpleModal>
      </div>
    </div>
  );
};
