import { message } from 'antd';
import axios, { Axios, AxiosRequestConfig, AxiosResponse } from 'axios';

export type ResponseData = { code?: number; data?: any; msg?: string };

interface HandleResponseData<T = any> {
  success?: boolean;
  code?: number;
  data?: T;
  msg?: string;
}

/**
 *  axios response 内容处理
 *  T is type a
 */
export interface CustomAxios extends Axios {
  <T = any, R = HandleResponseData<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R>;
  <T = any, R = HandleResponseData<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;

  getUri(config?: AxiosRequestConfig): string;
  request<T = any, R = HandleResponseData<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R>;
  get<T = any, R = HandleResponseData<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  delete<T = any, R = HandleResponseData<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  head<T = any, R = HandleResponseData<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  options<T = any, R = HandleResponseData<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  post<T = any, R = HandleResponseData<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  put<T = any, R = HandleResponseData<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patch<T = any, R = HandleResponseData<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  postForm<T = any, R = HandleResponseData<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  putForm<T = any, R = HandleResponseData<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patchForm<T = any, R = HandleResponseData<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
}

const baseURL = '/api/c2cb-tenant-biz';
const tokenName = 'token';
const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 6000,
  baseURL,
};
if (DEV_SERVER) {
  defaultConfig.timeout = 6000;
}
/**
 * create custom request
 * @param param0
 * @returns
 */
export const createAppRequest = ({ config, callback, request, response, key = 'createAppReaquest' }: any): CustomAxios => {
  const instance = axios.create({
    timeout: 3000,
    ...config,
  });
  if (callback) callback(instance);
  if (request) {
    instance.interceptors.request.use(request.onSuccess, request.onError);
  }
  if (response) {
    instance.interceptors.response.use(response.onSuccess, response.onError);
  }
  (instance as any).key = key;
  return instance;
};

const requestIntercept = {
  onSuccess: (config: any) => {
    const headers = config.headers || {};
    const token = localStorage.getItem(tokenName);
    token && (headers['Authorization'] = token);
    // if (DEV_SERVER) {
    //   console.groupCollapsed('request url:', config.url);
    //   console.log('request body', config);
    //   console.groupEnd();
    // }
    return config;
  },
};
const responseIntercept = {
  onSuccess: (response: AxiosResponse) => {
    const { data = {}, config } = response;
    if (DEV_SERVER) {
      console.groupCollapsed('request url:', response.request.responseURL);
      console.log('request', config?.data);
      console.log('response body', data);
      console.groupEnd();
    }
    if (data?.code === 403) {
      message.error('token expired, please login again');
      setTimeout(() => {
        location.href = '/login';
      }, 400);
      return { list: [] };
    } else if (data?.code === 401) {
      console.error('no permission');
    }
    // api has success
    // if (data.code === 200) {
    //   data.success = true;
    // }
    data.msg = data?.message || '';
    return data;
  },
  onError: (response: any) => {
    console.log('reqponse error', response);
    const { data } = response?.response || {};
    if (DEV_SERVER) {
      message.error('request error', data?.error || data?.message || response.message);
    }
    return {
      success: false,
      msg: data?.message || response?.message || 'request error',
      data,
    };
  },
};

let request = createAppRequest({ config: defaultConfig, request: requestIntercept, response: responseIntercept });

export type Options = {
  config?: any;
  request?: typeof requestIntercept;
  response?: typeof responseIntercept;
};
/**
 * global request reCreate
 * @param options
 */
export const reCreateAppRequet = (options?: Options) => {
  request = createAppRequest({ config: defaultConfig, request: requestIntercept, response: responseIntercept, ...options });
};
export { request };

export const saveToken = (token: string) => {
  localStorage.setItem(tokenName, token);
};
export const getToken = () => {
  return localStorage.getItem(tokenName);
};
