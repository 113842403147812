import { admin } from '@/admin/module';

/**
 * login
 * @param data
 * @returns
 */
export const postLogin = <T = any>(data: { username: string; password: string }) => {
  return admin.post<T>('/user/login', data);
};

/**
 * getUserInfo
 * @returns
 */
export const getUserInfo = <T = any>() => {
  return admin.get<T>('/user/info');
};

export const getHawktBitUrl = <T = string>() => {
  return admin.get<T>('/hawkBit/url');
};
