import { Button } from '@/theme';
import { Fragment, useMemo, useRef, useState } from 'react';
import { Studio, getDataSourceArgsByUrl } from '@/components/studio/v2';
import { EasyViewStore } from './model';
import { Loading } from '@/theme';
import { Close, Plus } from '@nisar/icons';
import { Dialog } from '@mui/material';
import Draggable from 'react-draggable';
import { createPortal } from 'react-dom';
import { message } from 'antd';
import classNames from 'classnames';
import Styles from './style.module.scss';
import { Viewer as ViewerIcon } from '@nisar/icons';
export const randomId = () => Math.random().toString(16).slice(2, 8);

type DialogSaveProps = {
  open: boolean;
  closeAll: () => void;
  close: () => void;
  save: () => void;
  loading?: boolean;
};
export const DialogSave = (props: DialogSaveProps) => {
  const { close, closeAll, save, open, loading } = props;

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        '&': { zIndex: 2300 },
        '& .MuiPaper-root': {
          borderRadius: '4px',
        },
      }}
    >
      <div
        style={{
          width: 428,
          fontSize: 16,
          fontWeight: 500,
          color: '#172F41',
          background: '#F9FBFF',
          padding: '16px 24px',
          borderRadius: '2px',
        }}
      >
        <div className='flex justify-between'>
          <div>Save the changes</div>
          <div className='w-5 cursor-pointer' onClick={close}>
            <Close />
          </div>
        </div>
        <div
          style={{
            margin: '28px 0px 28px 0px',
          }}
        >
          <div>Do you want to save the changes you made?</div>
          <div>If not, the content you changed will be lost.</div>
        </div>

        <div className='flex justify-end gap-4'>
          <Button onClick={closeAll} disabled={loading}>
            {"Don't save"}
          </Button>
          <Button type='primary' disabled={loading} onClick={save}>
            {loading ? <Loading /> : 'Save'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const StudioTabList = (props: { draggerId: string }) => {
  const { draggerId } = props;
  const easyViewStore = EasyViewStore.useStore();
  const modalList = easyViewStore.useKey('modalList');
  const activeId = easyViewStore.useKey('activeId');
  const close = (id: number) => {
    easyViewStore.close(id);
  };
  const setValue = (id: number, value) => {
    easyViewStore.setValue(id, value);
  };
  const setActive = (id: number) => {
    easyViewStore.setActive(id);
  };
  const save = async (data: any) => {
    return await easyViewStore.saveModalData(data);
  };
  const addPin = (id: any) => {
    easyViewStore.addPin({ id });
    // bottomViewerStore.openTab({ id, type: 'data-viewer' });
  };
  const len = modalList?.length;
  const activeIndex = modalList?.findIndex((item) => item.id === activeId) || 0;
  const StudioModal = (props: any) => {
    const { value } = props;
    const { id, category: type, config: pConfig } = value;
    const first = useRef(true);
    const StudioMemo = useMemo(() => {
      const { foxgloveUrl } = value;
      const { sourceId, datasourceArgs } = getDataSourceArgsByUrl(foxgloveUrl);
      return (
        <>
          <Studio
            datasourceArgs={datasourceArgs}
            sourceId={sourceId}
            config={pConfig}
            showSetting={true}
            type={type}
            getConfig={(v) => {
              console.log('---------config save', v);
              setValue?.(id, { config: v, isChange: !first.current }); // 回调
              if (first.current) {
                first.current = false;
              }
            }}
          ></Studio>
        </>
      );
    }, [type]);
    return <>{StudioMemo}</>;
  };
  const content = useMemo(() => {
    return (
      <>
        {modalList.map((item) => {
          const { id } = item;
          const isActived = activeId === id;
          return (
            <Fragment key={id}>
              <div className={classNames(isActived ? '' : 'hidden')} style={{ height: 400 }}>
                <div style={{ height: 400, border: '1px solid #C2CFD4', borderRadius: '2px' }}>
                  <StudioModal value={item} />
                </div>
              </div>
            </Fragment>
          );
        })}
      </>
    );
  }, [activeId, modalList.length]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closeId, setCloseId] = useState(0);
  const onDraggerEasyViewerClose = (id: number) => {
    const modal: any = modalList.find((item) => item.id === id);
    if ((modal as any).isChange) {
      setIsOpen(true);
      setCloseId(id);
    } else {
      close?.(id);
    }
  };

  return (
    <div
      className='flex flex-col'
      style={{
        background: '#ffffff00',
      }}
    >
      <div data-role={draggerId} className={classNames('flex items-center justify-between cursor-move', Styles['title'])} style={{ background: '#D8E0E8' }}>
        <div className={classNames('ml-4', Styles['nobar'])} style={{ height: 36, paddingTop: 4, width: '766px' }}>
          <div className={classNames('relative z-10 flex w-full')} style={{ fontSize: 12 }}>
            {modalList.map((item, index) => {
              const { id } = item;
              const isActived = activeId === id;
              return (
                <div
                  className={classNames('flex justify-between items-center pr-2 flex-shrink-0 cursor-pointer', Styles['tab'], {
                    [Styles['active']]: isActived,
                    [Styles['noRight']]: index + 1 === activeIndex,
                  })}
                  key={id}
                  style={{ height: 32, width: 160 }}
                  onClick={() => {
                    setActive(id);
                  }}
                >
                  <div className='flex'>
                    <ViewerIcon style={{ margin: '6px 8px 0px 10px', width: 16, height: 10 }} />
                    <div className='overflow-hidden text-ellipsis whitespace-nowrap' style={{ width: 98 }}>
                      {item?.title || item?.name}
                    </div>
                  </div>
                  <div style={{ width: 16, height: 16 }}>
                    <Close
                      onClick={(e) => {
                        // close(id)
                        onDraggerEasyViewerClose(id);
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div
              className={classNames('flex justify-between items-center pr-2 flex-shrink-0', Styles['active'], len > 0 ? 'hidden' : '')}
              style={{ height: 32, width: 160 }}
            >
              <div>
                <ViewerIcon style={{ margin: '6px 8px 0px 10px', width: 16, height: 10 }} />
                Untitled
              </div>
            </div>
            <div
              className='ml-3 flex cursor-pointer items-center justify-center'
              onClick={() => {
                easyViewStore.toggleModal();
              }}
            >
              <Plus />
            </div>
          </div>
        </div>

        <div className='mr-2 cursor-pointer'>
          <Close
            className='mr-2 w-6'
            onClick={() => {
              easyViewStore.singleModalClose();
            }}
          />
        </div>
      </div>
      <div className='px-10 py-8'>{content}</div>
      {len > 0 && (
        <>
          <div style={{ display: 'flex', justifyContent: 'end', padding: '10px 10px', background: '#f3f6fc' }}>
            <Button
              style={{ marginRight: 10, border: '1px solid #C2CFD4' }}
              onClick={async () => {
                setValue?.(activeId, { pin: true }); // update data
                addPin?.({ id: activeId }); // update pin data
                const r = await save?.({ id: activeId, pin: true }); // save to server
                if (r) {
                  message.success('Pin success');
                }
              }}
            >
              Pin to Data Viewer
            </Button>
            <Button
              type='primary'
              onClick={async () => {
                setValue?.(activeId, { pin: false }); // update data
                const d = modalList.find((item) => item.id === activeId);
                const r = await save?.(d); // save to server
                if (r) {
                  message.success('Save success');
                }
              }}
            >
              Save
            </Button>
          </div>
        </>
      )}
      {len > 0 && (
        <div>
          <DialogSave
            open={isOpen}
            close={() => {
              setIsOpen(false);
            }}
            closeAll={() => {
              setIsOpen(false);
              close?.(closeId);
            }}
            save={async () => {
              const v = modalList.find((item) => item.id === closeId);
              setLoading(true);
              const r = await save(v);
              setLoading(false);
              if (r) {
                setIsOpen(false);
                close?.(closeId);
              }
            }}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

/**
 * 显示对应的弹窗的列表的内容
 * @returns
 */
export const StudioModalList = () => {
  const easyViewStore = EasyViewStore.useStore();

  const singleModal = easyViewStore.useKey('singleModal');

  const draggable = document.querySelector('#id-container-draggable');
  if (draggable == null || !singleModal) {
    return <></>;
  }
  const id = 'easy-viewer-modal';
  const comp = (
    <Draggable handle={`[data-role="${id}"]`} bounds={'body'}>
      <div
        style={{
          width: 860,
          height: 560,
          marginTop: 49,
          background: '#F4F7FD',
          boxShadow: '0px 2px 10px rgba(23, 47, 65, 0.2)',
          position: 'absolute',
          left: '260px',
          top: '10px',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <StudioTabList draggerId={id} />
      </div>
    </Draggable>
  );
  return createPortal(comp, draggable as Element);
};
