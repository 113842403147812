import { Store } from '@/module/model';
import { EasyConfig, getList, postSave, deleteEasy } from './api';

import { isString } from 'lodash';
import { useParams } from 'react-router-dom';
const foxgloveFetchUrl = async (data?: any) => {
  const res: any = { data: '' };
  return res;
};
type StudioData = EasyConfig;

export type StudioModal = {
  id: number;
  type: string; // 3d image plot
  active: number; // default 0
  foxgloveUrl: string;
  config?: {
    [key: string]: any;
  };
  [key: string]: any;
};
/**
 * EasyViewStore
 * @class EasyViewStore
 */
export class EasyViewStore extends Store {
  stateInit = {
    list: [] as any[],
    modalList: [] as StudioModal[],
    singleModal: false, // single modal
    pinList: [] as StudioData[], // pin modal
    maxActive: 0,
    activeId: 0,
    formData: {
      category: '3d',
    } as any,
    modalOpen: false,
    foxgloveUrl: '',
  };

  deviceId: string;

  constructor(deviceId: string) {
    super();
    this.deviceId = deviceId;
    // TODO: dynamic foxgloveUrl
    this.state.foxgloveUrl = 'http://foxglove.manhe.tech/?ds=foxglove-websocket&ds.url=ws%3A%2F%2Ftest.hetzner.manhe.tech%3A8000';
  }
  singleModalClose = () => {
    this.setState({ singleModal: false, modalList: [], activeId: 0 });
  };
  close = (id: number) => {
    const modalList = this.state.modalList.filter((item) => item.id !== id);
    if (id === this.state.activeId) {
      const activeId = modalList.length > 0 ? modalList.slice(-1)[0].id : 0;
      this.setProduce(function (state) {
        state.activeId = activeId;
        state.modalList = modalList;
      });
    } else this.setState({ modalList });
  };

  setValue = (id: number, values: any) => {
    const _ = this.state.modalList.map((item) => {
      if (item.id === id) {
        const _ = { ...item, isChange: true, ...values };
        return _;
      }
      return item;
    });
    this.setState({ modalList: _ });
  };
  setActive = (id: number) => {
    this.setState({ activeId: id });
  };
  addModal = (id: number) => {
    const _ = this.state.list.find((i) => i.id === id);
    if (_) {
      const modalList = this.state.modalList;
      const has = modalList.find((i) => i.id === id);
      if (has) {
        this.setActive(id);
        return;
      }
      const _modalList = [...modalList, { ..._ }];
      this.setState({ modalList: _modalList, activeId: id, singleModal: true });
    }
  };
  toggleModal = (v?: boolean) => {
    const modalOpen = v ?? !this.state.modalOpen;
    this.setState({ modalOpen });
    // no same time to set modalOpen and formData, so use this to avoid formData updated in layout
    let formData = this.state.formData;
    if (!modalOpen) {
      // clear modal fromData
      formData = { foxgloveUrl: this.state.foxgloveUrl } as StudioModal;
    }
    if (!formData.id && modalOpen) {
      // create
      formData = { foxgloveUrl: this.state.foxgloveUrl } as StudioModal;
    }
    this.setState({ formData });
  };
  addPin = (data: Partial<StudioData>) => {
    const pinList = this.state.pinList;
    const has = pinList.find((i) => i.id === data.id);
    if (has) {
      const _ = pinList.map((item) => {
        if (item.id === data.id) {
          return { ...item, ...data };
        } else {
          return item;
        }
      });
      this.setState({ pinList: _ });
      return;
    }
    const newPin = this.state.list.find((i) => i.id === data.id);
    if (newPin) {
      pinList.unshift(newPin);
      this.setState({ pinList });
    }
  };
  removePin = (data: Partial<StudioData>) => {
    const pinList = this.state.pinList;
    const has = pinList.find((i) => i.id === data.id);
    if (has) {
      const _ = pinList.filter((item) => item.id !== data.id);
      this.setState({ pinList: _ });
      has.pin = false;
      this.saveModalData(has);
    }
  };
  /**
   * refresh pin list
   */
  refreshPin = () => {
    const pinList = this.state.list.filter((i) => i.pin);
    this.setState({ pinList });
  };
  /**
   * transfrom Data
   * @param data
   * @param direction
   * @returns
   */
  transfromData = (data: any, direction?: 'get' | 'post') => {
    try {
      if (direction === 'post') {
        return {
          ...data,
          config: JSON.stringify(data.config),
        };
      }
      return {
        ...data,
        config: JSON.parse(data.config || {}),
      };
    } catch (e) {
      if (direction === 'post') {
        return {
          ...data,
          config: '{}',
        };
      } else {
        return {
          ...data,
          config: {},
        };
      }
    }
  };
  /**
   * update formData by key
   * @param key
   * @param value
   */
  changeFormData = (key: string, value: string) => {
    const formData = { ...this.state.formData };
    formData[key] = value;
    this.setState({ formData });
  };
  setFoxgloveUrl = (value) => {
    const formData = { ...this.state.formData };
    formData['foxgloveUrl'] = value;
    this.setState({ formData });
  };
  getFromData = () => {
    return this.state.formData;
  };
  /**
   * update formData
   * @param record
   */
  setFormData(record: any) {
    this.setState({ formData: record });
  }
  saveFormData = async () => {
    const formData = this.state.formData;
    this.setLoading();
    const data = this.transfromData(formData, 'post');
    data.category = formData.category || '3d';
    if (!data.config) {
      // when config is null，, it is because it has just been created.
      data.config = '{}';
      data.pin = false;
      // only 初始化的时候修改
      // TODO: foxgloveUrl
      // if (!this.state.foxgloveUrl) {
      //   const foxgloveUrl = await this.fetchUrl();
      //   if (!foxgloveUrl) {
      //     return;
      //   }
      //   data.foxgloveUrl = foxgloveUrl;
      // } else {
      //   data.foxgloveUrl = this.state.foxgloveUrl;
      // }
    }

    const res = await postSave({ ...data, deviceNo: this.deviceId });
    this.toggleModal(false);
    this.setState({ formData: {} as StudioModal });
    if (res.success) {
      this.fetch();
      this.addModal((res.data as any)?.id);
    }
  };
  /**
   * modal data update
   * @param modalData
   * @returns
   */
  saveModalData = async (modalData) => {
    // same info save in modalList,but in some place(pin) no open modalList, so it's no have. list has all.
    const modalList = this.state.modalList;
    const _modal = this.state.modalList.find((i) => i.id === modalData.id);
    const _ = this.state.list.find((i) => i.id === modalData.id);
    if (!_) {
      return false;
    }
    // remove isChange
    this.setState({
      modalList: modalList.map((item) => {
        if (item.id === modalData.id) {
          delete item.isChange;
        }
        return item;
      }),
    });
    this.setLoading();
    const data = this.transfromData({ ..._, ..._modal, ...modalData }, 'post');
    const res = await postSave({ ...data, deviceNo: this.deviceId });
    this.setLoaded();
    if (res.success) {
      this.fetch();
      return true;
    }
    return false;
  };
  deleteEasy = async (id: string) => {
    const res = await deleteEasy({ id });
    if (res.success) {
      this.fetch();
    }
  };
  fetchUrl = async () => {
    const res = await foxgloveFetchUrl({ deviceNo: this.deviceId });
    if (res.success && isString(res.data)) {
      const foxgloveUrl = res.data;
      this.setState({ foxgloveUrl });
      return foxgloveUrl;
    }
    return '';
  };
  fetch = async () => {
    this.setLoading();
    const res = await getList({ deviceNo: this.deviceId });
    this.setLoaded();
    if (res.success) {
      const _list = (res?.data as any[]) || [];
      const list = _list.map((item) => {
        return this.transfromData(item, 'get');
      });
      const pinList = [...list].filter((i) => i.pin);
      this.setState({ list, pinList });
    }
    if (!this.state.foxgloveUrl) {
      this.fetchUrl();
    }
  };
  static useStore(this: typeof EasyViewStore) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const params = useParams();
    const deviceId = params?.id as string;
    const key = deviceId;
    const Class = this as typeof EasyViewStore;
    const map = this._instanceMap as Record<string, EasyViewStore>;
    if (!(key in map)) {
      const inst = new Class(deviceId);
      inst.key = key;
      map[key] = inst;
    }
    return map[key];
  }
}
