import { Form } from '../form';
import { Button } from '../button';
import { Input } from '../input';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Popconfirm, PopconfirmProps } from 'antd';

type TableWrapperProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  /**  */
  search?: React.ReactNode;
  tools?: React.ReactNode;
};
/**
 * Table for search Form and add button
 * @param props
 * @returns
 */
export const TableWrapper = (props: TableWrapperProps) => {
  const { children, style, search, tools } = props;
  return (
    <div
      style={{
        padding: '19px 20px',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        borderRadius: 4,
        border: '1px solid #C2CFD4',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #C2CFD4', paddingBottom: '14px', marginBottom: '10px' }}>
        <div>{search}</div>
        <div>{tools}</div>
      </div>
      <div className='no-scrollbar' style={{ flexGrow: 1, overflow: 'scroll' }}>
        {children}
      </div>
    </div>
  );
};
export type QueryFormProps = {
  queryPlaceholder?: string;
  queryLabel?: string;
  queryName?: string;
  children?: React.ReactNode;
  onFinsh?: (value: any) => void;
  onChange?: (value: any) => void;
};
const QueryForm = (props: QueryFormProps) => {
  const { queryName = 'search', queryLabel = 'Name / ID', queryPlaceholder = 'Please enter', onFinsh, children } = props;
  const [queryForm] = Form.useForm();
  const queryValue = Form.useWatch(queryName, queryForm);
  const onQueryFinish = (value) => {
    onFinsh?.(value);
  };
  useEffect(() => {
    props.onChange?.(queryValue);
  }, [queryValue]);
  return (
    <>
      <div>
        <Form form={queryForm} layout='inline' size='small' onFinish={onQueryFinish}>
          <Form.Item name={queryName} label={queryLabel}>
            <Input placeholder={queryPlaceholder} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
const AddButton = (props: { onClick: any; children?: React.ReactNode }) => {
  return (
    <Button
      type='primary'
      onClick={(e) => {
        props.onClick?.(e);
      }}
      size='small'
    >
      + add New
    </Button>
  );
};

TableWrapper.QueryForm = QueryForm;
TableWrapper.AddButton = AddButton;

type PopConfirmInfo = {
  showConfirmInfo?: boolean;
  popconfirmInfo?: PopconfirmProps;
};
type ConfrimInfoProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLAnchorElement> &
  PopConfirmInfo;
const ConfrimInfo = (props: ConfrimInfoProps) => {
  const { children, showConfirmInfo, popconfirmInfo, onClick, ...rest } = props;
  if (showConfirmInfo) {
    return (
      <Popconfirm
        onConfirm={(e) => {
          onClick?.(e as any);
        }}
        description={'Are you sure to delete?'}
        title={'Delete'}
        okText='Confirm'
        cancelText='Cancel'
        cancelButtonProps={{ type: 'text' }}
        {...popconfirmInfo}
      >
        <a {...rest}>{children}</a>
      </Popconfirm>
    );
  }
  return (
    <a onClick={onClick} {...rest}>
      {children}
    </a>
  );
};
export const DeleteAction = styled(ConfrimInfo)((props) => {
  return {
    color: '#FF4D4F',
  };
});

export const EditAction = styled(ConfrimInfo)((props) => {
  return {
    color: '#172F41',
  };
});

export const ViewerAction = styled(ConfrimInfo)((props) => {
  return {
    color: '#4797A5',
  };
});
TableWrapper.DeleteAction = DeleteAction;
TableWrapper.EditAction = EditAction;
TableWrapper.ViewerAction = ViewerAction;

type TableActionProps = {
  type?: 'delete' | 'edit' | 'viewer';
} & React.HTMLAttributes<HTMLDivElement> &
  PopConfirmInfo;
type JSXFunction<T = any> = (props: T) => JSX.Element;
export const TableAction = (props: TableActionProps) => {
  const { type = 'edit', children, ...rest } = props;
  let Com: JSXFunction | null = null;
  const defaultProps = {};
  if (type === 'delete') {
    Com = DeleteAction as JSXFunction;
    defaultProps['showConfirmInfo'] = true;
  } else if (type === 'edit') {
    Com = EditAction as JSXFunction;
  } else if (type === 'viewer') {
    Com = ViewerAction as JSXFunction;
  }
  if (!Com) {
    return null;
  }
  return (
    <Com {...defaultProps} {...rest}>
      {children}
    </Com>
  );
};
TableWrapper.TableAction = TableAction;
