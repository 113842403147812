import { EventEmitter } from 'eventemitter3';
import { useEffect } from 'react';

export const mitt = new EventEmitter();

export const MessageExtra = ['TARGETS_UPDATED', 'DATA_VIEWER', 'DOWNLOAD_FILE'] as const;
type MessageExtraType = (typeof MessageExtra)[number];

export const useDeviceRefresh = (refrseh: any) => {
  const fn = (data?: any) => {
    refrseh?.(data);
  };
  useEffect(() => {
    mitt.on('TARGETS_UPDATED', fn);
    return () => {
      mitt.off('TARGETS_UPDATED', fn);
    };
  }, []);
};

export const useDataViewer = (refrseh: any) => {
  const fn = (data?: any) => {
    refrseh?.(data);
  };
  useEffect(() => {
    mitt.on(MessageExtra['1'], fn);
    return () => {
      mitt.off(MessageExtra['1'], fn);
    };
  }, []);
};

export const sendExtraData = (type: MessageExtraType, data?: any) => {
  mitt.emit(type, data);
};

export const useExtraData = <T = any>(type: MessageExtraType, refrseh: (data?: T) => void) => {
  const fn = (data?: any) => {
    refrseh?.(data);
  };
  useEffect(() => {
    mitt.on(type, fn);
    return () => {
      mitt.off(type, fn);
    };
  }, []);
};
