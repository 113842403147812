import { Drop as DropIcon } from '@nisar/icons';
import { NisarLogo } from '@/components/company';
import { ToolWrapper } from './ToolWrapper';
import { Dropdown } from '@/theme';
import { useNavigate } from 'react-router-dom';
export const NisarDropIcon = () => {
  const navigate = useNavigate();
  const items = [
    {
      key: 'goBack',
      label: 'go back',
    },
  ];
  const onChange = (k) => {
    if (k === 'goBack') {
      window.history.back();
    }
  };
  return (
    <ToolWrapper>
      <NisarLogo
        style={{ width: 32 }}
        onClick={() => {
          navigate(-1);
        }}
      />

      {/* <Dropdown className='flex' menu={{ items, onChange }}>
        <NisarLogo style={{ width: 32 }} />
        <DropIcon style={{ width: 6 }} />
      </Dropdown> */}
    </ToolWrapper>
  );
};
