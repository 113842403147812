/**
 * @module LayoutMenu
 * @description this module is had more denpendencies. so checked it.
 */
import { NisarLogoText } from '@/components/company';
import styled from '@emotion/styled';
import { routesMenu, RoutesMenu, SpecialRoutes } from '@/admin/routes';
import cs from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { App, message } from 'antd';
import { getHawktBitUrl } from '@/admin/api/api';

const openHawktBit = async () => {
  const msg = message.loading('open...');
  const res = await getHawktBitUrl();
  msg();
  if (res.success) {
    window.open(res.data);
  } else {
    message.error('Failed to get hawkbit url');
  }
};
const CategoryMenu = styled('div')((props) => {
  return {
    display: 'flex',
    '&.active': {
      background: '#07121B',
      color: '#fff',
      borderLeft: '2px solid #7DDDEE',
      '.icon': {
        color: '#66D7EB',
      },
    },
  };
});
type RouteMenuProps = {
  routes: typeof routesMenu;
};
export const RouteMenu = (props: RouteMenuProps) => {
  const { routes } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const isActive = useCallback(
    (path: string) => {
      return location.pathname.startsWith(path);
    },
    [location.pathname],
  );
  const onMenuChange = (routeMenu: RoutesMenu) => {
    const path = routeMenu.path;
    if (routeMenu.isDev) {
      message.info(`${routeMenu.title} is in development`);
      return;
    }
    if (path) {
      if (path === SpecialRoutes.Hawkbit) {
        openHawktBit();
        return;
      }
      navigate(path);
    }
  };
  return (
    <>
      {routes
        .filter((item) => !item.hidden)
        .map((item) => {
          return (
            <CategoryMenu
              className={cs({ active: isActive(item.path!) }, 'cursor-pointer')}
              key={item.path}
              style={{ padding: '12px 12px' }}
              onClick={() => {
                onMenuChange(item);
              }}
            >
              <div className='w-6 h-6 icon'>{item.icon}</div>
              <div style={{ marginLeft: 8 }}>{item.title}</div>
            </CategoryMenu>
          );
        })}
    </>
  );
};
const LayoutBorder = styled('div')((props) => {
  return {
    borderBottom: '1px solid #c2cfd4',
    display: 'flex',
    flexDirection: 'column',
  };
});
const LayoutBackground = styled('div')((props) => {
  return {
    width: '208px',
    color: '#D8E0E8',
    background: '#172F41',
    borderRight: '1px solid #C2CFD4',
    fontSize: 16,
    flexShrink: 0,
  };
});
const LayoutCategory = styled('div')((props) => {
  return {
    padding: '0.5rem 16px',
    color: '#6D848F',
    fontWeight: 500,
  };
});
export const LayoutMenu = () => {
  return (
    <LayoutBackground>
      <NisarLogoText
        style={{
          fontSize: 16,
          fontFamily: 'Orbitron',
          fontWeight: 500,
          padding: '1rem 0',
          color: '#fff',
        }}
      />
      <LayoutBorder />
      <LayoutCategory style={{ marginTop: 10 }}>CATEGORY</LayoutCategory>
      <div className='flex flex-grow' style={{ overflow: 'hidden' }}>
        <div
          className='no-scrollbar'
          style={{
            height: '100%',
            width: '100%',
            overflow: 'auto',
          }}
        >
          <RouteMenu routes={routesMenu} />
        </div>
      </div>
    </LayoutBackground>
  );
};
