import { getLayoutDrawer } from '@/admin/module/admin-auth/Layout';
import { createPortal } from 'react-dom';
import { useDrawStore as useStore } from './store';
import { Close, Device, Right } from '@nisar/icons';
import { Button, Tooltip } from 'antd';
import { BodyTitle, Icon, LoadingData, Tabs, usePagination } from '@/theme';
import Collapse from '@mui/material/Collapse';
import { DeviceData, TargetData } from '@/admin/type';
import { Radar } from '@nisar/icons';
import { getTargetList, useTargetEditStore } from '../../../target';
import { useEffect } from 'react';
type BodyProps = { title?: string; children?: React.ReactNode; bodyStyle?: React.CSSProperties };
const Body = (props: BodyProps) => {
  const { title, bodyStyle } = props;
  // if (!props.children && typeof props.children !== 'string') return null;
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {title && <div className='mr-2'>{title}</div>}
      <div style={{ opacity: 0.65, width: 160, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', ...bodyStyle }}>
        <Tooltip title={props.children}>{props.children}</Tooltip>
      </div>
    </div>
  );
};
type TargetListProps = {
  list: TargetData[];
  loading?: boolean;
  device?: DeviceData;
};
const TargetList = (props: TargetListProps) => {
  const { loading, list = [], device } = props;
  const { id: deviceId } = device || {};
  const tabItems = [
    {
      label: `Target List`,
      key: 'Target',
    },
  ];

  const targetStore = useTargetEditStore();

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs items={tabItems} showBottomBorder={false} />
        <Button
          onClick={() => {
            if (!deviceId) return;
            targetStore.setDeviceId(deviceId);
            device && targetStore.setTenantId(device.tenantId!);
            targetStore.setOpen(true);
          }}
        >
          + add New
        </Button>
      </div>
      <LoadingData loading={loading}>
        <div
          className='no-scrollbar'
          style={{
            height: 'calc(100% - 130px - 48px - 4px)',
          }}
        >
          {list.map((item, index) => {
            return (
              <div
                key={item.id || index}
                className='flex justify-between items-center'
                style={{ padding: 16, background: '#F9FBFF', marginTop: index !== 0 ? 10 : 0 }}
              >
                <div>
                  <div className='flex'>
                    <Radar style={{ width: 24 }} />
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: 16,
                        color: '#172F41',
                        marginLeft: 5,
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                  <Body title='Name:'>{item.name}</Body>
                  <Body title='ID:'>{item.id}</Body>
                </div>
                <div>
                  <Right style={{ width: 24 }} />
                </div>
              </div>
            );
          })}
        </div>
      </LoadingData>
    </div>
  );
};
const TargetInfo = () => {
  const store = useStore();
  const data = store?.device;
  const DetailBox = (
    <div
      style={{
        position: 'relative',
        color: '#172F41',
        fontWeight: 500,
        fontSize: 10,
        height: 130,
      }}
    >
      <div className='flex'>
        <div className='flex items-center mr-2'>
          <Icon icon={<Device />} />
        </div>
        <BodyTitle>{data?.name}</BodyTitle>
      </div>
      <Body title='ID:' bodyStyle={{ width: 178 }}>
        {data?.id}
      </Body>
      <Body title='IP Address:'>{data?.ip}</Body>
      <Body title='State:'>{data?.status}</Body>
      <Body title='Protocol:'>{data?.protocol}</Body>
    </div>
  );
  const { run, dataSource, loading, refresh } = usePagination(getTargetList);
  useEffect(() => {
    return () => {
      store.setOpen(false); // clear draget draw open
    };
  }, []);
  useEffect(() => {
    if (!data?.id) return;
    if (!store.open) return;
    run({ deviceId: data?.id });
  }, [data?.id, store.open]);
  // refresh
  useEffect(() => {
    if (!store.open) return;
    if (store.refreshCount > 0) {
      refresh();
      store.clearRefresh();
    }
  }, [store.open, store.refreshCount]);

  return (
    <Collapse
      style={{
        height: '100%',
      }}
      in={store.open}
      timeout='auto'
      orientation='horizontal'
      // unmountOnExit
    >
      <div
        style={{
          boxShadow: '0px 2px 10px rgba(23, 47, 65, 0.2)',
          background: '#fff',
          padding: '48px 24px',
          minWidth: '403px',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginBottom: 24,
          }}
          onClick={() => {
            store.setOpen(false);
          }}
          className='cursor-pointer'
        >
          <Close style={{ width: 24 }} />
          <div style={{ marginLeft: 2 }}>Close</div>
        </div>
        {DetailBox}
        <TargetList list={dataSource} loading={loading} device={data} />
      </div>
    </Collapse>
  );
};
export const TargetDraw = () => {
  const container = getLayoutDrawer();
  if (!container) return <></>;
  return createPortal(<TargetInfo />, container);
};
