import styled from '@emotion/styled';

type Props = {
  /** default left is 8 */
  left?: number;
  children: React.ReactNode;
} & React.HTMLProps<HTMLDivElement>;
export const SpaceAction = styled((props: Props) => {
  const { left = 8, children, ...rest } = props;
  return <div {...rest}>{children}</div>;
})((props) => {
  const { left = 8 } = props;
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    '& >:not(:first-of-type)': {
      marginLeft: left,
    },
  };
});
