import { create } from 'zustand';
import { getList, deleteEasy, postSave } from './api';
import { EasyConfig } from './api';
import { EasyViewStore } from './model';

type Store = {
  loading?: boolean;
  list: EasyConfig[];
  formData: { pageSize?: number; page?: number; deviceNo?: string };

  /** actions */
  getList?: (data?: { deviceNo: string; [key: string]: any }) => Promise<void>;
  remove?: (id: string) => Promise<void>;
  update?: (data: EasyConfig) => Promise<void>;
  init: (id: string) => Promise<void>;
  easyViewStore?: EasyViewStore;
};

export const useViewerStore = create<Store>((set, get) => ({
  list: [],
  loading: false,
  formData: { pageSize: 1000, page: 1 },
  // init: async (id: string) => {
  // set((state) => {
  //   const formData = { ...state.formData, deviceNo: id };
  //   return { formData };
  // });
  // await get().getList?.({ deviceNo: id });
  // },
  init: async (id: string) => {
    const easyViewStore = new EasyViewStore(id);
    easyViewStore.fetch();
    set({ easyViewStore: easyViewStore });
  },
  getList: async (data) => {
    set({ loading: true });
    const { formData } = get();
    const res = await getList({ ...formData, ...data });
    set({ loading: false });
    if (res.success) {
      const { data } = res;
      set({ list: data });
    }
  },
  remove: async (id: string) => {
    const res = await deleteEasy({ id });
    if (res.success) {
      await get().getList?.();
    }
  },
  update: async (data: EasyConfig) => {
    const res = await postSave(data);
    if (res.success) {
      await get().getList?.();
    }
  },
}));
